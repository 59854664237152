import { h } from "../../../../tracejs/src/utils/JSXFactory";

export class EditableCargoInfo {

    private static colli = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'colli', i18n: 'common.captions.colli'"></label>
            <div className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)}`}>
                <input type="number" min="0"  data-bind="uniqueId: 'colli', kendoNumericTextBox: { value: colli, format: 'n0', decimals: 0, enable: $root.editableDimensions }" className="w-100" />
            </div>
            <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} inline col-form-label ps-0`}>
                <span data-bind="i18n: 'common.captions.packingList'"></span>: <strong
                data-bind="text: kendo.toString($data.sumColli(), 'n0')"></strong>
            </label>
        </div>      
    );

    private static kgs = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'kgs', i18n: 'common.captions.kgs'"></label>
            <div className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)}`}>
                <input type="number" min="0"  data-bind="uniqueId: 'kgs', kendoNumericTextBox: { value: kgs, format: 'n3', decimals: 3, enable: $root.editableDimensions }" className="w-100" />
            </div>
            <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} inline col-form-label ps-0`}>
                <span data-bind="i18n: 'common.captions.packingList'"></span>: <strong data-bind="text: kendo.toString($data.sumKgs(), 'n2')"></strong>
            </label>
        </div>
    )

    private static cbm = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'cbm', i18n: 'common.captions.cbm'"></label>
            <div className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)}`}>
                <input type="number" min="0"  data-bind="uniqueId: 'cbm', kendoNumericTextBox: { value: cbm, format: 'n2', decimals: 2, enable: $root.editableDimensions }" className="w-100" />
            </div>
            <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} inline col-form-label ps-0`}>
                <span data-bind="i18n: 'common.captions.packingList'"></span>: <strong data-bind="text: kendo.toString($data.sumCbm(), 'n2')"></strong>
            </label>
        </div>
    );

    private static chargableVolume = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'chargeableVolume', i18n: 'common.captions.chargeableVolume'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                <input type="number" min="0" data-bind="uniqueId: 'chargeableVolume', value: chargeableVolume" className="form-control" />
            </div>
        </div> 
    );

    private static ldm = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'ldm', i18n: 'common.captions.ldm'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                <input type="number" min="0" data-bind="uniqueId: 'ldm', value: ldm" className="form-control" />
            </div>
        </div>
    );

    private static cargoDescription = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'cargoDescription', i18n: 'common.captions.cargoDescription'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                <textarea maxlength="400" data-bind="uniqueId: 'cargoDescription', value: cargoDescription, style: { 'min-height': '46px'}" className="form-control"></textarea>
            </div>
        </div>
    );

    private static dgrCargo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} offset-lg-${l[0]} offset-xl-${l[1]} offset-xxl-${l[2]}`}>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" data-bind="uniqueId: 'adr', checked: adr" />
                    <label className="form-check-label" data-bind="i18n: 'common.captions.adr', uniqueFor: 'adr'"></label>
                </div>
            </div>
        </div>
    );

    private static adrClass = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <ko if="!($root.isRail())">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`}
                       data-bind="uniqueFor: 'adrClass', i18n: 'common.captions.adrClass'"></label>
            </ko>
            <ko if="$root.isRail()">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`}
                       data-bind="uniqueFor: 'adrClass', i18n: 'common.captions.dgrClass'"></label>
            </ko>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                <input type="text" data-bind="uniqueId: 'adrClass', value: adrClass" className="form-control"/>
            </div>
        </div>
    );

    private static unCode = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'un', i18n: 'common.captions.un'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                <input type="text" data-bind="uniqueId: 'un', value: un" className="form-control" />
            </div>
        </div>
    );

    private static parkingClass = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'packingGroup', i18n: 'common.captions.packingGroup'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                <select className="form-select" data-bind="
                    uniqueId: 'packingGroup',
                    options: $root.packingGroups,
                    optionsText: 'code',
                    optionsValue: 'code',
                    optionsCaption: '',
                    value: packingGroup
                "></select>
            </div>
        </div>
    );

    public static editableCardoInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9], chargableVolume: boolean = false) : HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.cargoInformation'"></legend>

            { this.colli(l, v) }
            { this.kgs(l, v) }
            { this.cbm(l, v) }
            { chargableVolume ? this.chargableVolume(l, v) : '' }
            <ko if="!($root.isRail())"> { this.ldm(l, v) } </ko>
            { this.cargoDescription(l, v) }
            
            { this.dgrCargo(l, v) }

            { this.adrClass(l, v) }
            { this.unCode(l, v) }
            { this.parkingClass(l, v) }
            
        </fieldset>
    )
}