import { locale } from "numeral";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { ReadOnlyHeaderTemplate } from "./ReadOnlyHeaderTemplate";
import { EditableContainerInfo } from "../EditAndCreateTransport/EditableContainerInfo";
import { EditableCargoInfo } from "../EditAndCreateTransport/EditableCargoInfo";
import { EditableMainInfo } from "../EditAndCreateTransport/EditableMainInfo";

export class EditableHeaderTemplate
{

    // #region Customer

    /**
     * Jedinne, co muze customer upravit je sva PIC,  ostatni casti jsou pouze read-only
     */
    public static customerParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="i18n: 'common.captions.customer'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <span data-bind="text: $root.mainSubject.name" className="col-form-label d-inline-block"></span>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myPicUserId', i18n: 'common.captions.customerPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                            data: $root.mainUsers,
                            dataTextField: 'realName',
                            dataValueField: 'userId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: myPicUserId,
                            animation: false 
                        }" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="i18n: 'common.captions.carrier'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <span data-bind="text: supplierPartner.name" className="col-form-label d-inline-block"></span>
                </div>
            </div>
        </fieldset>
    );

    public static customerMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerMainInfo(l, v);
    public static customerContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerContainerInfo(l, v);
    public static customerDriverInfo = (): HTMLElement => ReadOnlyHeaderTemplate.customerDriverInfo();
    public static customerCargoInfo = (): HTMLElement => ReadOnlyHeaderTemplate.customerCargoInfo();
    public static customerPrices = (): HTMLElement => EditableHeaderTemplate.spediterPrices();

    // #endregion



    // #region Customer - Create Order Only

    public static customerOrderParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="i18n: 'common.captions.customer'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <span data-bind="text: $root.mainSubject.name" className="col-form-label d-inline-block"></span>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myPicUserId', i18n: 'common.captions.customerPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                            data: $root.mainUsers,
                            dataTextField: 'realName',
                            dataValueField: 'userId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: myPicUserId,
                            animation: false 
                        }" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'supplierPartnerId', i18n: 'common.captions.carrier'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'supplierPartnerId', kendoDropDownList: {
                            data: $root.suppliers,
                            dataTextField: 'name',
                            dataValueField: 'subjectId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: supplierPartnerId,
                            animation: false 
                        }" />
                </div>
            </div>
        </fieldset>
    );
    
    public static customerOrderMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], showUzp: boolean = false, sync: boolean = false): HTMLElement => EditableMainInfo.editableMainInfo(l, v, showUzp, sync);

    public static customerIdentification = (): HTMLElement => (
        <div></div>
    );

    public static customerOrderContainerInfo = (
        l: number[] = [4, 4, 3],
        v: number[] = [8, 8, 9],
        sync: boolean = false
    ): HTMLElement => EditableContainerInfo.editableContainerInfo(l, v, sync);

    public static customerOrderCargoInfo = (
        l: number[] = [4, 3, 3],
        v: number[] = [8, 9, 9],
        chargableVolume: boolean = false
    ): HTMLElement => EditableCargoInfo.editableCardoInfo(l, v, chargableVolume);
    
    public static customerOrderDriverInfo = (): HTMLElement => (<div></div>);
    public static customerOrderPrices = (): HTMLElement => (<div></div>);    

    // #endregion



    // #region Spediter

    public static spediterParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} for="customerPartnerIdInput" data-bind="i18n: 'common.captions.customerPartner'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.customerPartnerId" /></label>
                    </ko>

                    <input className="w-100" id="customerPartnerIdInput" data-bind="kendoDropDownList: {
                        data: $root.customers,
                        dataTextField: 'name',
                        dataValueField: 'subjectId',
                        optionLabel: '-',
                        filter: 'contains',
                        value: customerPartnerId,
                        animation: false
                    }" /> {/* , enable: id() === null */}
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'customerPartnerPicUserId', i18n: 'common.captions.customerPartnerPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.customerPartnerPicUserId" /></label>
                    </ko>

                    <input className="w-100" data-bind="
                        uniqueId: 'customerPartnerPicUserId',
                        value: customerPartnerPicUserId,
                        kendoDropDownList: $root.customerPartnerPicUserComboConfig
                    " />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mySubject'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                    <ko if="id() === null"><span data-bind="text: $root.mainSubject.name"></span></ko>
                    <ko if="id() !== null"><span data-bind="text: mySubject.name"></span></ko>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.myPicUserId" /></label>
                    </ko>

                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                        data: $root.mainUsers,
                        dataTextField: 'realName',
                        dataValueField: 'userId',
                        optionLabel: '-',
                        filter: 'contains',
                        value: myPicUserId,
                        animation: false 
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} for="supplierPartnerIdInput" data-bind="i18n: 'common.captions.carrier'"></label>
                <ko if="id() === null">
                    <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                        <input className="w-100" id="supplierPartnerIdInput" data-bind="kendoDropDownList: {
                            data: $root.carriers,
                            dataTextField: 'name',
                            dataValueField: 'subjectId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: supplierPartnerId,
                            animation: false
                        }" />
                    </div>
                </ko>
                <ko if="id() !== null">
                    <label className={`col col-form-label`} data-bind="text: supplierPartnerId() ? supplierPartner.name() : ''"></label>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="click: $root.changePass.bind($root), i18n: 'common.captions.change'"></button>
                    </div>
                </ko>
            </div>
            <ko if="supplierPartnerId() && supplierPartnerId() != mySubjectId()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'supplierPartnerPicUserId', i18n: 'common.captions.carrierPicUser'"></label>
                    <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                        <ko if="$root.tracedoGroup()">
                            <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.supplierPartnerPicUserId" /></label>
                        </ko>

                        <input className="w-100" data-bind="
                            uniqueId: 'supplierPartnerPicUserId',
                            value: supplierPartnerPicUserId,
                            kendoDropDownList: $root.supplierPartnerPicUserComboConfig
                        " />                        
                    </div>
                </div>
            </ko>

        </fieldset>
    );

    /**
     * Spditer ma k editaci totez, co zakaznik pri objednavce
     */
    public static spediterMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => EditableHeaderTemplate.customerOrderMainInfo(l, v, true, true);
    

    public static spediterIdentification = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.identification'"></legend>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'seasonId', i18n: 'common.captions.season'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.mySeasonId" /></label>
                    </ko>

                    <input className="w-100" data-bind="uniqueId: 'seasonId', kendoDropDownList: {
                            data: $root.seasons,
                            dataTextField: 'name',
                            dataValueField: 'seasonId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: mySeasonId,
                            animation: false
                        }" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.number'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                    <ko if="id() !== null"><span data-bind="text: myNumber"></span></ko>
                    <ko if="id() === null"><em data-bind="i18n: 'common.captions.willBeGeneratedAfterSave'"></em></ko>
                </label>
            </div>
        </fieldset>
    );

    /**
     * Spditer ma k editaci totez, co zakaznik pri objednavce
     */    
    public static spediterContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => EditableContainerInfo.editableContainerInfo(l, v, true);


    public static spediterCargoInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => EditableHeaderTemplate.customerOrderCargoInfo(l, v, true);

    /**
     * Spditer ma k editaci totez, co dopravce
     */
    public static spediterDriverInfo = (): HTMLElement => EditableHeaderTemplate.carrierDriverInfo();

    /**
     * Ceny jsou v editaci vzdy ke cteni
     */
    public static spediterPrices = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.pricesInformation'"></legend>

            <ko if="myField() !== 'customer'">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'sellPrice', i18n: 'common.captions.sellPrice'"></label>
                    <ko if="id() === null || (myPassedFromStatusId() !== null && $root.passStatusesHash[myPassedFromStatusId()].ident === 'approved')">
                        <div className="col">
                            <input type="number" min="0" data-bind="uniqueId: 'sellPrice', value: sellPrice" className="form-control" />
                        </div>
                        <div className="col-auto">
                            <select data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: sellPriceCurrencyId" className="form-select"></select>
                        </div>
                    </ko>
                    <ko if="id() !== null && (myPassedFromStatusId() === null || $root.passStatusesHash[myPassedFromStatusId()].ident !== 'approved')">
                        <label className="col col-form-label">
                            <ko if="sellPriceCurrencyId() && sellPrice() !== null">
                                <strong data-bind="text: kendo.toString(sellPrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: sellPriceCurrency.iso"></strong>&nbsp;
                            </ko>
                            <ko if="myPassedFromStatusId()">
                                <span className="float-end" data-bind="
                                    css: {
                                        'text-warning': $root.passStatusesHash[myPassedFromStatusId()].ident === 'inprogress',
                                        'text-success': $root.passStatusesHash[myPassedFromStatusId()].ident === 'approved',
                                    },
                                    text: $root.passStatusesHash[myPassedFromStatusId()].nameTranslated
                                "></span>
                            </ko>
                        </label>
                    </ko>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="
                            i18n: 'common.captions.detail',
                            click: $root.openSellPriceDetail.bind($root),
                            enable: id() !== null
                        "></button>
                    </div>
                </div>
            </ko>

            <ko if="mySubjectId() != supplierPartnerId() && supplierPartnerId() !== null">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.purchasePrice'"></label>
                    <ko if="id() === null || (myPassStatusId() !== null && $root.passStatusesHash[myPassStatusId()].ident === 'approved')">
                        <div className="col">
                            <input type="number" data-bind="uniqueId: 'purchasePrice', value: purchasePrice" className="form-control" />
                        </div>
                        <div className="col-auto">
                            <select data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: purchasePriceCurrencyId" className="form-select"></select>
                        </div>
                    </ko>
                    <ko if="id() !== null && (myPassStatusId() === null || $root.passStatusesHash[myPassStatusId()].ident !== 'approved')">
                        <label className="col col-form-label">
                            <ko if="purchasePriceCurrencyId() && purchasePrice() !== null">
                                <strong data-bind="text: kendo.toString(purchasePrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: purchasePriceCurrency.iso"></strong>&nbsp;
                            </ko>
                            <ko if="myPassStatusId()">
                                <span className="float-end" data-bind="
                                    css: {
                                        'text-warning': $root.passStatusesHash[myPassStatusId()].ident === 'inprogress',
                                        'text-success': $root.passStatusesHash[myPassStatusId()].ident === 'approved',
                                    },
                                    text: $root.passStatusesHash[myPassStatusId()].nameTranslated
                                "></span>
                            </ko>
                        </label>
                    </ko>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="
                            i18n: 'common.captions.detail',
                            click: $root.openPurchasePriceDetail.bind($root),
                            enable: id() !== null
                        "></button>
                    </div>
                </div>
            </ko>

        </fieldset>
    );
    

    // #endregion

    

    // #region Carrier

    public static carrierParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartner'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: customerPartner.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartnerPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (customerPartnerPicUserId() ? customerPartnerPicUser.realName() : '')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mySubject'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mySubject.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.myPicUserId" /></label>
                    </ko>

                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                            data: $root.mainUsers,
                            dataTextField: 'realName',
                            dataValueField: 'userId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: myPicUserId,
                            animation: false 
                        }" />                                    
                </div>
            </div>
            <ko if="supplierPartnerId() !== null">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.carrier'"></label>
                    <label className={`col col-form-label`}><span data-bind="text: supplierPartner.name"></span></label>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="click: $root.changePass.bind($root), i18n: 'common.captions.change'"></button>
                    </div>
                </div>
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.carrierPicUser'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (supplierPartnerPicUserId() ? supplierPartnerPicUser.realName() : '')"></label>
                </div>
            </ko>
            <ko if="supplierPartnerId() === null && $root.canPass">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.carrier'"></label>
                    <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                        <button type="button" className="btn btn-secondary" data-bind="click: $root.pass.bind($root), i18n: 'common.captions.pass'"></button>
                    </div>
                </div>
            </ko>

        </fieldset>
    );

    public static carrierMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.mainInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'trade.demand.edit.kind'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: kind.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'trade.demand.edit.mode'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: roadType.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef2'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef2"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myUzp', i18n: 'common.captions.myUzp'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.myUzp" /></label>
                    </ko>

                    <input type="date" data-bind="uniqueId: 'myUzp', kendoDatePicker: { animation: false, value: myUzp }" className="w-100" />
                </div>
            </div>
        </fieldset>        
    ); // ReadOnlyHeaderTemplate.carrierMainInfo(labelMd, valueMd);
    
    public static carrierIdentification = (): HTMLElement => EditableHeaderTemplate.spediterIdentification();

    public static carrierContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.carrierContainerInfo(l, v);

    // Cargo information - Informace o nákladu
    public static carrierCargoInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.cargoInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.colli'"></label>
                <label className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)} col-form-label`} data-bind="text: kendo.toString(colli(), 'n0')"></label>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} col-form-label`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <span data-bind="text: kendo.toString(sumColli(), 'n0')"></span>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.kgs'"></label>
                <label className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)} col-form-label`} data-bind="text: kendo.toString(kgs(), 'n2')"></label>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} col-form-label`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <span data-bind="text: kendo.toString(sumKgs(), 'n2')"></span>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.cbm'"></label>
                <label className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)} col-form-label`} data-bind="text: kendo.toString(cbm(), 'n2')"></label>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} col-form-label`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <span data-bind="text: kendo.toString(sumCbm(), 'n2')"></span>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.chargeableVolume'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="number" value="0" min="0" style="border: 5px solid red" data-bind="value: chargeableVolume" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.ldm'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="number" value="0" min="0" data-bind="value: ldm" className="form-control" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.cargoDescription'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: cargoDescription"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.adr'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="bool: adr"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.adrClass'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: adrClass"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.un'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: un"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.packingGroup'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: packingGroup"></label>
            </div>

        </fieldset>
    );

    public static carrierDriverInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.driverInfo'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'truckType', i18n: 'common.captions.truckType'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select data-bind="options: $root.truckTypes, optionsText: 'name', optionsValue: 'truckTypeId', optionsCaption: '-', value: truckTypeId" className="form-select"></select>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'spz', i18n: 'common.captions.spz'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="value: spz" className="form-control" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'driverUserId', i18n: 'common.captions.driver'"></label>
                <div className="col">
                    <input type="text" data-bind="uniqueId: 'driverUserid', value: (driverUserId() ? driverUser.realName() : '')" readonly="readonly" className="form-control" />
                </div>
                <label className="inline col-auto col-form-label ps-0 pe-0" data-bind="uniqueFor: 'driverPhone'"><i className="icon-phone"></i></label>
                <div className="col">
                    <input type="text" data-bind="uniqueId: 'driverPhone', value: (driverUserId() ? (driverUser.mobile() ? driverUser.mobile() : driverUser.phone() ) : '')" readonly="readonly" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'driverInfo', i18n: 'common.captions.driverInfo'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'driverInfo', value: driverInfo" readonly="readonly" className="form-control" />
                </div>
            </div>
        </fieldset>
    );

    public static carrierPrices = (): HTMLElement => EditableHeaderTemplate.spediterPrices();

    // #endregion 
}