import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { Edit } from "./Edit";
import { timers } from "jquery";
import {Create} from "./Create";


/**
 * View for Supervisor - Managing spediter subjects (approving registrations)
 */
@injectable()
export class Subjects extends BaseViewModel<any>
{

	// GRID element
	private gridElement: JQuery<HTMLElement>;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

	// pro moznost manipulaci s nadrazenimy akcemi
	private gridConfiguratorViewModel: GridConfigurator;


	/**
	 * Configure kendo GRID
	 */
	private configureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var toolbarConfig: kendo.ui.GridToolbarItem[] = [];

		if(this.user.isAllowed('entity.subjects', 'manage')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus"></i><span> ' + i18n.t('common.actions.create') + '</span></button>' });
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="update"><i class="icon-edit"></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete"><i class="icon-trash"></i><span> ' + i18n.t('common.actions.cancel') + '</span></button>' });
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-success" data-action="approve"><i class="icon-ok"></i><span> ' + i18n.t('common.actions.approve') + '</span></button>' });
		}

		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

						options.data.search = (this.grid.dataSource as any).searchText;

						let response: any = await this.rpc.call('subject.get', {
							excludeMe: false,
							query: {
								...options.data,
								select: '*,subjectUsers(userId),country(*)'
							}
						});

						let subjectIds: number[] = [];
						response.data.forEach((item: any) => {
							subjectIds.push(item.subjectId);
						});

						let response2: any = await this.rpc.call('tracedo.getCountsBySubjects', { ids: subjectIds });
						response.data.forEach((item: any) => {
							item.tracedoCount = response2[item.subjectId] ? response2[item.subjectId] : 0;
						});

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: {
							'name': { editable: false, type: "string" },
							'ic': { editable: false, type: "string" },
							'code': { editable: false, type: "string" },
							'email': { editable: false, type: "string" },
							'phone': { editable: false, type: "string" },
							'address1': { editable: false, type: "string" },
							'attrCarrier': { editable: false, type: "boolean" },
							'attrPartner': { editable: false, type: "boolean" },
							'attrSpediter': { editable: false, type: "boolean" },
							'isRegistered': { editable: false, type: "boolean" },
							'approved': { editable: false, type: "boolean" },
							'city': { editable: false, type: "string" },
							'country': { editable: false, type: "object" },
                            'subjectUsers': { editable: false, type: 'array' },
							'tracedoCount': { editable: false, type: 'number' }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "name", dir: "asc" },
				pageSize: 50,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true,
				pageSizes: [10, 20, 50, 100, 300, 500]
			},
			toolbar: toolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.grid);
				this.gridElement.find('[data-action=delete]').prop('disabled', true);
				this.gridElement.find('[data-action=update]').prop('disabled', true);
                this.gridElement.find('[data-action=approve]').prop('disabled', true);
			},
			change: () => {
                let selected: any = this.grid.select().length > 0 ? this.grid.dataItem(this.grid.select()) : null;
				this.gridElement.find('[data-action=delete]').prop('disabled', selected === null);
				this.gridElement.find('[data-action=update]').prop('disabled', selected === null);
				this.gridElement.find('[data-action=approve]').prop('disabled', selected === null || selected.approved);
			},
			columns: [{
					field: "name",
					title: i18n.t('common.captions.name'),
					width: 200
				}, {
                    field: 'address1',
					title: i18n.t('common.captions.address'),
					width: 300,
					template: '#= address1 + ", " + city + (countryId ? " " + country.iso : "") #'
                }, {
					field: "ic",
					title: i18n.t('registration.firmIco'),
					width: 120
                }, {
					field: "code",
					title: i18n.t('registration.numberCode'),
					template: '#= (code === null ? \'\' : code) + (vipCode ? \' (' + i18n.t('common.captions.vipCode') + ')\' : \'\') #',
					width: 120
                }, {
					field: "email",
					title: i18n.t('registration.email'),
					width: 250
                }, {
					field: "phone",
					title: i18n.t('registration.phone'),
					width: 150
                }, {
					field: "attrCarrier",
                    title: i18n.t('common.captions.carrier'),
                    template: (data: any) => (data.attrCarrier ? i18n.t('common.captions.yes') : '-'),
                    width: 110
                }, {
					field: "attrPartner",
                    title: i18n.t('common.captions.customer'),
                    template: (data: any) => (data.attrPartner ? i18n.t('common.captions.yes') : '-'),
                    width: 110
				}, {
					field: "attrSpediter",
                    title: i18n.t('common.captions.spediter'),
                    template: (data: any) => (data.attrSpediter ? i18n.t('common.captions.yes') : '-'),
                    width: 110
                }, {
					field: "isRegistered",
                    title: i18n.t('common.captions.isRegistered'),
                    template: (data: any) => (data.isRegistered ? i18n.t('common.captions.yes') : '-'),
                    width: 110
				}, {
                    field: "approved",
                    title: i18n.t('common.captions.approved'),
                    template: (data: any) => (data.approved ? i18n.t('common.captions.yes') : '-'),
                    width: 110
				}, {
                    field: 'subjectUsers',
                    title: i18n.t('common.captions.usersCount'),
                    template: (data: any) => data.subjectUsers.length > 0 ? data.subjectUsers.length : '-',
                    width: 110
				}, {
					field: 'tracedoCount',
					title: i18n.t('common.captions.tracedoCount'),
					sortable: false,
					filterable: false
				}]
		};
	}


	/**
	 * ViewModel startup
	 */
	public async startup()
	{
		// Ma povolenou spravu subjektu ?
		if(!this.user.isAllowed('section.subjects', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		await super.startup();

		// Configure GRID
		this.configureGrid();
	}

	public async rendered()
	{
		// grid element
		this.gridElement = this.element.find('[data-grid=subjects]');

		/// initialize grid
		this.gridElement.kendoGrid(this.gridConfig);
		// Kendo GRID reference
		this.grid = this.gridElement.data('kendoGrid');
		// BIND action buttons
		this.gridElement.on('click', '[data-action=create]', this.create.bind(this));
		this.gridElement.on('click', '[data-action=update]', this.update.bind(this));
		this.gridElement.on('click', '[data-action=delete]', this.delete.bind(this));
		// approve action
		this.gridElement.on('click', '[data-action=approve]', this.approve.bind(this));

		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Subjects.Management',
			exports: [{
				name: 'XLS export',
				method: 'subject.xlsExport'
			}]
		} as IGridConfiguratorOptions);

		this.gridConfiguratorViewModel = vm;
	}


    private async create(): Promise<void>
    {
        await this.openCreateDialog();
    }

    private async update(): Promise<void>
    {
        var subject: any = this.grid.dataItem(this.grid.select());
        await this.openEditDialog(subject.subjectId);
    }

    private delete()
    {
        this.alertDialog('Neimplementováno', 'TODO');
    }

    /**
     * Approve newly registered subject
     */
    private async approve()
    {
        let yesNo = await this.confirmDialog('Opravdu chcete schválit registraci tohoto sujektu?'); // fixme: missing translation
        if(yesNo) {
            let subject: any = this.grid.dataItem(this.grid.select());
            await this.rpc.call('subject.approve', { id: subject.subjectId });
            this.grid.dataSource.read();
        }
    }

    /**
     * Open editation dialog
     * @param subjectId
	 * @param activateList Otevřít záložku č.
     */
    private async openEditDialog(subjectId: number = null, activateList: number =  null): Promise<void>
    {
		let vm = await this.loadViewFrame<Edit>(Edit, 'edit', {
			subjectId: subjectId,
			activateList: activateList,
			dialog: {
				modal: true,
				width: 1300,
				height: 740,
				title: i18n.t('common.actions.edit'),
				buttons: (vm: Edit, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => window.close()
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: () => vm.save().then(() => {
								window.close();
								this.grid.dataSource.read();
							}).catch(() => { })
						}
					];
				}
			}
		});
    }

	/**
	 * Open create dialog
	 */
	private async openCreateDialog(): Promise<void>
	{
		let vm = await this.loadViewFrame<Create>(Create, 'edit', {
			dialog: {
				modal: true,
				width: 900,
				height: 500,
				title: i18n.t('common.actions.create'),
				buttons: (vm: Edit, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => window.close()
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.saveClose'),
							click: () => vm.save().then(() => {
								window.close();
								this.grid.dataSource.read();
							}).catch(() => { })
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: () => vm.save().then((savedData) => {
								window.close();
								this.openEditDialog(savedData.subjectId, 2);
							}).catch(() => { })
						},
					];
				}
			}
		});
	}

	public template = (): HTMLElement => (

		<div>

			<h2 data-bind="i18n: 'nav.subjects'"></h2>
            <div className="panel">
                <view-frame name="gridconf" className="mb-2" />

                <div data-grid="subjects" className="grid-autoheight"></div>

                <view-frame name="edit" />
            </div>
		</div>
	);

}