import {Addressbook} from "../../entities/Addressbook";
import {Client} from "../../../tracejs/src/net/jsonrpc/Client";
import { singleton } from "tsyringe";
import {Culture} from "../../../tracejs/src/application/Culture";

@singleton()
export class AddressBookManager {

    private rpc: Client;

    constructor(rpc: Client) {
        this.rpc = rpc;
    }

    async getAddressBookWithId(addressBookId: string): Promise<Addressbook> {
        let batch = this.rpc.batch();

        batch.call('selected', 'addressbook.getOne', {
            id: addressBookId,
            deleted: true,
            query: {select: '*,country(iso)'}
        });

        const response: any = await batch.run<Addressbook>();
        const addressBook: Promise<Addressbook> = response['selected'];
        return addressBook;
    }

    async getAllAddresses() : Promise<Addressbook[]> {
        let batch = this.rpc.batch();

        batch.call('addresses', 'addressbook.get', {
            query: {
                select: '*,country(iso)',
                page: 1,
                pageSize: 100,
                sort: [{field: 'name', dir: 'asc'}]
            }
        });

        const response: any = await batch.run<Addressbook[]>();
        return response['addresses'].data;
    }

    async getAddressbooksWithText(searchedText: string): Promise<Addressbook[]> {
        let batch = this.rpc.batch();

        batch.call('addresses', 'addressbook.get', {
            query: {
                search: searchedText,
                select: '*,country(iso)',
                page: 1,
                pageSize: 100,
                sort: [{field: 'name', dir: 'asc'}]
            }
        });

        const response: any = await batch.run<Addressbook[]>();
        return response['addresses'].data;
    }

}