import { i18n, t } from "i18next-ko";
import moment from "moment";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { inject, injectable } from "tsyringe";
import { BaseViewModel } from "../../common/BaseViewModel";
import { TypedCheckpointsHelper } from "../../../model/TypedCheckpointsHelper";
import { Fee } from "../../../entities/Fee";
import { EditCalculator } from "./EditCalculator";
import { TracedoCalculator } from "../../../entities/TracedoCalculator";
import { CodelistManager } from "../../../model/CodelistManager";
import { Client } from "../../../../tracejs/src/net/jsonrpc/Client";
import { Subject } from "../../../entities/Subject";
import { EditCalculatorHelpers } from "./EditCalculatorHelpers";
import { Currency } from "../../../entities/Currency";

export interface ICalculatorSettings {
    /** Tracedo ID */
    id: number;
    /** Road Type ID */
    roadTypeId: KnockoutObservable<number>;
    /** Kind ID */
    kindId: KnockoutObservable<number>;
    /** Is the Calculator read-only? */
    readOnly: boolean;
}

/**
 * Calculator
 */
@injectable()
export class Calculator extends BaseViewModel<ICalculatorSettings>
{

    private costsVisible: KnockoutObservable<boolean>;

    private revenuesVisible: KnockoutObservable<boolean>;

    private costsGridEl: JQuery<HTMLElement>;
    private costsGrid: kendo.ui.Grid;

    private revenuesGridEl: JQuery<HTMLElement>;
    private revenuesGrid: kendo.ui.Grid;

    // Codelist manager
    private codelistManager: CodelistManager;

    // Edit calculator helpers for inline edits
    private editCalculatorHelpers: EditCalculatorHelpers;

    // Edit flag for costs + revenues grid
    private costsRevenuesGridEditRowFlag: boolean = false;


    // Is the calculator read-only ?
    private readOnly: boolean;


    /**
     * Totals (costs + revenues)
     */
    private totals: {
        costs: {
            real: KnockoutObservable<number>,
            expected: KnockoutObservable<number>,
            total: KnockoutComputed<number>
        },
        revenues: {
            real: KnockoutObservable<number>,
            expected: KnockoutObservable<number>,
            total: KnockoutComputed<number>
        },
        netRevenue: KnockoutComputed<number>,
        profitability: KnockoutComputed<number>
    } = {
        costs: {
            real: ko.observable(0),
            expected: ko.observable(0),
            total: ko.pureComputed(() => this.totals ? this.totals.costs.real() + this.totals.costs.expected() : 0)
        },
        revenues: {
            real: ko.observable(0),
            expected: ko.observable(0),
            total: ko.pureComputed(() => this.totals ? this.totals.revenues.real() + this.totals.revenues.expected() : 0)
        },
        netRevenue: ko.pureComputed(() => this.totals ? (this.totals.revenues.total() ?? 0) - (this.totals.costs.total() ?? 0) : 0),
        profitability: ko.pureComputed(() => {
            if(this.totals && this.totals.revenues.total() > 0) {
                return this.totals.netRevenue() / this.totals.revenues.total();
            }
            return 0;
        })
    };


    /**
     * Grids select
     */
    private gridsSelect: string = '*,fee(nameCs,nameEn,ident,group(name)),currency(iso),invoicedCurrency(iso),subject(name),status(ident,name),createdUser(realName)';

    /**
     * Schema Fields - pro costs i revenues stejne
     */
    private gridsSchemaFields: kendo.data.DataSourceSchemaModelFields = {
        'tracedoId': { editable: false, type: "number" },
        'text': { editable: true, type: "string" },
        'description': { editable: true, type: "string" },
        'unit': { editable: true, type: 'string' },
        'unitPrice': { editable: true, type: "number" },
        'amount': { editable: true, type: "number" },
        'price': { editable: true, type: "number" },
        'currencyId': { editable: true, type: "number", validation: { required: true } },
        'currencyIso': { editable: true, type: "string" },
        'priceCzk': { editable: true, type: "number" },
        'exchangeId': { editable: true, type: "number" },
        'feeId': { editable: false, type: "number", validation: { required: true } },
        'fee.nameCs': { editable: false, type: "string" },
        'fee.nameEn': { editable: false, type: "string" },
        'feeGroupName': { editable: true, type: "string" },
        'fee.group.name': { editable: false, type: "string" },
        'costs': { editable: false, type: "boolean" },
        'revenues': { editable: false, type: "boolean" },
        'subjectId': { editable: true, type: "number", validation: { required: true } },
        'subjectName': { editable: false, type: "string" },
        'invoicedPrice': { editable: true, type: "number" },
        'invoicedCurrencyId': { editable: true, type: "number", validation : { required: true } },
        'invoicedCurrencyIso': { editable: true, type: "string" },
        'invoicedExchangeId': { editable: true, type: "number" },
        'statusId': { editable: false, type: "number" },
        'statusName': { editable: false, type: "string" },
        'statusIdent': { editable: false, type: "string" },
        'deleted': { editable: false, type: "boolean" },
        'autogenerated': { editable: false, type: "boolean" },
        'unlocked': { editable: false, type: "boolean" },
    };

    /**
     * Costs Toolbar Config
     */
    private costsToolbarConfig = (): kendo.ui.GridToolbarItem[] => {
		let tb: kendo.ui.GridToolbarItem[] = [];
        if(!this.readOnly) {
            tb.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus"></i><span>' + i18n.t('common.actions.create') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="update"><i class="icon-edit"></i><span>' + i18n.t('common.actions.edit') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-primary d-none" data-action="lock"><i class="icon-lock"></i><span>' + i18n.t('common.actions.lock') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-primary d-none" data-action="unlock"><i class="icon-lock-open-alt"></i><span>' + i18n.t('common.actions.unlock') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>' });
        }
        tb.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="refresh" style="position:absolute;right:0;"><i class="icon-refresh"></i><span>' + i18n.t('common.actions.refresh') + '</span></button>' });
        return tb;
    };

    /**
     * Revenues Toolbar Config
     */
    private revenuesToolbarConfig = (): kendo.ui.GridToolbarItem[] => {
		let tb: kendo.ui.GridToolbarItem[] = [];
        if(!this.readOnly) {
            tb.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus"></i><span>' + i18n.t('common.actions.create') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="update"><i class="icon-edit"></i><span>' + i18n.t('common.actions.edit') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-primary d-none" data-action="lock"><i class="icon-lock"></i><span>' + i18n.t('common.actions.lock') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-primary d-none" data-action="unlock"><i class="icon-lock-open-alt"></i><span>' + i18n.t('common.actions.unlock') + '</span></button>' });
            tb.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>' });

            tb.push({ template: '<button type="button" class="btn btn-outline-primary d-none" data-action="sendRevenuesExport"><i class="icon-mail"></i><span>' + i18n.t('common.captions.revenuesExport') + '</span></button>' });
            tb.push({ template: '<span style="cursor: pointer;" class="btn btn-link text-decoration-none cursor-default" data-selected-count="1"></span>' });
        }
        tb.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="refresh" style="position:absolute;right:0;"><i class="icon-refresh"></i><span>' + i18n.t('common.actions.refresh') + '</span></button>' });
        return tb;
    };

    /**
     * Vraci definici sloupcu pro costs / revenues
     * @param grid 'costs' / 'revenues'
     * @returns 
     */
    private gridsColumns(grid: string): kendo.ui.GridColumn[]
    {
        const loc = this.culture.localeShortCapitalized;

        let cols: kendo.ui.GridColumn[] = [];

        if(grid === 'revenues') {
            cols.push({
                template: (data: TracedoCalculator) => `<input class="k-checkbox" type="checkbox" data-revenues-select="${data.id}" data-id="${data.id}">`,
                headerTemplate: '',
                width: '25px',
                title: i18n.t('common.captions.rowSelection')
            });
        }

        cols.push({
            width: 27,
            template: (data: any) => {
                if(data.autogenerated) {
                    if(data.unlocked) {
                        return '<i style="font-size: 140%" class="icon-lock-open-alt" title="' + i18n.t('common.captions.autogeneratedUnlocked') + '"></i>';
                    }
                    return '<i style="font-size: 140%" class="icon-lock" title="' + i18n.t('common.captions.autogeneratedLocked') + '"></i>';
                }
                return '';
            }
        });
        cols.push({
            field: "subjectId",
            title: i18n.t('common.captions.subject'),
            template: '#= subjectId ? subjectName : \'\' #',
            editor: this.subjectDropDownEditor.bind(this),
            width: 180
        });

        if(grid === 'costs') {
            cols.push({
                field: 'feeId',
                template: '#= feeId ? feeName' + loc + ' : \'\' #',
                editor: this.feeDropDownEditor.bind(this, 'cost'),
                title: i18n.t('common.captions.costItem'),
                width: 130
            });
            cols.push({
                field: 'feeGroupName',
                title: i18n.t('common.captions.costType'),
                editor: (container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions) => {
                    jQuery('<span data-bind="text:' + options.field + '"></span>').appendTo(container);
                },
                width: 125
            });
        }
        else {
            cols.push({
                field: 'feeId',
                template: '#= feeId ? feeName' + loc + ' : \'\' #',
                editor: this.feeDropDownEditor.bind(this, 'revenue'),
                title: i18n.t('common.captions.revenueItem'),
                width: 130
            });
            cols.push({
                field: 'feeGroupName',
                title: i18n.t('common.captions.revenueType'),
                editor: (container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions) => {
                    jQuery('<span data-bind="text:' + options.field + '"></span>').appendTo(container);
                },
                width: 125
            });
        }

        cols.push({ 
            field: "unit",
            title: i18n.t('common.captions.unit'),
            width: 70,
            editor: this.unitEditor.bind(this)
        });
        cols.push({
            field: "amount",
            title: i18n.t('common.captions.unitAmount'),
            width: 115,
            format: '{0:n2}',
            headerAttributes: { class: 'text-end' },
            attributes: { class: 'text-end' },
            editor: this.amountEditor.bind(this)
        });
        cols.push({
            field: "unitPrice",
            title: i18n.t('common.captions.unitPrice'),
            width: 115,
            format: '{0:n2}',
            headerAttributes: { class: 'text-end' },
            attributes: { class: 'text-end' },
            editor: this.unitPriceEditor.bind(this)
        });
        cols.push({
            field: "price",
            title: i18n.t('common.captions.totalPrice'),
            editor: (container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions) => {
                jQuery('<span data-decimals="2" data-format="n2" data-bind="text:' + options.field + '"></span>').appendTo(container);
            },
            width: 115,
            format: '{0:n2}',
            headerAttributes: { class: 'text-end' },
            attributes: { class: 'text-end' }
        });
        cols.push({
            field: "currencyIso",
            title: i18n.t('common.captions.currency'),
            editor: this.currencyDropDownEditor.bind(this, 'currencyId'),
            width: 70
        });
        cols.push({
            field: "invoicedPrice",
            title: i18n.t('common.captions.invoicedPrice'),
            editor: (container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions) => {
                jQuery('<span data-decimals="2" data-format="n2" data-bind="text:' + options.field + '"></span>').appendTo(container);
            },
            width: 115,
            format: '{0:n2}',
            headerAttributes: { class: 'text-end' },
            attributes: { class: 'text-end' }
        });
        cols.push({
            field: "invoicedCurrencyIso",
            title: i18n.t('common.captions.invoicedCurrency'),
            editor: this.currencyDropDownEditor.bind(this, 'invoicedCurrencyId'),
            width: 70
        });
        cols.push({
            field: "priceCzk",
            title: i18n.t('common.captions.priceCzk'),
            editor: (container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions) => {
                jQuery('<span data-decimals="2" data-format="n2" data-bind="text:' + options.field + '"></span>').appendTo(container);
            },
            width: 115,
            format: '{0:n2}',
            headerAttributes: { class: 'text-end' },
            attributes: { class: 'text-end' }
        });
        cols.push({ 
            field: "description",
            title: i18n.t('common.captions.description')
        });
        cols.push({
            field: "statusName",
            title: i18n.t('common.captions.status'),
            headerTemplate: '<div class="text-center">' + i18n.t('common.captions.status') + '</div>',
            template: (data: any) => {
                if(!data.status) {
                    return '';
                }
                let isPlanned = data.statusIdent === 'expected';
                if(!this.readOnly) {
                    let tpl = '<div class="text-center">';
                    tpl += '<input type="checkbox" data-action="changeStatus" class="align-middle" ' + (isPlanned ? '' : ' checked="checked"') + ' />';
                    tpl += '</div>';
                    return tpl;
                }
                else {
                    let tpl = '<div class="text-center">';
                    tpl += (isPlanned ? '<i class="icon-cancel opacity-25 fs-2"></i>' : '<i class="icon-ok fs-2"></i>');
                    tpl += '</div>';
                    return tpl;
                }
            },
            width: 38
        });
        if(!this.readOnly) {
            cols.push({
                command: ['edit'], /// , "destroy"
                title: '&nbsp;',
                width: 170
            });        
        }
        return cols;
    }



    /**
     * Constructor
     * @param rpc 
     * @param codelistManager 
     */
    constructor(@inject(Client) rpc: Client, @inject(CodelistManager) codelistManager: CodelistManager)
    {
        super(rpc);
        this.codelistManager = codelistManager;
    }

    /**
     * Pokud je autogenerated a neni odemknuty, budou editacni pole readonly
     */
    private getAutogenAttrs(model: any)
    {
        return model.autogenerated && !model.unlocked ? ' readonly title="' + i18n.t('common.captions.autogenerated') + '"' : '';
    }

    private unitEditor(container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions)
    {
        const el = jQuery('<input required' + this.getAutogenAttrs(options.model) + ' name="' + options.field + '" class="k-textbox" />');
        el.appendTo(container);
    }
    
    private amountEditor(container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions)
    {
        const el = jQuery('<input required' + this.getAutogenAttrs(options.model) + ' name="' + options.field + '" class="select-on-zero" />');
        el.appendTo(container);
        el.kendoNumericTextBox({
            format: 'n2',
            decimals: 2
        });
    }

    private unitPriceEditor(container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions)
    {
        const el = jQuery('<input required' + this.getAutogenAttrs(options.model) + ' name="' + options.field + '" class="select-on-zero" />');
        el.appendTo(container);
        el.kendoNumericTextBox({
            format: 'n2',
            decimals: 2
        });
    }


    private currencyDropDownEditor(fieldName: string, container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions) 
    {
        const el = jQuery('<input required' + this.getAutogenAttrs(options.model) + ' data-required-msg="' + i18n.t('common.captions.calculatorRequired_' + fieldName) + '" name="' + fieldName + '"/>');
        el.appendTo(container);
        el.kendoDropDownList({
            valuePrimitive: true,
            autoBind: true,
            dataTextField: "iso",
            dataValueField: "currencyId",
            dataSource: this.codelistManager.getCurrencies()
        });
    }

    private subjectDropDownEditor(container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions)
    {
        // let initSubjectId = (options.model as any).subjectId;
        // let initialLoaded = false;
        const el = jQuery('<input required' + this.getAutogenAttrs(options.model) + ' data-required-msg="' + i18n.t('common.captions.calculatorRequired_subjectId') + '" name="' + options.field + '"/>');
        el.appendTo(container);
        el.kendoDropDownList({
            valuePrimitive: true,
            autoBind: true,
            dataTextField: "name",
            dataValueField: "subjectId",
            filter: 'contains',
            dataSource: this.editCalculatorHelpers.subjectComboDataSource
        });
    }

    private feeDropDownEditor(type: string, container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions)
    {
        const loc = this.culture.localeShortCapitalized;
        const el = jQuery('<input required' + this.getAutogenAttrs(options.model) + ' data-required-msg="' + i18n.t('common.captions.calculatorRequired_feeId') + '" name="' + options.field + '"/>');
        el.appendTo(container);
        el.kendoDropDownList({
            valuePrimitive: true,
            autoBind: true,
            dataTextField: 'name' + loc,
            dataValueField: "id",
            dataSource: this.editCalculatorHelpers.fees
        });
    }

    private feeGroupNameEditor(type: string, container: JQuery<HTMLElement>, options: kendo.ui.GridColumnEditorOptions)
    {
        jQuery('<span data-bind="text:' + options.field + '"></span>').appendTo(container);
    }


    /**
     * Change event pro oba gridy
     * @param gridEl 
     */
    private costsRevenuesGridChange(gridEl: JQuery)
    {
        let grid = gridEl.data('kendoGrid');
        let isSelected = grid.select().length > 0;
        let dataItem: any = grid.dataItem(grid.select());
        gridEl.find('[data-action=update]').prop('disabled', !isSelected);
        gridEl.find('[data-action=delete]').prop('disabled', !isSelected);
        if(dataItem.autogenerated) {
            gridEl.find('[data-action=delete]').prop('disabled', true);
            if(dataItem.unlocked) {
                gridEl.find('[data-action=lock]').removeClass('d-none');
                gridEl.find('[data-action=unlock]').addClass('d-none');
            }
            else {
                gridEl.find('[data-action=lock]').addClass('d-none');
                gridEl.find('[data-action=unlock]').removeClass('d-none');
            }
        }
        else {
            gridEl.find('[data-action=lock]').addClass('d-none');
            gridEl.find('[data-action=unlock]').addClass('d-none');
        }
    }

    /**
     * Databound event pro oba gridy
     * @param gridEl 
     */
    private costsRevenuesGridDataBound(gridEl: JQuery)
    {
        //let grid = gridEl.data('kendoGrid');
        gridEl.find('[data-action=update]').prop('disabled', true);
        gridEl.find('[data-action=delete]').prop('disabled', true);
        gridEl.find('[data-action=lock]').addClass('d-none');
        gridEl.find('[data-action=unlock]').addClass('d-none');
    }
    
    /**
     * Konfigurace GRIDu nakladu
     */
    private costsGridConfig = (): kendo.ui.GridOptions => ({
        dataSource: {
            transport: {
                read: async (options: kendo.data.DataSourceTransportOptions) => {
                    let batch = this.rpc.batch();
                    batch.call('costs', 'calculator.getCosts', {
                        tracedoId: this.settings.id,
                        query: {
                            ...options.data,
                            select: this.gridsSelect
                        }
                    });
                    batch.call('template', 'calculator.getTemplateCostsByTracedo', {
                        tracedoId: this.settings.id,
                        query: {
                            select: '*,group(*)',
                            sort: [{ field: 'subjectId', dir: 'asc' }, { field: 'nameCs', dir: 'asc' }]
                        }
                    });

                    let response: any = await batch.run();

                    let combined = this.combineTemplateWithFees('costs', response['template'], response['costs']);

                    options.success(combined);
                },
                update: this.costsRevenuesGridDataUpdate.bind(this)
            },
            requestEnd: (e: any) => {
                if(e.type === 'update') {
                    this.updateTotals();
                }
            },
            schema: {
                model: {
                    id: 'id',
                    fields: this.gridsSchemaFields
                }
            },
            // sort: { field: "id", dir: "asc" },
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false
        },
        editable: 'inline',
        selectable: 'row',
        scrollable: true,
        reorderable: false,
        columnMenu: false,
        resizable: true,
        filterable: false,
        sortable: true,
        pageable: false,
        toolbar: this.costsToolbarConfig(),
        beforeEdit: this.costsRevenuesBeforeEditHandler.bind(this, 'costs'),
        edit: this.costsRevenuesEditHandler.bind(this),
        dataBound: () => {
            this.costsRevenuesGridDataBound(this.costsGridEl);
        },
        change: (arg: kendo.ui.GridChangeEvent) => {
            this.costsRevenuesGridChange(this.costsGridEl);
        },
        columns: this.gridsColumns('costs')
    });

    /**
     * Konfigurace GRIDu vynosu
     */
    private revenuesGridConfig = (): kendo.ui.GridOptions => ({
        dataSource: {
            transport: {
                read: async (options: kendo.data.DataSourceTransportOptions) => {
                    let batch = this.rpc.batch();
                    batch.call('revenues', 'calculator.getRevenues', {
                        tracedoId: this.settings.id,
                        query: {
                            ...options.data,
                            select: this.gridsSelect
                        }
                    });
                    batch.call('template', 'calculator.getTemplateRevenuesByTracedo', {
                        tracedoId: this.settings.id,
                        query: {
                            select: '*,group(*)',
                            sort: [{ field: 'subjectId', dir: 'asc' }, { field: 'nameCs', dir: 'asc' }]
                        }
                    });

                    let response: any = await batch.run();

                    let combined = this.combineTemplateWithFees('revenues', response['template'], response['revenues']);

                    options.success(combined);
                },
                update: this.costsRevenuesGridDataUpdate.bind(this)
            },
            requestEnd: (e: any) => {
                if(e.type === 'update') {
                    this.updateTotals();
                }
            },
            schema: {
                model: {
                    id: 'id',
                    fields: this.gridsSchemaFields
                }
            },
            //sort: { field: "id", dir: "asc" },
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false
        },
        editable: 'inline',
        selectable: 'row',
        scrollable: true,
        reorderable: false,
        columnMenu: false,
        resizable: true,
        filterable: false,
        sortable: true,
        pageable: false,
        toolbar: this.revenuesToolbarConfig(),
        beforeEdit: this.costsRevenuesBeforeEditHandler.bind(this, 'revenues'),
        edit: this.costsRevenuesEditHandler.bind(this),
        dataBound: () => {
            this.costsRevenuesGridDataBound(this.revenuesGridEl);
        },
        change: (arg: kendo.ui.GridChangeEvent) => {
            this.costsRevenuesGridChange(this.revenuesGridEl);
        },
        columns: this.gridsColumns('revenues')
    });


    /**
     * Costs + Revenues grid before edit event handler
     * @param e 
     */
    private async costsRevenuesBeforeEditHandler(type: string, e: kendo.ui.GridBeforeEditEvent)
    {
        if(!e.model.id) {
            // predvyplnit 1 jako mnozstvi, pokud polozka neexistuje - je to sablona
            e.model.set('amount', 1);
            // predvyplnit "ks" jako jednotku, pokud polozka neexistuje - je to sablona
            e.model.set('unit', 'ks');
        }

        if(type === 'revenues') {
            if(this.costsGrid && this.costsGridEl.find('.k-grid-edit-row').length > 0) {
                this.costsGrid.cancelRow();
            }
        }
        else {
            if(this.revenuesGrid && this.revenuesGridEl.find('.k-grid-edit-row').length > 0) {
                this.revenuesGrid.cancelRow();
            }
        }

        let row = e.sender.tbody.find('tr[data-uid="'+ e.model.uid+ '"]');
        if(!this.costsRevenuesGridEditRowFlag) {
            e.preventDefault();
            let cFor = type === 'revenues' ? 'revenue' : 'cost';
            await this.editCalculatorHelpers.configureFor(cFor, e.model as any);
            this.costsRevenuesGridEditRowFlag = true;
            e.sender.editRow(row);
        }
        else {
            this.costsRevenuesGridEditRowFlag = false;
        }
    }

    /**
     * Costs + Revenues grid edit handler
     * @param e 
     */
    private async costsRevenuesEditHandler(e: kendo.ui.GridEditEvent)
    {
        let model = e.model as any;
        model.bind('change', (e: any) => {
            switch(e.field) {
                case 'feeId':
                    this.editCalculatorHelpers.calculator.feeId(model.feeId);
                    break;
                case 'amount':
                    this.editCalculatorHelpers.calculator.amount(model.amount);
                    break;
                case 'unitPrice':
                    this.editCalculatorHelpers.calculator.unitPrice(model.unitPrice);
                    break;
                case 'currencyId':
                    this.editCalculatorHelpers.calculator.currencyId(model.currencyId);
                    break;
                case 'invoicedCurrencyId':
                    this.editCalculatorHelpers.calculator.invoicedCurrencyId(model.invoicedCurrencyId);
                    break;
            }

            model.set('feeGroupName', this.editCalculatorHelpers.feeGroupName());
            model.set('price', this.editCalculatorHelpers.calculator.price());
            model.set('priceCzk', this.editCalculatorHelpers.calculator.priceCzk());
            model.set('invoicedPrice', this.editCalculatorHelpers.calculator.invoicedPrice());
            model.set('invoicedCurrencyIso', this.editCalculatorHelpers.invoicedCurrencyIso());
            model.set('currencyIso', this.editCalculatorHelpers.currencyIso());
            model.set('exchangeId', this.editCalculatorHelpers.calculator.exchangeId());
            model.set('invoicedExchangeId', this.editCalculatorHelpers.calculator.invoicedExchangeId());
        });
    }

    private async costsRevenuesGridDataUpdate(options: kendo.data.DataSourceTransportOptions)
    {
        let calc: any = options.data as any;
        delete calc['currency'];
        delete calc['currencyIso'];
        delete calc['exchange'];
        delete calc['fee'];
        delete calc['feeNameCs'];
        delete calc['feeNameEn'];
        delete calc['feeGroupName'];
        delete calc['invoicedCurrency'];
        delete calc['invoicedCurrencyIso'];
        delete calc['invoicedExchange'];
        delete calc['status'];
        delete calc['statusName'];
        delete calc['statusIdent'];
        delete calc['subject'];
        delete calc['subjectName'];
        let saved: any = await this.rpc.call('calculator.save', {
            tracedoId: this.settings.id,
            calculator: calc,
            query: {
                select: '*,currency(iso),invoicedCurrency(iso),fee(*,group(name)),subject(name),status(ident,name)'
            }
        });
        this.boostCalculatorForGrid(saved);
        options.success(saved);
    }



    /**
     * Startup
     */
    public async startup(): Promise<any>
    {
        await super.startup();

        this.costsVisible = ko.observable(false);
        this.revenuesVisible = ko.observable(false);
        this.costsGrid = null;
        this.revenuesGrid = null;

        this.costsGridEl = this.element.find('.costs-grid');
        this.revenuesGridEl = this.element.find('.revenues-grid');

        this.editCalculatorHelpers = new EditCalculatorHelpers(this.rpc, this.codelistManager, {
            tracedoId: this.settings.id,
            kindId: this.settings.kindId,
            roadTypeId: this.settings.roadTypeId
        });

        // TODO: additional logic - permissions ?
        this.readOnly = this.settings.readOnly;

        await this.updateTotals();
    }

    /**
     * Naplni zkratkove promenne z vnorenych objektu
     * @param calc 
     */
    private boostCalculatorForGrid(calc: TracedoCalculator)
    {
        calc.feeGroupName = calc.fee && calc.fee.group ? calc.fee.group.name : null;
        calc.feeNameCs = calc.fee ? calc.fee.nameCs : null;
        calc.feeNameEn = calc.fee ? calc.fee.nameEn : null;
        calc.statusName = calc.status ? calc.status.name : null;
        calc.statusIdent = calc.status ? calc.status.ident : null;
        calc.subjectName = calc.subject ? calc.subject.name : null;
        calc.currencyIso = calc.currency ? calc.currency.iso : null;
        calc.invoicedCurrencyIso = calc.invoicedCurrency ? calc.invoicedCurrency.iso : null;
    }

    /**
     * Kombinace poplatku na sablone s existujicimi polozkami kalkulatoru
     */
    private combineTemplateWithFees(type: string, template: Array<Fee>, calculator: Array<TracedoCalculator>): Array<TracedoCalculator>
    {
        // nalezeny subject v existujicich polozkach kalkulatoru
        let foundSubjectId: number = null;
        let foundSubjectName: string = null;

        let calcHash: { [key: number]: TracedoCalculator[] } = {};
        calculator.forEach(calc => {
            this.boostCalculatorForGrid(calc);
            if(!calcHash[calc.feeId]) {
                calcHash[calc.feeId] = [];
            }
            calcHash[calc.feeId].push(calc);
            if(!foundSubjectId || (calc.fee!== null && calc.fee.ident == 'freightCosts')) {
                foundSubjectId = calc.subjectId;
                foundSubjectName = calc.subjectName;
            }
        });

        let result: Array<TracedoCalculator> = [];
        template.forEach(tpl => {
            if(calcHash[tpl.id] && calcHash[tpl.id].length > 0) {
                let item = calcHash[tpl.id].shift();
                result.push(item);
                //delete calcHash[tpl.id];
            }
            else {
                result.push({
                    id: null,
                    tracedoId: this.settings.id,
                    feeId: tpl.id,
                    feeNameCs: tpl.nameCs,
                    feeNameEn: tpl.nameEn,
                    feeGroupName: tpl.group.name,
                    fee: tpl,
                    amount: 0,
                    currency: null,
                    currencyId: null,
                    currencyIso: null,
                    description: null,
                    invoicedCurrency: null,
                    invoicedCurrencyId: null,
                    invoicedCurrencyIso: null,
                    invoicedPrice: 0,
                    price: 0,
                    priceCzk: 0,
                    status: null,
                    statusId: null,
                    statusName: null,
                    statusIdent: null,
                    subject: null,
                    subjectId: foundSubjectId,
                    subjectName: foundSubjectName,
                    unit: '',
                    unitPrice: 0,
                    exchange: null,
                    exchangeId: null,
                    invoicedExchange: null,
                    invoicedExchangeId: null,
                    deleted: false,
                    text: '',
                    costs: type === 'costs',
                    revenues: type !== 'costs'
                });
            }
        });

        for(let feeId in calcHash) {
            for(let ix in calcHash[feeId]) {
                result.push(calcHash[feeId][ix]);
            }
        }

        return result;
    }

    /**
     * Update totals from server
     */
    public async updateTotals(): Promise<void>
    {
        let totals: any = await this.rpc.call('calculator.getTotal', { tracedoId: this.settings.id });
        this.totals.costs.real(totals.costs.real);
        this.totals.costs.expected(totals.costs.expected);
        this.totals.revenues.real(totals.revenues.real);
        this.totals.revenues.expected(totals.revenues.expected);
    }

    /**
     * Costs grid
     */
    public openCosts(): void
    {
        // sbalit
        if(this.costsVisible()) {
            this.costsVisible(false);
            return;
        }

        // rozbalit
        this.costsVisible(true);
        if(this.costsGrid) {
            return;
        }

        // Initializace gridu - pokud jeste neni
        this.costsGrid = this.costsGridEl.kendoGrid(this.costsGridConfig()).data('kendoGrid');
    }

    /**
     * Revenues grid
     */
    public openRevenues(): void
    {
        // sbalit
        if(this.revenuesVisible()) {
            this.revenuesVisible(false);
            return;
        }

        // rozbalit
        this.revenuesVisible(true);
        if(this.revenuesGrid) {
            return;
        }

        // Initializace gridu - pokud jeste neni
        this.revenuesGrid = this.revenuesGridEl.kendoGrid(this.revenuesGridConfig()).data('kendoGrid');
    }


    public refreshCosts(): void
    {
        this.costsGrid.dataSource.read();
        this.updateTotals();
    }

    public refreshRevenues(): void
    {
        this.revenuesGrid.dataSource.read().done(() => {
            this.refreshSelectedRevenuesCount();
        });
        this.updateTotals();
    }

    public refreshSelectedRevenuesCount()
    {
        let checkedData = this.getRevenuesGridCheckedRows();
        let isChecked = checkedData.length > 0;
        if(isChecked) {
            this.revenuesGridEl.find('button[data-action=sendRevenuesExport]').addClass('d-inline-block').removeClass('d-none');
        }
        else {
            this.revenuesGridEl.find('button[data-action=sendRevenuesExport]').addClass('d-none').removeClass('d-inline-block');
        }
        // how many selected
        this.revenuesGridEl.find('span[data-selected-count=1]').html(
            '<strong>' + (isChecked && checkedData.length > 0 ? i18n.t('common.captions.selectedCount') + ': ' + checkedData.length : '') + '</strong>'
        );
    }

    public async sendRevenuesExport(): Promise<void>
    {
        const checkedRows = this.getRevenuesGridCheckedRows();
        if(checkedRows.length <= 0) {
            kendo.alert(i18n.t('common.captions.revenuesExportSelectItems'));
            return;
        }

        let checkedIds: number[] = [];
        checkedRows.forEach((row: any) => {
            checkedIds.push(row.id);
        });

        let params = {
            lang: this.culture.localeShort,
            selectedIds: checkedIds,
            setReal: true
        };

        const response: number = await this.rpc.call('calculator.sendRevenuesExport', params);
        if(response <= 0) {
            kendo.alert(i18n.t('common.captions.revenuesExportNotSent'));
        }
        this.refreshRevenues();
        kendo.alert(i18n.t('common.captions.revenuesExportSent'));
    }

	public getRevenuesGridCheckedRows()
	{
        
		const rows = this.revenuesGrid.tbody.find("tr");
		var checkedRowsData: any[] = [];
		rows.each((i: number, row: HTMLTableRowElement) => {
			const $row = $(row);
			const checkbox = $row.find("input[data-revenues-select][type=checkbox]");
			if (checkbox.is(":checked")) {
				var dataItem = this.revenuesGrid.dataItem($row);
				checkedRowsData.push(dataItem);
			}
		});
		return checkedRowsData;
	}

    public async rendered()
    {
        await super.rendered();
        
        // bind costs actions
		this.costsGridEl.on('click', '[data-action=create]', this.insertCost.bind(this));
		this.costsGridEl.on('click', '[data-action=delete]', this.deleteCost.bind(this));
		this.costsGridEl.on('click', '[data-action=update]', this.editCost.bind(this));
		this.costsGridEl.on('click', '[data-action=refresh]', () => { this.refreshCosts(); });
        this.costsGridEl.on('change', 'input[data-action=changeStatus]', (e: any) => { this.changeCostStatus(jQuery(e.target).closest('tr')); });
        //this.costsGridEl.on('dblclick', 'tbody tr', this.editCost.bind(this));

        // bind revenues actions
		this.revenuesGridEl.on('click', '[data-action=create]', this.insertRevenue.bind(this));
		this.revenuesGridEl.on('click', '[data-action=delete]', this.deleteRevenue.bind(this));
		this.revenuesGridEl.on('click', '[data-action=update]', this.editRevenue.bind(this));
		this.revenuesGridEl.on('click', '[data-action=refresh]', () => { this.refreshRevenues(); });
        this.revenuesGridEl.on('change', 'input[data-action=changeStatus]', (e: any) => { this.changeRevenueStatus(jQuery(e.target).closest('tr')); });
        //this.revenuesGridEl.on('dblclick', 'tbody tr', this.editRevenue.bind(this));
        this.revenuesGridEl.on('click', '[data-action=sendRevenuesExport]', this.sendRevenuesExport.bind(this));

        // bind lock / unlock
        this.costsGridEl.on('click', '[data-action=lock]', this.lockCost.bind(this));
        this.costsGridEl.on('click', '[data-action=unlock]', this.unlockCost.bind(this));
        this.revenuesGridEl.on('click', '[data-action=lock]', this.lockRevenue.bind(this));
        this.revenuesGridEl.on('click', '[data-action=unlock]', this.unlockRevenue.bind(this));

		// on checkbx clicked - stop propagation immediately
		this.revenuesGridEl.on('click', 'input[data-revenues-select][type=checkbox]', (event: JQuery.ClickEvent) => {
			event.stopPropagation();
            this.refreshSelectedRevenuesCount();
		});
    }

    /**
     * Change status of cost item
     */
    private async changeCostStatus(tr: JQuery<HTMLElement>)
    {
        let cost: any = this.costsGrid.dataItem(tr);
        let isPaid = cost.statusIdent !== 'expected';
        await this.rpc.call('calculator.setStatus', {
            tracedoId: this.settings.id,
            id: cost.id,
            status: isPaid ? 'expected' : 'real'
        });
        this.refreshCosts();
    }

    /**
     * Change status of revenue item
     */
    private async changeRevenueStatus(tr: JQuery<HTMLElement>)
    {
        let revenue: any = this.revenuesGrid.dataItem(tr);
        let isPaid = revenue.statusIdent !== 'expected';
        await this.rpc.call('calculator.setStatus', {
            tracedoId: this.settings.id,
            id: revenue.id,
            status: isPaid ? 'expected' : 'real'
        });
        this.refreshRevenues();
    }

    /**
     * Delete selected cost
     */
    private deleteCost()
    {
        let selected = this.costsGrid.select();
        let cost: any = this.costsGrid.dataItem(selected);
        this.confirmDialog(i18n.t('common.captions.reallyDeleteCost')).then(async (result: boolean) => {
            if(result) {
                await this.rpc.call('calculator.delete', { tracedoId: this.settings.id, id: cost.id });
                this.refreshCosts();
            }
        });        
    }

    /**
     * Delete selected revenue
     */
    private deleteRevenue()
    {
        let selected = this.revenuesGrid.select();
        let revenue: any = this.revenuesGrid.dataItem(selected);
        this.confirmDialog(i18n.t('common.captions.reallyDeleteRevenue')).then(async (result: boolean) => {
            if(result) {
                await this.rpc.call('calculator.delete', { tracedoId: this.settings.id, id: revenue.id });
                this.refreshRevenues();
            }
        });
    }


    /**
     * Uzamknout naklad
     */
    private lockCost()
    {
        let selected = this.costsGrid.select();
        let cost: any = this.costsGrid.dataItem(selected);
        this.confirmDialog(i18n.t('common.captions.reallyLockCost')).then(async (result: boolean) => {
            if(result) {
                await this.rpc.call('calculator.lock', { tracedoId: this.settings.id, id: cost.id });
                this.refreshCosts();
            }
        });
    }

    /**
     * Odemknout naklad
     */
    private unlockCost()
    {
        let selected = this.costsGrid.select();
        let cost: any = this.costsGrid.dataItem(selected);
        this.confirmDialog(i18n.t('common.captions.reallyUnlockCost')).then(async (result: boolean) => {
            if(result) {
                await this.rpc.call('calculator.unlock', { tracedoId: this.settings.id, id: cost.id });
                this.refreshCosts();
            }
        });        
    }

    /**
     * Uzamknout vynos
     */    
    private lockRevenue() 
    {
        let selected = this.revenuesGrid.select();
        let revenue: any = this.revenuesGrid.dataItem(selected);
        this.confirmDialog(i18n.t('common.captions.reallyLockRevenue')).then(async (result: boolean) => {
            if(result) {
                await this.rpc.call('calculator.lock', { tracedoId: this.settings.id, id: revenue.id });
                this.refreshRevenues();
            }
        });        
    }

    /**
     * Odemknout vynos
     */    
    private unlockRevenue() 
    {
        let selected = this.revenuesGrid.select();
        let revenue: any = this.revenuesGrid.dataItem(selected);
        this.confirmDialog(i18n.t('common.captions.reallyUnlockRevenue')).then(async (result: boolean) => {
            if(result) {
                await this.rpc.call('calculator.unlock', { tracedoId: this.settings.id, id: revenue.id });
                this.refreshRevenues();
            }
        });        
    }


    /**
     * Insert new cost
     */
    private insertCost()
    {
        this.openCalculatorEditDialog('cost', null);
    }

    /**
     * Insert new revenue
     */
    private async insertRevenue()
    {
        return this.openCalculatorEditDialog('revenue', null);
    }


    private async editCost()
    {
        let selected = this.costsGrid.select();
        let cost: any = this.costsGrid.dataItem(selected);
        return this.openCalculatorEditDialog('cost', cost);
    }

    private async editRevenue()
    {
        let selected = this.revenuesGrid.select();
        let revenue: any = this.revenuesGrid.dataItem(selected);
        return this.openCalculatorEditDialog('revenue', revenue);
    }

    private async openCalculatorEditDialog(type: string, item: TracedoCalculator = null)
    {
        return await this.loadViewFrame<EditCalculator>(EditCalculator, 'edit-calculator', {
            tracedoId: this.settings.id,
            type: type,
            roadTypeId: this.settings.roadTypeId,
            kindId: this.settings.kindId,
            calculator: item,
            dialog: {
                width: 420,
                height: 600,
                modal: true,
                title: i18n.t('common.captions.calculator' + (item && item.id ? 'Edit' : 'Create') + (type == 'revenue' ? 'Revenue' : 'Cost')),
                buttons: (editVm: EditCalculator, window: kendo.ui.Window) => {
                    return [{
                        align: 'right',
                        cls: 'btn-link',
                        label: i18n.t('common.actions.cancel'),
                        click: async () => {
                            window.close();
                        }
                    }, {
                        align: 'right',
                        cls: 'btn-primary',
                        label: i18n.t('common.actions.save'),
                        click: async () => {
                            // save
                            let result = await editVm.save();
                            if(result) {
                                // refresh grid
                                type == 'revenue' ? this.refreshRevenues() : this.refreshCosts();
                                // close dialog
                                window.close();
                            }
                        }
                    }];
                }
            }
        });        
    }


	/**
	 * Template
	 */
    public template = (): HTMLElement => (
		<div>
			<table className="table table-fixed table-calculator">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-center">
                            <big data-bind="i18n: 'common.captions.calculatorReal'"></big>
                        </th>
                        <th className="text-center">
                            <big data-bind="i18n: 'common.captions.calculatorExpected'"></big>
                        </th>
                        <th className="text-center">
                            <big data-bind="i18n: 'common.captions.calculatorTotal'"></big>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            <button className="expand-collapse-btn" data-bind="click: $root.openCosts.bind($root)">
                                <big data-bind="i18n: 'common.captions.costs'"></big>
                                <i data-bind="css: { 'icon-down-dir': $root.costsVisible(), 'icon-right-dir': !$root.costsVisible() }"></i>
                            </button>
                        </th>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.costs.real(), 'n2')"></big>
                        </td>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.costs.expected(), 'n2')"></big>
                        </td>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.costs.total(), 'n2')"></big>
                        </td>
                    </tr>
                    <tr data-bind="visible: $root.costsVisible">
                        <td colspan="4">

                            <div className="calculator-grid costs-grid"></div>

                        </td>
                    </tr>
                    <tr>
                        <th>
                            <button className="expand-collapse-btn" data-bind="click: $root.openRevenues.bind($root)">
                                <big data-bind="i18n: 'common.captions.revenues'"></big>
                                <i data-bind="css: { 'icon-down-dir': $root.revenuesVisible(), 'icon-right-dir': !$root.revenuesVisible() }"></i>
                            </button>
                        </th>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.revenues.real(), 'n2')"></big>
                        </td>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.revenues.expected(), 'n2')"></big>
                        </td>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.revenues.total(), 'n2')"></big>
                        </td>
                    </tr>
                    <tr data-bind="visible: $root.revenuesVisible">
                        <td colspan="4">
                            
                            <div className="calculator-grid revenues-grid"></div>

                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th><big data-bind="i18n: 'common.captions.netRevenue'"></big></th>
                        <td className="text-center" colspan="2"></td>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.netRevenue(), 'n2')"></big>
                        </td>
                    </tr>
                    <tr>
                        <th><big data-bind="i18n: 'common.captions.profitability'"></big></th>
                        <td className="text-center" colspan="2"></td>
                        <td className="text-center">
                            <big className="very-big fw-500" data-bind="text: kendo.toString($root.totals.profitability(), 'n2')"></big>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <view-frame name="edit-calculator"></view-frame>

		</div>
	);

}