﻿export let cs = {
  "forbidden": {
    "caption": "Zakázáno",
    "message": "Nemáte povolen přístup do této sekce."
  },
  "oldBrowser": {
    "caption": "Váš internetový prohlížeč je zastaralý",
    "message": "Tuto aplikaci nelze ve vašem internetovém prohlížeči spustit. Prosíme, použijte minimálně Microsoft Internet Explorer 10 a vyšší, případně aktuální verzi prohlížeče Google Chrome či Mozilla Firefox. Děkujeme za pochopení a omlouváme se za tuto nepříjemnost."
  },
  "trial": {
    "tracedoExceeded": "Byl vyčerpán maximální povolený počet vytvořených přeprav. Pokud chcete pokračovat, kontaktujte <a href=\"mailto:hello@tracedo.com\">hello@tracedo.com</a>."
  },
  "common": {
    "formats": {
      "date": "{0:dd.MM.yyyy}",
      "dateTime": "{0:dd.MM.yyyy HH:mm}",
      "kendoDate": "dd.MM.yyyy",
      "kendoDateTime": "dd.MM.yyyy HH:mm",
      "kendoTime": "HH:mm",
      "momentTime": "HH:mm",
      "momentDate": "Do MMM"
    },
    "captions": {
      "syncInfo": "Synchronizovat na všechny přepravy ve skupině",

      "revenuesExport": "Odeslat export výnosů emailem",
      "revenuesExportSelectItems": "Označte nejprve výnosy, které chcete odeslat emailem jako podklad k fakturaci",
      "revenuesExportNotSent": "Export výnosů nebyl odeslán. Pravděpodobně nemáte definované příjemce v nastavení společnosti.",
      "revenuesExportSent": "Export výnosů byl odeslán na definované příjemce a na vás v kopii.",

      "addToGroup": "Přiřadit do skupiny",
      "removeFromGroup": "Odebrat ze skupiny",
      "rowSelection": "Výběr řádků",
      "pleaseSelectGroup": "Prosím, zvolte skupinu",
      "noGroupStartTyping": "Začněte psát pro vyhledání nebo založení nové skupiny",
      "noGroupAddNew": "Tuto skupinu ještě nemáte, přejete si založit skupinu ",
      "addNewGroup": "Přidat skupinu",
      "selectGroup": "Vyberte skupinu",
      "newlyAddedItems": "Nově přidávané položky",
      "alreadyContainedItems": "Již obsažené položky",
      "alreadyContainerInfo": "Položka je již ve skupině obsažena a nebude tedy přidána",
      "inSomeContainer": "Položka je již obsažena v nějaké skupině. Pokud ji přiřadíte do nové skupiny, bude z původní skupiny odebrána.",
      "tracedoGroupName": "Název skupiny",
      "tracedoGroupNumber": "Číslo skupiny",
      "tracedoGroupItems": "Přepravy v této skupině",
      "partOfTheGroupInfo": "Tato přeprava je součástí skupiny. Editace u polí, která zůstanou zaškrtnuta, bude mít za následek propsání stejné hodnoty na všechny přepravy z této skupiny. Pokud nechcete nějaké informace propisovat, odškrtněte u nich checkbox.",

      "reallyDeleteApiKey": "Opravdu chcete odebrat tento API klíč? Všechny napojené aplikace, které klíč používají, přestanou fungovat správně.",
      "createdStamp": "Vytvořeno",
      "apiKey": "API klíč",
      "apiKeys": "API klíče",
      "apiKeyInfo": "<h1>Toto je váš nový API klíč</h1><p>Zkopírujte si prosím tento klíč a uložte jej na bezpečném místě. Z bezpečnostních důvodů vám jej nebudeme moci zobrazit znovu.</p>",

      "attributes": "Atributy",

      "autogeneratedUnlocked": "Automaticky generováno, nesynchronizováno, odemčeno pro editaci",
      "autogeneratedLocked": "Automaticky generováno, synchronizováno, zamčeno pro editaci",

      "reallyLockCost": "Pokud náklad uzamknete pro editaci, bude automaticky synchronizován s nákupní cenou přepravy. Chcete pokračovat?",
      "reallyUnlockCost": "Pokud náklad odemknete pro editaci, nebude již automaticky synchronizován s nákupní cenou přepravy. Chcete pokračovat?",
      "reallyLockRevenue": "Pokud výnos uzamknete pro editaci, bude automaticky synchronizován s prodejní cenou přepravy. Chcete pokračovat?",
      "reallyUnlockRevenue": "Pokud výnos odemknete pro editaci, nebude již automaticky synchronizován s prodejní cenou přepravy. Chcete pokračovat?",

      "autogenerated": "Generováno automaticky",

      "truncatedOutput": "Výstup omezen z důvodu výkonu. Prosím zpřesněte filtr! Vráceno <strong>%sent%</strong> záznamů z <strong>%total%</strong>.",
      "generate": "Generovat",
      "invoiceBasis": "Podklad faktury",
      "selectedCount": "Počet označených záznamů",
      "realCost": "Skutečný náklad",
      "fee": "Poplatek",
      "tracedoItem": "Přeprava",

      "load": "Nakládka",
      "loads": "Nakládky",
      "unload": "Vykládka",
      "unloads": "Vykládky",

      "generateCMR": "Generovat CMR",
      "generateCMRPrompt": "Zvolte zastávky, pro které chcete vygenerovat CMR dokument.",

      "calculatorRequired_subjectId": "Vyberte subjekt",
      "calculatorRequired_feeId": "Vyberte poplatek",
      "calculatorRequired_invoicedCurrencyId": "Vyberte fakturační měnu",
      "calculatorRequired_currencyId": "Vyberte měnu",
      "enterAtLeastLastName": "Zadejte alespoň příjmení ke kontaktní osobě",		

      "customFee": " Vlastní",
      "inTemplate": "Defaultní",
      "inTemplateCosts": "Default<br>(náklady)",
      "inTemplateRevenues": "Default<br>(výnosy)",
      "inTemplatePosIndex": "Pozice",
      "reallyDeleteFee": "Opravdu smazat tento poplatek?",
      "feeAssign": "Zařazení poplatku",
      "nameAndGroup": "Název a skupina",
      "checkCostsRevenues": "Zaškrtněte náklady, výnosy nebo oboje",

      "customFeeEdit": "Upravit poplatek",
      "customFeeCreate": "Vytvořit poplatek",

      "reallyDeleteRevenue": "Opravdu smazat tento výnos?",
      "reallyDeleteCost": "Opravdu smazat tento náklad?",
      "exchangeUsed": "Použitý kurz",
      "tradeCurrencyUsed": "Použitý obchodní kurz: ",
      "noExchangeRate": "Kurz nenalezen!",
      "amount": "Počet jednotek",

      "calculator": "Kalkulátor",
      "calculatorCreateCost": "Vytvořit náklad",
      "calculatorCreateRevenue": "Vytvořit výnos",
      "calculatorEditCost": "Upravit náklad",
      "calculatorEditRevenue": "Upravit výnos",

      "calculatorReal": "Skutečné",
      "calculatorExpected": "Očekávané",
      "calculatorTotal": "Celkem",
      "costs": "Náklady",
      "revenues": "Výnosy",
      "netRevenue": "Čistý výnos",
      "profitability": "Ziskovost",
      "actualCosts": "Skutečné náklady",
      "remainingCosts": "Očekávané náklady",
      "totalCosts": "Celkové náklady",
      "actualRevenues": "Skutečné výnosy",
      "remainingRevenues": "Očekávané výnosy",
      "totalRevenues": "Celkové výnosy",
      "netRevenues": "Čistý výnos",

      "selectSubject": "Zvolte subjekt",
      "selectFee": "Zvolte poplatek",
      "feeCost": "Položka nákladu",
      "feeRevenue": "Položka výnosu",
      "feeGroup": "Skupina",
      "feeGroupCost": "Typ nákladu",
      "feeGroupRevenue": "Typ výnosu",

      "subject": "Subjekt",
      "subjects": "Subjekty",
      "users": "Uživatelé",
      "costItem": "Položka nákladu",
      "revenueItem": "Položka výnosu",
      "costType": "Typ nákladu",
      "revenueType": "Typ výnosu",
      "unit": "Jednotka",
      "unitAmount": "Počet jednotek",
      "unitPrice": "Cena za jednotku",
      "totalPrice": "Celkem",
      "currency": "Měna",
      "invoicedCurrency": "Fakturační měna",
      "invoicedPrice": "Fakturovaná částka",
      "priceCzk": "Ekvivalent v CZK",
      "status": "Stav",
      
      "defaultNote": "Výchozí poznámka",

      "sellPriceDetail": "Prodejní cena",
      "purchasePriceDetail": "Nákupní cena",

      "loading": "Načítám...",
      "authorNote": "Poznámka autora",
      "proposePrice": "Navrhnout cenu",

      "willBeGeneratedAfterSave": "bude vygenerováno po uložení",
      "detail": "Detail",
      "note": "Poznámka",
      "noLogPrices": "Žádné ceny...",
      "priceLogCaption": "Historie cen",
      "priceLog": {
        "supplier": "Cena prodej",
        "customer": "Cena nákup"
      },
      "acceptedByCustomer": "Akceptováno<br/>zákazníkem",
      "acceptedBySupplier": "Akceptováno<br/>dopravcem",
      "acceptedByYou": "Akceptováno<br/>vámi",

      "mainInformation": "Základní informace",
      "identification": "Identifikace",
      "identificationAndSubjects": "Identifikace a subjekty",
      "containerInformation": "Kontejner",
      "cargoInformation": "Informace o nákladu",
      "driverInformation": "Informace o řidiči",
      "parties": "Subjekty",
      "pricesInformation": "Cena",

      "cannotSetDriver": "Nemůžete přiřadit řidiče, nejste dopravcem.",

      "subjectRole": {
        "customer": "Zákazník",
        "spediter": "Speditér",
        "mediator": "Prostředník",
        "carrier": "Dopravce"
      },
      "subjectsSubjectsRole": {
        "partner": "Partner",
        "carrier": "Dopravce"
      },
      "autoApprovePrice": "Automaticky schvalovat cenu",
      "connectWithSubject": "Vazba se subjektem",
      "active" : "Aktivní",

      "role": "Role",
      "pass": "Postoupit",
      "changeCarrier": "Změnit dopravce",
      "change": "Změnit",
      "passStatus": "Stav vůči dopravci",
      "passedFromStatus": "Stav vůči zákazníkovi",

      "customerPicUser": "Zodp. osoba zákazníka",
      "carrierPicUser": "Zodp. osoba dopravce",
      "spediterPicUser": "Zodp. osoba speditéra",

      "contactInfo": "Kontaktní údaje",
      "otherInfo": "Ostatní údaje",
      "subjectCode": "Kód subjektu",
      "vipCode": "Vlastní kód",

      "customValue": "Vlastní",
      "newCheckpoint": "Nová zastávka",
      "replaceCheckpoints": "Pro zvolenou kombinaci \"Import/Export\" + \"Typ přepravy\" je k dispozici automatické přednastavení zastávek. Přejete si provést jejich nahrazení za ty stávající?",
      "editOrigin": "Editace odeslání",
      "editDestination": "Editace určení",
      "editCheckpoint": "Editace zastávky",
      "removeCheckpoint": "Opravdu chcete odebrat tuto zastávku?",

      "returnPickupDepot": "Return/Pick-up depot",
      "dropDownSelectValue": "- Zvolte hodnotu -",

      "next": "Další",
      "prev": "Zpět",
      "finish": "Dokončit",

      "packingList": "Balící list",

      "otherSettings": "Další nastavení",
      "reportsSettings": "Nastavení reportů",
      "reportsHeader": "Hlavička reportů",
      "calculatorSettings": "Nastavení kalkulátoru",
      "calculatorFees": "Poplatky",

      "invoicingSettings": "Zasílání podkladů k fakturaci",
      "invoicingEmailRecipient": "Příjemci podkladů (může být více emailů oddělených čárkami):",
      "invoicingEmailSubject": "Předmět emailu:",
      "invoicingEmailBody": "Text emailu:",
      "invoicingInfo": "<p>V předmětu i textu emailu je možné použít zástupný řetězec <b>%transports%</b> pro vepsání čísel přeprav. Pro vepsání jména uživatele, telefonu a emailu je možné použít <b>%userName%</b>, <b>%userPhone%</b>, <b>%userEmail%</b>.</p>",

      "messageLog": "Log zpráv",
      "companyIc": "IČO společnosti",

      "spediter": "Speditér",
      "isRegistered": "Registrován",
      "approved": "Schválen",
      "usersCount": "Uživatelů",
      "tracedoCount": "Počet přeprav (jako speditér)",

      "place": "Místo",
      "estimatedTime": "Plánovaný čas",
      "actualTime": "Skutečný čas",
      "arrival": "Příjezd",
      "departure": "Odjezd",
      "person": "Osoba",
      "phone": "Telefon",
      "email": "Email",

      "selectUserType": "Vyberte pro každý soubor jeho typ",
      "reallyDeleteFile": "Chcete skutečně smazat tento soubor?",
      "selectType": "- Vyberte typ -",
      "userTypes": {
        "cd": "Customer document",
        "private": "Soukromý",
        "internal": "Interní",
        "business": "Obchodní",
        "public": "Veřejný"
      },
      "langs": {
        "en": "Anglicky",
        "cs": "Česky"
      },
      "ok": "OK",
      "settings": "Nastavení",
      "saved": "Data byla úspěšně uložena",
      "failed": "Operace selhala",

      "reference": "Reference",

      "additionalInformation": "Další informace",
      "customFields": "Vlastní pole",
      "multiline": "Víceřádkové pole",
      "decimals": "Desetinných míst",

      "caption": "Popisek",
      "chooseThis": "Vyberte...",
      "poHeader": "Hlavička",
      "contactPerson": "Kontaktní osoba",
      "contactPhone": "Kontaktní telefon",
      "contactEmail": "Kontaktní email",

      "planForDay": "Plan pro den:",
      "planStatus": "Fáze:",
      "planKindLoad": "Nakládání",
      "planKindUnLoad": "Vykládání",
      "planKindAll": "Vše",

      "showHasDocuments": "Zobrazit záznamy u kterých:",
      "hasDocuments": "existují podklady",
      "hasNoDocuments": "neexistují podklady",
      "withExcarrier": "Zobrazit i přepravy Externího dopravce",

      "todoList": "Úkoly",

      "invoicingStatus": "Stav fakturace",
      "pending": "Čeká na schválení",
      "approved": "Schváleno",
      "rejected": "Zamítnuto",

      "confirmNoFilter": "Nemáte nastavený žádný filtr, chcete opravdu pokračovat?",

      "driverNote": "Poznámka řidiče",

      "airTransport": "Letecká přeprava",
      "seaTransport": "Námořní přeprava",
      "roadTransport": "Pozemní přeprava",
      "railTransport": "Vlaková přeprava",

      "containers": "Položky",
      "trucks": "Položky",
      "orderItemVaz": "Vazba na položku",
      "tracedoVaz": "Vazba na Track&Trace",

      "roadType": "Typ",
      "mode": "Mód přepravy",
      "information": "Informace",
      "transportItem": "Transport - Item",
      "order": "Objednávka",
      "orderItems": "Položky",
      "customsInstruction": "Instrukce k proclení",
      "poItems": "Balící list",
      "invoices": "Faktury",
      "invoice": "Faktura",
      "documents": "Dokumenty",
      "chat": "Chat",
      "gps": "GPS log",
      "dates": "Datumy",
      "isOnNotifyList": "Příjmat notifikace o změnách",
      "isOnNotifyListTrc": "Chci dostávat notifikace",

      "partner": "Partner",

      "mySubject": "Můj subjekt",
      "myPicUser": "Moje zodp. osoba",

      "customerPartner": "Objednatel",
      "customerPartnerPicUser": "Zodp. osoba objednatele",
      "customerPartnerNumber": "Číslo objednávky",
      
      "supplierPartner": "Dopravce",
      "supplierPartnerPicUser": "Zodp. osoba dopravce",
      "supplierPartnerNumber": "Číslo dopravce",

      "invoicePrice": "Fakturace",
      "purchasePrice": "Cena nákup",
      "sellPrice": "Cena prodej",
      "company": "Společnost",
      "coordinator": "Koordinátor",
      "contractor": "Kontaktní osoba",
      "agent": "Agent",
      "season": "Sezóna",
      "carrierSeason": "Sezóna dopravce",

      "orderNumber": "Číslo přepravy",
      "number": "Číslo",
      "carrierNumber": "Číslo dopravce",
      "oldNumber": "Staré číslo",
      "status": "Stav",
      "trcStatus": "Stav přepravy",
      "kind": "Export/import",
      "type": "Typ",
      "route": "Trasa",
      "cee": "CEE",
      "vipFlag": "VIP",
      "letterOfCredit": "Akreditiv",
      "carrier": "Dopravce",
      "service": "Typ servisu",
      "dateOfOrder": "Datum objednávky",

      "etd": "ETD",
      "atd": "ATD",
      "atdReady": "ATD ready",
      "eta": "ETA",
      "ata": "ATA",
      "ataReady": "ATA ready",
      "myUzp": "Mé UZP",
      "supplierPartnerUzp": "UZP dopravce",
      "uzp": "UZP",
      "uzpFrom": "UZP od",
      "uzpTo": "UZP do",
      "ecr": "ECR",
      "ecrTo": "ECR to",
      "ecrFrom": "ECR from",
      "acr": "ACR",
      "rpu": "RPU",
      "epu": "EPU",
      "apu": "APU",
      "etdOrig": "EPU",
      "atdOrig": "APU",
      "rdl": "RDL",
      "rdlFrom": "RDL from",
      "rdlTo": "RDL to",
      "rtaDest": "RDL",
      "rtaDestTo": "RDL to",
      "edl": "EDL",
      "etaDest": "EDL",
      "ataDest": "ADL",
      "adl": "ADL",
      "distributionDate": "Deadline",
      "crd": "Procleno",
      "cutomReleaseDate": "Propuštěno dne:",
      "dutyPayment": "Celní výměr",
      "showIssued": "Zobrazit proclené",
      "showDuty": "Zobrazit s celním výměrem",
      "jsdDocs": "Celní prohlášení",
      "jsdDocsText": "Text k vloženým souborům:",
      "dateListed": "Datum vystavení",
      "dateDue": "Datum splatnosti",
      "to": "do",
      "from": "od",

      "clientRef": "Klient. ref.",
      "clientRef2": "Klient. ref. 2",
      "clientRef3": "Klient. ref. 3",
      "clientRef4": "Klient. ref. 4",
      "clientRef5": "Klient. ref. 5",
      "incoterms": "Incoterms",
      "shipper": "Shipper",
      "consignee": "Consignee",
      "externalCarrier": "Externí dopravce",
      "pickup": "Pickup",
      "pickupAddress": "Místo nakládky",
      "pickupContact": "Kontakt nakládky",
      "pickupContactPlaceholder": "Příjmení jméno",
      "delivery": "Delivery",
      "deliveryAddress": "Místo vykládky",
      "deliveryContact": "Kontakt vykládky",
      "deliveryContactPlaceholder": "Příjmení jméno",
      "insurance": "Pojištění",
      "adr": "DGR Cargo",
      "adrClass": "ADR třída",
      "dgrClass": "DGR třída",
      "remark": "Poznámka",
      "newMessage": "Nová zpráva",
      "newMessageRequested": "Je požadována zpráva",
      "address": "Adresa",

      "truckType": "Typ vozu",
      "orderedTruckType": "Objednaný Typ vozu",
      "truckNr": "Číslo vozu",
      "colli": "Colli",
      "cbm": "CBM",
      "kgs": "KGS",
      "chargeWeight": "Charge weight",

      "sumColli": "Colli (Balící list)",
      "sumCbm": "CBM (Balící list)",
      "sumKgs": "KGS (Balící list)",

      "width": "Šířka",
      "length": "Délka",
      "height": "Výška",

      "inland": "Inland",
      "inland2": "Inland 2",

      "goods": "Zboží",
      "customs": "Clení",
      "price": "Cena",
      "priceCarrier": "Cena pro dopravce",
      "originalPrice": "Původní cena",
      "commodity": "Komodita",
      "un": "UN code",
      "packingGroup": "Balící třída",
      "commodityValue": "Cena zboží",
      "hsCode": "HS kód",
      "nettoKgs": "Netto kgs",
      "pod": "POD",
      "jsd": "CP/VDD",
      "cargoValue": "Cena zboží",
      "transportPrice": "Cena přepravy",

      "documentName": "Dokument",
      "fileType": "Typ souboru",
      "fileName": "Název souboru",
      "fileSize": "Velikost souboru",
      "fileVersion": "Verze souboru",
      "zeroDuty": "Nulové clo",
      "documentExist": "Podklady",

      "createdBy": "Vytvořil(a)",
      "createdAt": "Vytvořeno",

      "vessel": "Loď",
      "rejdar": "Rejdař",
      "voyageNr": "Voyage Nr.",
      "portOfLoading": "Přístav nakládky",
      "gateOfLoading": "Brána nakládky",
      "portRailOrigin": "Terminál odeslání",
      "portOfDischarge": "Přístav vykládky",
      "gateOfDischarge": "Brána vykládky",
      "portRailDestination": "Terminál určení",
      "pickupDepot": "Return / Pickup depot",
      "mbl": "MBL",
      "portToPortTime": "Port to Port Time",
      "metPin": "Pin",
      "metAtb": "ATB",
      "hbl": "HBL",
      "bl": "B/L",
      "express": "Express",
      "transport": "Doprava",
      "transportInfo": "Přepravní kusy",
      "transportDescription": "Pokud znáte rozměry nebo podpoložky zadejte níže.",
      "bdpCntrType": "BDP Kontejner",
      "cntrType": "Typ kontejneru",
      "cntrNr": "Číslo kontejneru",
      "sealNr": "Číslo plomby",
      "custom": "Celní deklarace",
      "customOffice": "Celní úřad",
      "declarant": "Deklarant",
      "custom2": "Celní deklarace #2",
      "datePortPickup": "Port pickup date",
      "etaDepot": "ETA depot",
      "ataDepot": "ATA depot",
      "gjsd": "MRN",
      "mrn": "MRN",
      "spz": "SPZ",
      "metTranshipmentDepot": "Transhipment depot",
      "trainNr": "Train Nr.",
      "hblSend": "HBL send",
      "hblDelivered": "HBL delivered",
      "firstTransferToBdp": "First transfer to BDP",
      "lastTransferToBdp": "Last transfer to BDP",

      "flightNr": "Flight nr.",
      "airlinePrefix": "Airline prefix",
      "airportOrigin": "Airport origin",
      "airportDest": "Airport destination",
      "mawb": "MAWB",
      "hawb": "HAWB",
      "chbw": "CHBW",
      "bttoKgs": "BTTO KGS",
      "bttoLbs": "BTTO LBS",
      "totalPcs": "Total PCS",
      "readyForCarriage": "Ready for Carriage",
      "prealertToDest": "Pre Alert to Dest.",
      "clientNotified": "Client Notified",
      "docReceived": "Doc. received",
      "docToBroker": "Doc. to Broker",
      "docToAnBroker": "Doc. to an. Broker",
      "orderId": "ID",

      "milestonesExport": "Milestones - Export",
      "milestonesImport": "Milestones - Import",

      "basicInfo": "Základní informace",
      "document": "Dokument",
      "serie": "Serie dokumentu",
      "createdTimestamp": "Čas založení",
      "group": "Skupina",
      "contact": "Kontakt",
      "contact2": "Kontakt 2",
      "name": "Název",
      "description": "Popis",
      "notePractice": "Poznámka z praxe",
      "offerValidity": "Platnost nabídky",
      "offerNumber": "Číslo nabídky",
      "offer": "Nabídka",
      "offerPlaceholder": "Vyberte číslo nabídky",
      "demand": "Poptávka",
      "fromDate": "Datum od",
      "toDate": "Datum do",
      "publicFrom": "Zveřejněno od",
      "publicTo": "Zveřejněno do",
      "sentDate": "Odesláno dne",
      "presumedAmount": "Předpokládaný objem",
      "realAmount": "Skutečný objem",
      "accepted": "Přijato",
      "acceptedDate": "Přijato / odmítnuto dne",
      "lostReason": "Důvod odmítnutí",
      "declineReason": "Důvod zamítnutí",
      "author": "Autor",
      "result": "Výsledek",
      "customer": "Zákazník",
      "demandType": "Typ poptávky",
      "messageText": "Text zprávy",

      "yes": "Ano",
      "no": "Ne",

      "destination": "Určení",
      "dispatch": "Odeslání",

      "destinationCountry": "Země určení",
      "originCountry": "Země odeslání",

      "shipmentNotify": "Shipment notify",
      "cutOff": "Cut off",
      "m3": "Celkem(m<sup>3</sup>)",
      "m2": "Celkem(m<sup>2</sup>)",
      "chooseOriginAirport": "Vyber 'origin airport'...",
      "chooseDestAirport": "Vyber 'destination airport'...",
      "choosePortOfDischarge": "Vyber přístav vykládky",
      "chooseGateOfDischarge": "Vyber bránu vykládky (zkontroluj, že je kompletní)",
      "choosePortOfLoading": "Vyber přístav nakládky (zkontroluj, že je kompletní)",
      "chooseGateOfLoading": "Vyber bránu nakládky",
      "metOriginCountry": "Vyber zemi původu zboží",
      "chooseOriginGateway": "Vyber bránu nakládky (zkontroluj, že je kompletní)",
      "chooseDestinationGateway": "Vyber bránu vykládky (zkontroluj, že je kompletní)",
      "chooseRejdar": "Vyber rejdaře...",
      "chooseVessel": "Vyber vessel...",
      "chooseState": "Vyber stát...",
      "noDocuments": "Nebyly nalezeny žádné sobory",
      "basicOffer": "Základní poled",
      "action": "Akce",
      "changes": "Změny",
      "CZKCurrency": "Částka v CZK",
      "foreignCurrency": "Částka cizí měna",
      "notFollow": "Elektronická",
      "field": "Pole",
      "oldValue": "Původní hodnota",
      "newValue": "Nová hodnota",
      "addComment": "Prosím přidejte komentář",
      "changedBy": "Změnil",
      "changedAt": "Změněno",
      "vgm": "Ověřená hmotnost kontejneru (VGM)",
      "vgmPerson": "VGM zodpovědná osoba",
      "vgmMethod": "VGM metoda",
      "vgmDate": "VGM datum",
      "vgmCalculation": "Metoda 2 - Kalkulací",
      "vgmWeigh": "Metoda 1 - Zvážením kontejneru",

      "phaseName": "Fáze",
      "driver": "Řidič: ",
      "driverInfo": "Informace o řidiči",
      "authorization": "Oprávnění",
      "delay": "Zpoždění",
      "tookOverFromPerson": "Převzato od osoby",
      "handedOverToPerson": "Předáno osobě",
      "cargoDescription": "Popis zboží",
      "ldm": "LDM",
      "lm": "Ložné metry",
      "preliminaryDuty": "Předběžné clo",
      "preliminaryDeclaration": "Předběžné celní prohlášení",
      "track": "Sledovat",
      "origin": "Odeslání",
      "loadingReference": "Loading reference",
      "unloadingReference": "Unloading reference",
      "userToNotify": "Notifikovaní uživatelé",
      "companyFunction": "Pozice",

      "chargeableVolume": "Počitatelný objem",

      "extName": "Externí název",

      "checkpoints": "Zastávky",
      "create": "Vytvořit",
      "responsiblePerson": "Zodpovědná osoba",
      "ramp": "Rampa",
      "containerType": "Typ kontejneru",
    },
    "actions": {
      "lock": "Uzamknout",
      "unlock": "Odemknout",

      "generate": "Generovat",
      "generateCMR": "Generovat CMR",
      "refresh": "Obnovit",
      "ok": "OK",
      "saveAsPdf": "Uložit jako PDF",
      "generateTransportOrder": "Objednávka přepravy",
      
      "downloadPdf": "Stáhnout objednávku dopravci",
      "editAndDownloadPdf": "Upravit a stáhnout obj. dopravci",
      "downloadOrderReceivedPdf": "Stáhnout objednávku přijatou",
      "editAndDownloadOrderReceivedPdf": "Upravit a stáhnout obj. přijatou",

      "askForConnection": "Požádat společnost o potvrzení spojení",
      "askForRegistration": "Požádat společnost o registraci a spojení",
      
      "changeRevenueStatus": "Změnit stav fakturace",
      "changeCostStatus": "Změnit stav fakturace",
      "changeInvStatus": "Změnit stav fakturace",
      
      "create": "Vytvořit",
      "createOrder": "Vytvořit objednávku",
      "edit": "Upravit",
      "detail": "Detail",
      "duplicate": "Duplikovat",
      "duplicateTransport": "Kopírovat přepravu",
      "saveFile": "Uložit soubory",
      "save": "Uložit",
      "continue": "Pokračovat k dalšímu kroku",
      "continue1": "Pokračovat",
      "saveAndSend": "Uložit a odeslat poptávku",
      "saveAndOrder": "Uložit a objednat",
      "saveClose": "Uložit a zavřít",
      "rename": "Přejmenovat",
      "saveAs": "Uložit jako",
      "delete": "Smazat",
      "remove": "Odebrat",
      "search": "Hledat",
      "setAsDefault": "Nastavit jako výchozí",
      "cancel": "Zrušit",
      "exportExcel": "XLS export",
      "exportExcelHistory": "XLS export with order history",
      "findOnTracedo": "Hledat na přepravách",
      "linkExisting": "Připojit existující",
      "linkExistingTracedo": "Připojit z přepravy",
      "link": "Připojit",
      "unlink": "Odpojit z přepravy",
      "unlinkTracedo": "Odpojit z úseku",
      "approve": "Schválit",
      "decline": "Zamítnout",
      "accept": "Akceptovat",
      "reject": "Odmítnout",
      "rejectTransport": "Odmítnout přepravu",
      "rejectConfirmation": "Opravdu odmítnout tuto přepravu? Později už nebudete mít možnost ji akceptovat.",
      "rejectedByYou": "Tato přeprava byla vámi odmítnuta",
      "rejectedBySupplier": "Tato přeprava byla dopravcem odmítnuta",
      "accepted": "Přijatá",
      "rejected": "Odmítnutá",
      "new": "Nová",
      "download": "Stáhnout",
      "downloadAll": "Stáhnout zip se všemi soubory",
      "display": "Zobrazovat",
      "pin": "Připnout",
      "unpin": "Odepnout",
      "newItem": "Nová položka",
      "close": "Zavřít",
      "sendOrder": "Poslat objednávku",
      "offerAcceptCreate": "Přijmout nabídku a vytvořit objednávku",
      "setDeadline": "Nastavit deadline",
      "addDocs": "Přidat komerční dokument",
      "addCommercialOrigin": "Přidat komerční dokument - origin",
      "addCommercialDestination": "Přidat komerční dokument - destination",
      "addHbl": "Přidat House B/L, HAWB",
      "addMbl": "Přidat Master B/L, MAWB",
      "addAgentInvoice": "Přidat fakturu - agent",
      "setCargo": "Nastavit Cargo ready",
      "setADL": "Nastavit ADL",
      "setECR": "Nastavit ECR",
      "setAPU": "Nastavit APU",
      "changeRecord": "Zadat údaje",
      "confirmLoad": "Potvrdit nakládku",
      "confirmDischarge": "Potvrdit vykládku",
      "createNewOrder": "Objednání nové přepravy",
      "createOrderTitle": "Vytvořit objednávku z poptávky (bez nabídky)",
      "offer": "Nabídnout",
      "upload": "Nahrát",
      "insert": "Vložit zprávu.",
      "preview": "náhled",
      "setVgm": "Nastavit VGM",
      "setDriver": "Přiřadit řidiče",
      "demandSend": "Odeslání poptávky",
      "offerAttach": "Přiložení nabídky",
      "demandChanged": "Změna poptávky",
      "offerDeclined": "Zamítnutí nabídky",
      "offerApproved": "Schválení nabídky",
      "orderCreated": "Založení objednávky",
      "traceCreated": "Založení tracedo úseku",
      "traceRejected": "Odmítnutí přepravy",
      "traceAccepted": "Akceptování přepravy",
      "driverSet": "Přiřazení řidiče",
      "driverRemoved": "Odstranění řidiče",
      "loadingSet": "Zaznamenání nakládky",
      "loadingDelay": "Zpoždění nakládky",
      "loadingCnaceled": "Zrušení nakládky",
      "dischargeSet": "Zaznamenání vykládky",
      "dischargeDelay": "Zpoždění vykládky",
      "dischargeCanceled": "Zrušení vykládky",
      "interruption": "Přerušení",
      "loadingArrival": "Příjezd na místo nakládky",
      "dischargeArrival": "Příjezd na místo vykládky",
      "issue": "Nahlášen problém",
      "update": "Změna informací",
      "showOnMap": "Zobrazit trasu na mapě",
      "suspend": "Přerušit",
      "connectToNewCarrier": "Spojit s novým dopravcem",
      "createNewCarrier": "Vytvořit nového dopravce",
      "connectToNewSpediter": "Spojit s novým speditérem",
      "connectToNewCustomer": "Spojit s novým zákazníkem",
      "connectToSubject": "Spojit se subjektem",
      "send": "Odeslat požadavek na spojení"
    }
  },
  "kendo": {
    "columnMenu": {
      "columns": "Sloupce",
      "done": "Dokončeno",
      "filter": "Filtr",
      "lock": "Zamknout",
      "settings": "Nastavení sloupce",
      "sortAscending": "Řadit vzestupně",
      "sortDescending": "Řadit sestupně",
      "unlock": "Odemknout"
    },
    "groupable": {
      "empty": "Přetáhněte sem záhlaví sloupce, pro seskupení podle tohoto sloupce"
    },
    "filterMenu": {
      "info": "Zobrazit položky s hodnotami:",
      "filter": "Filtr",
      "clear": "Vyčistit filtry",
      "isTrue": "je pravda",
      "isFalse": "není pravda",
      "and": "A",
      "or": "Nebo",
      "selectValue": "-Vyberte hodnotu-",
      "operator": "Operátor",
      "value": "Hodnota",
      "cancel": "Zrušit"
    },
    "filterMenuOperators": {
      "string": {
        "eq": "Je rovno",
        "neq": "Není rovno",
        "startswith": "Začíná na",
        "contains": "Obsahuje",
        "doesnotcontain": "Neobsahuje",
        "endswith": "Končí na",
        "isEmpty": "Je prázdný",
        "isNotEmpty": "Není prázdný"
      },
      "number": {
        "eq": "Je rovno",
        "neq": "Není rovno",
        "gte": "Je větší nebo rovno",
        "gt": "Je větší než",
        "lte": "Je menší nebo rovno",
        "lt": "Je menší než"
      },
      "date": {
        "eq": "Je stejné jako",
        "neq": "Není stejné jako",
        "gte": "Je stejné nebo později než",
        "gt": "Je později",
        "lte": "Je stejné nebo dříve než",
        "lt": "Je dříve než",
        "isweek": "Č. týdne je rovno",
        "thisweek": "Je v tomto týdnu",
        "today": "Je dneska",
        "isnull": "Je prázdné",
        "isnotnull": "Není prázdné"
      },
      "enums": {
        "eq": "je rovno",
        "neq": "není rovno"
      }
    },
    "pager": {
      "display": "Zobrazuji {0} - {1} z {2} záznamů",
      "empty": "Žádné záznamy k zobrazení",
      "first": "Přejít na první stránku",
      "itemsPerPage": "Záznamů na stránku",
      "last": "Přejít na poslední stránku",
      "morePages": "Další stránky",
      "next": "Přejít na další stránku",
      "of": "z {0}",
      "page": "Stránka",
      "previous": "Přejít na předchozí stránku",
      "refresh": "Obnovit"
    },
    "validator": {
      "required": "{0} je povinné",
      "pattern": "{0} není správně vyplněno",
      "min": "{0} by mělo být větší nebo stejné než {1}",
      "max": "{0} by mělo být měnší nebo stejné než {1}",
      "step": "{0} není správně",
      "email": "{0} není správná emailová adresa",
      "url": "{0} není platná adresa URL",
      "date": "{0} není platné datum"
    },
    "imageBrowser": {
      "uploadFile": "Nahrát",
      "orderBy": "Uspořádat podle",
      "orderByName": "Jméno",
      "orderBySize": "Velikost",
      "directoryNotFound": "Složka s tímto názvem nebyla nalezena.",
      "emptyFolder": "Prázdná složka",
      "deleteFile": "Opravdu chcete smazat soubor \"{0}\"?",
      "invalidFileType": "Vybraný soubor \"{0}\" není platný. Podporované typy jsou {1}.",
      "overwriteFile": "Soubor s názvem \"{0}\" v této složce již existuje. Přejete si jej nahradit ?",
      "dropFilesHere": "Soubory pro nahrání přetáhněte sem.",
      "search": "Hledat"
    },
    "editor": {
      "addColumnLeft": "Přidat sloupec nalevo",
      "addColumnRight": "Přidat sloupec napravo",
      "addRowAbove": "Přidat řádek nad",
      "addRowBelow": "Přidat řádak pod",
      "backColor": "Barva pozadí",
      "bold": "Tučný",
      "createLink": "Vytvořit odkaz",
      "createTable": "Vytvořit tabulku",
      "deleteColumn": "Odstranit sloupec",
      "deleteFile": "Opravdu chcete smazat soubor \"{0}\"?",
      "deleteRow": "Odstranit sloupec",
      "dialogButtonSeparator": "nebo",
      "dialogCancel": "Zrušit",
      "dialogInsert": "Vložit",
      "dialogUpdate": "Aktualizovat",
      "directoryNotFound": "Složka s tímto názvem nebyla nalezena",
      "emptyFolder": "Prázdná složka",
      "fontName": "Vybrat písmo",
      "fontNameInherit": "(zděděné písmo)",
      "fontSize": "Vybrat velikost písma",
      "fontSizeInherit": "(zděděná velikost)",
      "foreColor": "Barva popředí",
      "formatBlock": "Formát",
      "formatting": "Formát",
      "imageAltText": "Alternativní text",
      "imageHeight": "Výška (px)",
      "imageWebAddress": "Webová adresa",
      "imageWidth": "Šířka (px)",
      "indent": "Zvětšit odsazení",
      "insertHtml": "Vložit HTML",
      "insertImage": "Vložit image",
      "insertOrderedList": "Vložit řazený seznam",
      "insertUnorderedList": "Vložit neřazený seznam",
      "invalidFileType": "Vybraný soubor \"{0}\" není platný. Podporované typy jsou {1}.",
      "italic": "Kurzíva",
      "justifyCenter": "Zarovnat na střed",
      "justifyFull": "Zarovnat do bloku",
      "justifyLeft": "Zarovnat vlevo",
      "justifyRight": "Zarovnat vpravo",
      "linkOpenInNewWindow": "Otevřít odkaz v novém okně",
      "linkText": "Text",
      "linkToolTip": "ToolTip",
      "linkWebAddress": "Webová adresa",
      "orderBy": "Řadit podle:",
      "orderByName": "Název",
      "orderBySize": "Velikost",
      "outdent": "Zmenšit odsazení",
      "overwriteFile": "Soubor s názvem \"{0}\" v této složce již existuje. Přejete si jej nahradit?",
      "strikethrough": "Přeškrtnutý",
      "style": "Styly",
      "subscript": "Dolní index",
      "superscript": "Horní index",
      "underline": "Podtržený",
      "unlink": "Odstranit odkaz",
      "uploadFile": "Nahrát",
      "viewHtml": "Zobrazit HTML"
    },
    "numericTextBox": {
      "upArrowText": "Zvýšit hodnotu",
      "downArrowText": "Snížit hodnotu"
    },
    "upload": {
      "select": "Vybrat soubory...",
      "cancel": "Zrušit",
      "retry": "Opakovat",
      "remove": "Odstranit",
      "uploadSelectedFiles": "nahrát soubory",
      "dropFilesHere": "Soubory pro nahrání přetáhněte sem.",
      "statusUploading": "Nahrávání",
      "statusUploaded": "Nahráno",
      "statusWarning": "Varování",
      "statusFailed": "Chyba",
      "headerStatusUploading": "Nahrávání...",
      "headerStatusUploaded": "Dokončeno"
    }
  },
  "dashboard": {
    "chooseWidget": "Vyberte widget",
    "placeNewWidget": "Umístit nový widget na dashboard",
    "widgetPlaced": "Widget byl úspěšně umístěn",
    "widgetRename": "Přejmenovat",
    "widgetSettings": "Nastavení",
    "widgetRemove": "Odebrat",
    "removeWidgetQuestion": "Chcete skutečně odstranit tento widget?",
    "addNewTooltip": "Přidat nový widget"
  },
  "login": {
    "userLogin": "Přihlášení uživatele",
    "user": "Uživatel:",
    "password": "Heslo:",
    "permanentLogin": "Trvalé přihlášení",
    "login": "Přihlásit se",
    "forgottenPassword": "Zapomenuté heslo",
    "emailSend": "Email byl úspěšně odeslán na vaši adresu.",
    "userNotExist": "Uživatel s tímto přihlašovacím jménem nebo emailem nebyl nelezen.",
    "fillLoginName": "Prosím vyplňte pole 'Uživatel' přihlašovacím jménem nebo váším emailem.",
    "errorCode1": "Chybné přihlašovací údaje",
    "errorCode2": "Chybné přihlašovací údaje",
    "errorCode3": "Chyba při přihlášení. Zkuste to později",
    "errorCode4": "Váš účet ještě není schválen. Zkuste to později",
    "errorCode5": "Váš subjekt není registrován",
    "errorCode6": "Pro tento účet není povoleno přihlášení do webové aplikace",
    "errorCode7": "Váš účet není přiřazen k žádnému subjektu"    
  },
  "forgot": {
    "heading": "Zapomenuté heslo",
    "backToLogin": "Zpět na přihlášení",
    "email": "Email",
    "send": "Odeslat",
    "fillEmail": "Zadejte svůj email",
    "resultMessage": "Pokud byl zadaný e-mail v naší databázi nalezen, byly vám na něj zaslány instrukce pro nastavení nového hesla."
  },
  "setPassword": {
    "heading": "Nastavení hesla",
    "email": "Email",
    "realname": "Celé jméno",
    "password": "Heslo",
    "password2": "Kontrola hesla",
    "btnSetPassword": "Nastavit heslo",
    "fillPassword": "Zadejte své nové heslo",
    "noMatch": "Zadaná hesla se neshodují. Zkuste je napsat znovu.",
    "invalidRequest": "Chybný požadavek. Token již pravděpodobně vypršel. Zkuste proces obnovy hesla zopakovat.",
    "success": "Heslo bylo úspěšně nastaveno. Nyní se můžeze přihlásit.",
    "failure": "Heslo nebylo nastaveno. Token pravděpodobně vypršel. Zkuste proces obnovy hesla zopakovat."
  },
  "registration": {
    "numberCode": "Kód pro číslování přeprav",
    "backToLogin": "Zpět na přihlášení",
    "registration": "Registrace",
    "register": "Registrovat",
    "firstName": "Jméno",
    "lastName": "Příjmení",
    "firmName": "Název firmy",
    "email": "Email",
    "password": "Heslo",
    "passwordCheck": "Kontrola hesla",
    "phone": "Telefon",
    "language": "Jazyk",
    "firmIco": "IČO",
    "firmIcoInfo": "Identifikační číslo společnosti",
    "firmDic": "DIČ",
    "firmStreet": "Ulice",
    "firmZipCode": "PSČ",
    "firmCity": "Město",
    "firmCountry": "Stát",
    "firmTradeCurrency": "Obchodní kurz",
    "firmTradeCurrencyTipText": "Příplatek k kurzu ČNB používaný pro náklady a výnosy. V procentech.",
    "firm": "Firma",
    "user": "Uživatel",
    "connectProcesInfo": "Pro spojení s dopravcem je nutné mu poslat pozvánku a požádat o spojení. Kliknutím na tlačítko 'Odeslat' se dopravci odešlou všechny potřebné informace (váš email, Název firmy a ). V případě, že firma už je v systému registrovaná, odešle se potvrzení spojení na jiný email!",
    "connectProcesInfo2": "Pro spojení se speditérem je nutné mu poslat pozvánku a požádat o spojení. Kliknutím na tlačítko 'Odeslat' se speditérovi odešlou všechny potřebné informace (váš email, Název firmy a IČO). V případě, že firma už je v systému registrovaná, odešle se potvrzení spojení na jiný email!"
  },
  "activate": {
    "activating": "Probíhá aktivace vašeho účtu...",
    "success": "Váš účet byl úspěšně aktivován. Nyní se můžete přihlásit.",
    "fail": "Aktivační token již není platný.",
    "goToLogin": "Přejít na přihlášení"
  },
  "trackAndTrace": {
    "reallySuspend": "Opravdu chcete přerušit tuto přepravu?",
    "reallyCancel": "Opravdu chcete zrušit tuto přepravu?",
    "edit": {
      "selectAddress": "- zvolte adresu -",
      "selectContactPerson": "- zvolte kontaktní osobu -"
    }
  },
  "nav": {
    "transportResults": "Výsledky",
    "logout": "Odhlásit se",
    "driverPlan": "Plán řidiče",
    "editProfile": "Upravit profil",
    "editCompany": "Upravit společnost",
    "airfreight": "Letecká",
    "oceanfreight": "Námořní",
    "roadfreight": "Pozemní",
    "railfreight": "Železniční",
    "otherfreight": "Ostatní služby",
    "dashboard": "Dashboard",
    "poManagement": "Balící list",
    "demands": "Poptávky",
    "reports": "Reporty",
    "newDemand": "Nová poptávka",
    "editDemand": "Editace poptávky",
    "orders": "Objednávky",
    "offers": "Nabídky",
    "offerDetail": "Detail nabídky",
    "demandDetail": "Detail poptávky",
    "offerAccept": "Přijmout nabídku",
    "offerDecline": "Odmítnout nabídku",
    "createOrder": "Uložit a potvrdit objednávku",
    "poItemCreate": "Vytvořit balící položku",
    "poItemEdit": "Upravit balící položku",
    "duplicateDemand": "Duplikace poptávky",
    "setRDL": "Nastavit RDL",
    "setECR": "Nastavit ECR",
    "setADL": "Nastavit ADL",
    "setAPU": "Nastavit APU",
    "addCommercial": "Přidat komerční dokument",
    "addCommercialOrigin": "Přidat komerční dokument - origin",
    "addCommercialDestination": "Přidat komerční dokument - destination",
    "addHbl": "Přidat House B/L, HAWB",
    "addMbl": "Přidat Master B/L, MAWB",
    "addAgentInvoice": "Přidat fakturu - agent",
    "setDuty": "CP + Celní výměr",
    "cleared": "Proclít",
    "setDeclarant": "Nastavit declaranta",
    "invoices": "Faktury",
    "files": "Soubory",
    "changeRecord": "Zadat informace",
    "help": "Nápověda",
    "setVgm": "Nastavit VGM",
    "userManagement": "Správa uživatelů",
    "driverManagement": "Správa řidičů",
    "subjectVazManagement": "Správa vazby subjektů",
    "tracedo": "Track&Trace",
    "preliminaryDuty": "Předběžné clo",
    "downloadCustoms": "Vygenerovat custom clearance",
    "createTracedo": "Vytvořit přepravu Track&Trace",
    "editTracedo": "Upravit přepravu Track&Trace",
    "settings": "Nastavení",
    "carriers": "Dopravci",
    "customers": "Zákazníci",
    "addresses": "Adresy",
    "spediters": "Speditéři",
    "carrier": "Dopravce",
    "customer": "Zákazník",
    "spediter": "Speditér",
    "users": "Uživatelé",
    "fields": "Uživatelská pole",
    "subjects": "Subjekty",
    "rolePermissions": "Oprávnění rolí",
    "finances": "Finance",
    "costs": "Náklady",
    "revenues": "Výnosy"
  },
  "calculatorStatuses": {
    "planned": "Plán",
    "received": "Faktura přijata",
    "prepared": "Připraveno k fakturaci",
    "tobeinvoiced": "Fakturovat",
    "invoiced": "Fakturováno",
    "paid": "Zaplaceno",
    "expected": "Očekávané",
    "real": "Skutečné"
  },
  "tracedo": {
    "carrierPrice": {
      "confirmMessage": "Přejete si schválit přepravu s touto cenou pro dopravce?"
    }
  },
  "system": {
    "subjects": {
      "linkExpired": "Odkaz již vypršel.",
      "connectionRequestRejected": "Žádost o spojení byla zamítnuta",
      "connectionRequestApproved": "Žádost o spojení byla schválena",
      "registrationInvitationSent": "Pozvánka k registraci byla úspěšně odeslána",
      "connectionInvitationSent": "Žádost o spojení byla úspěšně odeslána",
      "carrierHasBeenAdded": "Dopravce byl úspěšně přidán",
      "customerHasBeenAdded": "Zákazník byl úspěšně přidán"
    },
    "tools": {
      "gridConfigurator": {
        "presets": "Předvolby",
        "selectPreset": "Zvolte předvolbu...",
        "enterPresetName": "Zadejte název předvolby:",
        "overwrite": "Chcete přepsat předvolbu s názvem \"__name__\" aktuálními hodnotami?",
        "deletePresetQuestion": "Chcete smazat aktuální předvolbu?",
        "graphs": "Grafy",
        "selectGraph": "Zvolte graf...",
        "showGraph": "Zobrazit",
        "excarrierKgs": "Graf váhy přepraveného zboží dle externích dopravců",
        "countryKgs": "Graf váhy přepraveného zboží dle země",
        "amountKgs": "Graf poměru počtu zásilek k váhovým katergoriím"
      },
      "uploader": {
        "noFilesSelected": "Žádné soubory k nahrání..."
      },
      "specificFiltration": {
        "label": "Specifická filtrace",
        "hideOld": "Nezobrazovat odeslané",
        "hideOldTitle": "Nezobrazí položky s poslední vazbou na Importní přepravu a s datumem: ADL = teď-150 dní"
      },
      "subjectWizard": {
        "company": "Společnost",
        "contact": "Kontakt",
        "enterCrnText": "Zadejte IČO společnosti, kterou se chystáte přidat, a pokračujte tlačítkem \"Další\".",
        "fillInfoText": "Vyplňte údaje o společnosti a kontaktní údaje a pokračujte tlačítkem \"Další\".",
        "willContactRegistrationText": "Společnost bude kontaktována na vámi zadaný email (viz pole výše) s pozvánkou k registraci společnosti a spojení s vaší společností.",
        "willContactConnectionText": "Společnost bude kontaktována s požadavkem na spojení s vaší společností."
      }
    },
    "user": {
      "profile": {
        "editProfile": "Upravit profil",
        "personalInfo": "Osobní údaje",
        "firstName": "Jméno:",
        "lastName": "Příjmení:",
        "email": "E-mail:",
        "phone": "Telefon:",
        "mobile": "Mobil:",
        "intraline": "Interní linka:",
        "sms": "SMS:",
        "companyFunction": "Pracovní pozice:",
        "notes": "Poznámka:",
        "loginInfo": "Přihlašovací údaje",
        "username": "Uživatelské jméno:",
        "oldPassword": "Současné heslo:",
        "newPassword": "Nové heslo:",
        "confirmPassword": "Potvrzení hesla:",
        "noModifyPasswordNotification": "Nechcete-li měnit heslo, ponechte všechna tři pole prázdná.",
        "notification": "Notifikace",
        "active": "Aktivní",
        "watch": "Sledovat pole",
        "sendToAllOrders": "Posílat notifikace o změnách na všechny přepravy",
        "sendToAllOrdersByContact": "Posílat notifikace o změnách u všech přeprav, kde jsem nastaven jako kontaktní osoba.",
        "sendToAllTrcOrders": "Posílat notifikace pro všechny přepravy Track&Trace",
        "sendToContactTrcOrders": "Posílat notifikace pro všechny přepravy Track&Trace, kde jsem zodpovědnou osobou",
        "watchedFields": "Sledovaná pole",
        "notificationLanguange": "Jazyk notifikací:",
        "spz": "SPZ:",
        "signature": "Podpis",
        "logo": "Logo firmy pro emailové notifikace",
        "logoSize": "Maximální povolené rozměry: 400x150px.",
        "downloadSignature": "Stáhnout podpis",
        "downloadLogo": "Stáhnout logo"
      },
      "edit": {
        "editUser": "Upravit uživatele",
        "addUser": "Založení uživatele",
        "userInfo": "Informace o uživateli",
        "userLogInInfo": "Přihlašovací údaje",
        "ident": "Ident:",
        "realname": "Celé jméno:",
        "firstName": "Jméno:",
        "lastName": "Příjmení:",
        "loginname": "Přihlašovací jméno:",
        "password": "Heslo:",
        "passwordAgain": "Heslo pro kontrolu:",
        "disabled": "Smazaný",
        "valid": "Povolit přihlášení",
        "portal": "Název portálu",
        "set": "Přiřazeno",
        "action": "Akce",
        "setRoles": "Nastavit role",
        "addSubject": "Přidat společnost",
        "unlinkSubject": "Odpojit",
        "roleName": "Název role",
        "duplicateFromUser": "Zkopírovat nastavení z uživatele (role,widgety,předvolby)"
      }
    },
    "partner": {
      "partner": "Kontakt partnera",
      "bdp": "BDP - Wakestone",
      "attributes": "Atributy",
      "news": "Novinky",
      "position": "Pozice",
      "fullName": "Celé jméno",
      "mobil": "Mobil",
      "email": "Email",
      "dealer": "Obchodník",
      "town": "Město",
      "primContact": "Primární kontakt",
      "Contacts": "Kontaktní osoby"
    },
    "question": {
      "confirmSaveChanges": "Chcete uložit změny? Pokud neuložíte, změny budou ztraceny.",
      "confirmSaveChanges2": "Pro ukončení je potřeba zadat důvod, jinak nedojde k uložení.",
      "deleteItem": "Nemůžete smazat poslední položku na objednávce.",
      "confirmDeleteItem": "Opravdu chcete smazat tuto položku objednávky?",
      "declaratnSetSuccess": "Deklarant byl úspěšně nastaven.",
      "preliminaryDutySucces": "Předběžné clo bylo úspěšně nastaveno.",
      "setReleaseDateSuccess": "Datum customs release byl úspěšně nastaven ",
      "setRDLSuccess": "RDL datum byl úspěšně nastaven.",
      "docsUploaded": "Dokumenty byly přidány.",
      "setECRSuccess": "ECR datum byl úspěšně nastaven.",
      "setADLSuccess": "ADL datum byl úspěšně nastaven.",
      "setAPUSuccess": "APU datum byl úspěšně nastaven.",
      "vgmAgree": "Odesílatel (odpovědná osoba) prohlašuje, že poskytl firmě BDP-Wakestone, správný údaj VGM v souladu s požadavky SOLAS, Kapitola VI.  Dále se zavazuje, že zasilateli BDP-Wakestone nahradí veškerou škodu, která by mu mohla vzniknout v případě, že by uvedl nesprávnou nebo chybnou informaci VGM, a že mu poskytne veškerou potřebnou součinnost, aby následky takového jednání byly pro zasilatele co nejmenší.",
      "agree": "Souhlasím",
      "setDutySuccess": "Celní výměr přepravy byl úspěšně nastaven.",
      "clearedSuccess": "Úspěšně procleno.",
      "cancelDemand": "Opravdu chcete zrušit vybranou poptávku?",
      "deletePo": "Opravdu chcete smazat vybranou položku?",
      "cancelOrder": "Opravdu chcete zrušit přepravu?",
      "deletePoVaz": "Opravdu chcete odpojit vybranou položku od přepravy?",
      "acceptInvoice": "Opravdu chcete schváli tento dokument?",
      "declineInvoice": "Opravdu chcete zamítnout tento dokument?",
      "createOrderWithoutOffer": "Opravdu chcete vytvořit objednávku z této poptávky, která nemá přiloženou nabídku?",
      "dataSaved": "Data byla uložena.",
      "offerSaved": "Nabídka byla uložena.",
      "messageInserted": "Zpráva byla vložena.",
      "setVGMSuccess": "VGM informace byly uloženy.",
      "approveTracedo": "Opravdu chcete akceptovat vybraný úsek?",
      "tracedoApproved": "Úsek byl schválen.",
      "deleteUser": "Opravdu chcete smazat vybraného uživatele?",
      "deleteSubjectConnection": "Opravdu chcete smazat vazbu s vybraným subjektem?",
      "deleteAddress": "Opravdu chcete smazat vybranout adresu z vašeho seznamu?",
      "activationEmailSent": "Na vámi uvedený email byl odeslán aktivační email pro potvrzení registrace.",
      "registeredSuccessfully": "Registrace dokončena úspěšně.",
      "registrationConfirmationPending": "Vaší registraci nyní musí schválit zodpovědná osoba. O vyřízení budete informováni e-mailem.",
      "deleteCustomer": "Opravdu chcete smazat vybraného Zákazníka?"
    },
    "alerts": {
      "error": "Akce neproběhla v pořádku.",
      "emptyEmail": "Email musí být zadán.",
      "emptyETD": "ETD musí být zadán.",
      "emptyETA": "ETA musí být zadán.",
      "emptyATD": "ATD musí být zadán.",
      "emptyAPU": "APU musí být zadán.",
      "emptyATA": "ATA musí být zadán.",
      "emptySpediter": "Speditér musí být vybrán.",
      "emptyCustomer": "Zákazník musí být zadán.",
      "emptyCarrier": "Dopravce musí být zadán.",
      "emptyPicUser": "Zodpovědná osoba musí být zadána.",
      "emptyCustomerPicUser": "Zodpovědná osoba zákazníka musí být vybrána.",
      "emptyMyPicUser": "Zodpovědná osoba musí být vybrána.",
      "notValidEmail": "Špatný formát emailu.",
      "passMismatched": "Nová hesla se neshodují.",
      "fillPassword": "Zadejte heslo.",
      "fillCarrier": "Zadejte dopravce",
      "fillRequiredFields": "Vyplňte povinná pole.",
      "fillPrice": "Vyplňte cenu",
      "fillTruckType": "Vyplňte typ vozu",
      "vgmAgree": "Musíte souhlasit s podmínkami.",
      "updateDisabled": "Přeprava v tomto stavu nelze měnit.",
      "emptyMessageText": "Pokud chcete vložit zprávu, vyplňte nejprve text zprávy.",
      "selectItem": "Prosím vyberte položku 'Order item'",
      "selectTracedo": "Prosím vyberte Track&Trace přepravu. Definujte úsek, ktery Po položku poveze.",
      "oldClientUrl": "Staré klientské rozhraní je nyní dostupné na url adrese: ",
      "selectShipper": "Před uložením prosím vyberte hodnotu pro pole shipper.",
      "invalidAddress": "U nové adresy není vše vyplněno.",
      "invalidContact": "U nového kontaktu není vše vyplněno.",
      "alertUrlChanged": "25.5. 20:00 - 23:00 bude systém z důvodu plánované údržby nedostupný.",
      "fillTransportPrice": "Cena přepravy musí být vylněná.",
      "fillDeliveryInformation": "Jedna z vykládek nemá vyplnění datum.",
      "fillDeliveryAddress": "U vykládky je potřeba vyplnit adresu.",
      "fillPickupAddress": "U nakládky je potřeba vyplnit adresu.",
      "invalidCharacter": "Uložení selhalo. Není možné uložit vložený znak (nečeský znak)",
      "dataInUse": "Uložení selhalo. Záznam je právě používán jiným uživatelem nebo aplikací, prosím zkuste uložit později.",
      "invalidUsername": "Uživatelské jméno neexistuje.",
      "wrongPassword": "Špatné heslo.",
      "emailDuplicity": "Uživatel s tímto emailem už existuje!",
      "imageMustBeJpg": "Podpis musí být typu jpg",
      "wrongSignatureSize": "Podpis musí mít rozměry přibližně 300x250",
      "wrongLogoSize": "Logo musí mít rozměry maximálně 400x150",
      "driverCantLogIn": "Řidič se nemůže přihlásit do webové aplikace",
      "lastName": "Vyplňte Příjmení",
      "firstName": "Vyplňte Jméno",
      "firmName": "Vyplňte Název firmy",
      "firmIco": "Vyplňte IČO",
      "firmDic": "Vyplňte DIČ",
      "firmStreet": "Vyplňte Ulici",
      "firmZipCode": "Vyplňte PSČ",
      "firmCity": "Vyplňte Město",
      "firmCountryId": "Vyplňte Stát",
      "firmTradeCurrency": "Vyplňte Obchodní kurz",
      "requestSend": "byl úspěšně kontaktován.",
      "insufficientRight": "Nemáte dostatečná práva na tuto operaci",
      "invalidCheckpointAddress": "U nové adresy zastávky není vše vyplněno",
      "invalidCheckpointContact": "U nového kontaktu zastávky není vše vyplněno",
      "invalidPoItems": "Název balící položky musí být vyplněn",
      "invalidPickupContact": "U kontaktu odeslání není vše vyplněno",
      "invalidDestinationContact": "U kontaktu určení není vše vyplněno"
    }
  },
  "widget": {
    "no-settings": "Widget nemá žádné nastavení.",
    "settingsSaved": "Nastavení widgetu bylo úspěšně uloženo",
    "customsDeclarationExport": {
      "descr": "Zobrazí (export) položky přepravy dle datumu EPU (EPU je mezi datumy 'dnešní datum - EPUminus z nastavení' a 'dnešní datum + EPUplus z nastavení') a s prázdným datumem Customs release. Pokud EPU není vyplněné použije se datum EDL. Přepravy s prázdným EPU i EDL se nezobrazí."
    },
    "customsDeclarationImport": {
      "descr": "Zobrazí (import) položky letecké a námořní přepravy dle datumu ETA (ETA je mezi datumy 'dnešní datum - ETAminus z nastavení' a 'dnešní datum + ETAplus z nastavení') a pozemní přepravy dle datumu EDL (EDL je mezi datumy 'dnešní datum - ETAminus z nastavení' a 'dnešní datum + ETAplus z nastavení'). Výpis položek končí vyplněním datumu 'Celní výměr'"
    },
    "dispatchWarehouse": {
      "showSetEcr": "Zobrazit položky přepravy s nastaveným datumem ECR",
      "showSetApu": "Zobrazit položky přepravy s nastaveným datumem APU",
      "descr": "Zobrazí (export) položky přepravy  dle datumu ECR (ECR je mezi datumy 'dnešní datum - ECRminus z nastavení' a 'dnešní datum + ECRplus z nastavení') a s prázdným datumem APU a ADL"
    },
    "importWarehouse": {
      "descr": "Zobrazí (import) položky přepravy dle datumu EDL (EDL je mezi datumy 'dnešní datum - EDLminus z nastavení' a 'dnešní datum + EDLplus z nastavení') a s prázdným datumem ADL'"
    },
    "logisticExportDestination": {
      "descr": "Zobrazí (export) položky přepravy dle datumu EDL (EDL je mezi datumy 'dnešní datum - EDLminus z nastavení' a 'dnešní datum + EDLplus z nastavení') a s prázdným nebo větším datumem ADL než 'dnešní datum - ADLminus z nastavení'",
      "descr2": " U CFR, CIF přeprav není EDL, to jsou přepravy letecké nebo námořní - u těchto přeprav se filtruje dle datumu ETA a přepravy zmizí po vyplnění ATA."
    },
    "logisticExportOrigin": {
      "descr": "Zobrazí (export) položky přepravy, kde datum ECR, ACR nebo EPU je v rozmezí ('dnešní datum - ECRminus z nastavení' a 'dnešní datum + ECRplus z nastavení') a s prázdným nebo větším datumem ADL než 'dnešní datum - ATDminus z nastavení'",
      "descr2": "u CFR, CIF přeprav není ADL, to jsou přepravy letecké nebo námořní - tyto přepravy zmizí po vyplnění ATD (datum odplutí/odletu)."
    },
    "logisticImportDestination": {
      "descr": "Zobrazí (import) položky přepravy dle datumu ETA (ETA je mezi datumy 'dnešní datum - ETAminus z nastavení' a 'dnešní datum + ETAplus z nastavení') a s prázdným nebo větším datumem ADL než 'dnešní datum - ADLminus z nastavení'",
      "descr2": " U CFR, CIF přeprav není EDL, to jsou přepravy letecké nebo námořní - u těchto přeprav se filtruje dle datumu ETA a přepravy zmizí po vyplnění ATA."
    },
    "logisticImportOrigin": {
      "showAddCargoReady": "Zobrazit 'Add Cargo Ready' položky",
      "showAddDeadline": "Zobrazit 'Add Deadline' položky",
      "showAddCommercialDocuments": "Zobrazit položky bez komerčních dokumentů",
      "descr": "Zobrazí (import) položky přepravy, kde datum ECR, ACR nebo EPU je v rozmezí ( 'dnešní datum - ECRminus z nastavení' a 'dnešní datum + ECRplus z nastavení') a s prázdným nebo větším datumem ADL než 'dnešní datum - ATDminus z nastavení'",
      "descr2": "u CFR, CIF přeprav není ADL, to jsou přepravy letecké nebo námořní - tyto přepravy zmizí po vyplnění ATD (datum odplutí/odletu)."
    },
    "purchasingDepartment": {
      "descr": "Zobrazí (import) položky přepravy dle datumu ECR (ECR je mezi datumy 'dnešní datum - ECRminus z nastavení' a 'dnešní datum + ECRplus z nastavení' )"
    },
    "salesDepartment": {
      "descr": "Zobrazí (export) položky přepravy dle datumu ECR (ECR je mezi datumy 'dnešní datum - ECRminus z nastavení' a 'dnešní datum + ECRplus z nastavení' )"
    },
    "invoicesToApprove": {
      "descr": "Zobrazí pouze elektronické, neschválené faktury dle datumu DUE (DUE je mezi datumy 'dnešní datum - DUEminus z nastavení' a 'dnešní datum + DUEplus z nastavení' )."
    },
    "externalCarrierOrder": {
      "descr": "Zobrazí položky přepravy, které mají vyplněného externího dopravce. Záznam zmizí vyplněním datumu ADL' )"
    },
    "externalCarrierDemand": {
      "descr": "Zobrazí poptávky, které mají vyplněného externího dopravce a nemají stav 'cancel' či 'not send'. Záznam zmizí při vytvoření přepravy z poptávky. "
    },
    "consignee": {
      "descr": "Zobrazí (export) položky přepravy dle datumu ECR nebo EPU, ale jen pokud je ECR nevyplněné, (ECR or EPU je mezi datumy 'dnešní datum - ECRminus z nastavení' a 'dnešní datum + ECRplus z nastavení') a s prázdným datumem ADL",
      "descr2": "u CFR, CIF přeprav není ADL, to jsou přepravy letecké nebo námořní - tyto přepravy zmizí po vyplnění ATD (datum odplutí/odletu)."
    },
    "warehousePoItems": {
      "descr": "Zobrazí položky Balícího listu, které skončily ve vybraném místě doručení a dále již nikam nepokračují."
    },
    "progressGraph": {
      "fieldTitles": "Popis sloupců"
    },
    "trackAndTrace": {
      "descr": "Zobrazí úseky dle ETD (ETD je mezi datumy 'dnešní datum - ETDminus z nastavení' a 'dnešní datum + ETD plus z nastavení') a s prázdným datumem ATA nebo ATA >= dnešní datum + ATA Plus",
      "descr2": "Zobrazí úseky, které se chystají v přístím týdnu(ETD plus), které jsou na cestě(ETD minus) a které dojely maximálně před třemi dny (ATA Plus).",
      "colors": "Popis barevného označení",
      "descrRed": "ČERVENÁ - ETD nebo ETA jsou minulosti nebo nebo je přeprava ve stavu suspended.",
      "descrOrange": "ORANŽOVÁ - ETD nebo ETA během 30 min nastanou nebo na na úseku byl nahlášen nějaký problém (zácpa, nehoda, zpoždění...).",
      "descrGreen": "ZELENÁ - Není zde žádný časový problém.",
      "descrWhite": "BÍLÁ - nový úsek, nebo není nastaven řidič.",
      "showEmergency": "Zobrazit pouze urgentní záznamy (červené, oranžové)"
    },
    "origin": {
      "descr": "Zobrazí úseky dle ETD (ETD je mezi datumy 'dnešní datum - ETDminus z nastavení' a 'dnešní datum + ETD plus z nastavení') a s prázdným datumem ATA nebo ATA >= dnešní datum + ATA Plus",
      "descr2": "Zobrazí úseky, které se chystají v přístím týdnu(ETD plus), které jsou na cestě(ETD minus) a které dojely maximálně před třemi dny (ATA Plus).",
      "colors": "Popis barevného označení",
      "descrRed": "ČERVENÁ - ETD nebo ETA jsou minulosti nebo nebo je přeprava ve stavu suspended.",
      "descrOrange": "ORANŽOVÁ - ETD nebo ETA během 30 min nastanou nebo na na úseku byl nahlášen nějaký problém (zácpa, nehoda, zpoždění...).",
      "descrGreen": "ZELENÁ - Není zde žádný časový problém.",
      "descrWhite": "BÍLÁ - nový úsek, nebo není nastaven řidič.",
      "showEmergency": "Zobrazit pouze urgentní záznamy (červené, oranžové)"
    },
    "destination": {
      "descr": "Zobrazí úseky dle ETA (ETA je mezi datumy 'dnešní datum - ETA minus z nastavení' a 'dnešní datum + ETA plus z nastavení') a s prázdným datumem ATA nebo ATA >= dnešní datum + ATA Plus",
      "descr2": "Zobrazí úseky, které se chystají v přístím týdnu(ETA plus), které jsou na cestě(ETA minus) a které dojely maximálně před třemi dny (ATA Plus).",
      "colors": "Popis barevného označení",
      "descrRed": "ČERVENÁ - ETD nebo ETA jsou minulosti nebo nebo je přeprava ve stavu suspended.",
      "descrOrange": "ORANŽOVÁ - ETD nebo ETA během 30 min nastanou nebo na na úseku byl nahlášen nějaký problém (zácpa, nehoda, zpoždění...).",
      "descrGreen": "ZELENÁ - Není zde žádný časový problém.",
      "descrWhite": "BÍLÁ - nový úsek, nebo není nastaven řidič.",
      "showEmergency": "Zobrazit pouze urgentní záznamy (červené, oranžové)"
    }
  },
  "trade": {
    "demand": {
      "edit": {
        "containers": "Položka",
        "trucks": "Položka",
        "unloadingPlace": "Vykládka",
        "poItems": "Balící list",
        "dimensions": "rozměry",
        "poItem": "Balící položka",
        "information": "Informace",
        "orderItems": "Položky",
        "mode": "Typ přepravy",
        "partner": "Partner",
        "kind": "Export/import",
        "document": "Číslo poptávky",
        "status": "Status",
        "clientRef": "Klientská ref.",
        "clientRef2": "Klientská ref. 2",
        "clientRef3": "Klientská ref. 3",
        "clientRef4": "Klientská ref. 4",
        "clientRef5": "Klientská ref. 5",
        "incoterms": "Incoterms",
        "incotermsPlace": "Incoterms place",
        "service": "Typ servisu",
        "express": "Přímý vůz z (do) přístavu",
        "insurance": "Pojištění",
        "temperatureTransport": "Teplotně řízená přeprava",
        "adrRoad": "DGR Cargo",
        "adrSea": "DGR Cargo",
        "adrAir": "DGR Cargo",
        "customsOrigin": "Exportní celní odbavení",
        "customsDest": "Importní celní odbavení",
        "pickup": "Adresa nakládky",
        "delivery": "Adresa vykládky",
        "shipper": "Adresa odesílatele",
        "consignee": "Adresa příjemce",
        "portSea": "Přístav",
        "portAir": "Letiště",
        "portRail": "Brána",
        "portRailOrigin": "Terminál odeslání",
        "portRailDestination": "Terminál určení",
        "door": "Seznam adres",
        "another": "Nová adresa",
        "addressName": "Jméno",
        "addressStreet": "Adresa",
        "addressStreet2": "2. řádek",
        "addressCity": "Město",
        "addressCountry": "Stát",
        "addressZipCode": "PSČ",
        "note": "Poznámka",
        "ecr": "ECR (Zboží připraveno)",
        "rdl": "RDL (Doručit do)",
        "rrtt": "Nabídka požadována do",
        "loadType": "Typ nákladu",
        "addContainer": "Přidat položku",
        "addTruck": "Přidat vůz",
        "addCar": "Přidat vagón",
        "addPoItem": "Přidat balící položku",
        "addUnloadingPlace": "Přidat další vykládku",
        "dataLog": "Datalog",
        "rdlRequired": "Vyplňte datum RDL.",
        "ecrRequired": "Vyplňte datum ECR.",
        "owner": "Zodpovědná osoba",
        "saveCanceled": "Poptávka nebyla uložena, protože už byla zpracována. Pro více informací kontaktujte obchodníka."
      }
    }
  },
  "edit": {
    "poItem": {
      "name": "Název",
      "baseInfo": "Základní údaje",
      "perColli": "Na 1 colli",
      "subjectId": "Subjekt:",
      "oogcntr": "Typ vztahu:",
      "packaging": "Balení:",
      "descr": "Popis:",
      "colli": "Colli:",
      "pcs": "Počet částí:",
      "length": "Délka(cm):",
      "width": "Šířka(cm):",
      "height": "Výška(cm):",
      "lengthM": "Délka(m):",
      "widthM": "Šířka(m):",
      "heightM": "Výška(m):",
      "weight": "Hrubá váha(kg):",
      "stackable": "Stohovatelné:",
      "orderItem": "Položka:",
      "kgs": "Hmotnost(kg)",
      "countOrder": "Počet vazeb",
      "contactEmail": "Email kontaktu",
      "metOriginCountry": "Země původu",
      "transported": "Doručeno",
      "inProgress": "Na cestě",
      "metOriginCountryName": "descr",
      "tracedoNumber": "Číslo úseku"
    }
  },
  "others": {
    "filename": {
      "gridSettings": "Uživatelské nastavení tabulek."
    }
  },
  "codelists": {
    "demandTemplateType": {
      "normal": "Standardní",
      "multipleDelivery": "Více vykládka"
    }
  },
  "settings": {
    "addresses": {
      "edit": "Upravit adresu",
      "editContactPerson": "Upravit kontaktní osoby",
      "saveOrLoseEdit": "Upravená data uložte, v opačném případě budou zahozena.",
      "noChanges": "Nejsou provedeny žádné změny.",
      "successfulSave": "Změny byly úspěšně uloženy.",
      "saveFailed": "Změny se nepodařilo uložit. Zkuste to prosím znovu.",
      "mergeDuplicities": "Sloučit duplicitní osoby",
      "mergeSuccess": "Sloučení proběhlo úspěšně.",
      "noContactsMess": "Pro tuto adresu jsme nenalezli žádné kontaktní údaje.",
      "selectContact": "Vyberte osobu, do které budou sloučeny všechny ostatní.",
      "ramps": {
        "title": "Rampy",
        "name": "Rampa",
        "alertUsed": "Nelze smazat! Tato rampa je již v přepravě použita."
      }
    }
  },
  "checkpoints": {
    "typed": {
      "cargo_load_etd": "Cargo ETD",
      "cargo_load_atd": "Cargo ATD",
      "cargo_load_address_full": "Nakládka zboží",
      "cargo_unload_eta": "Cargo ETA",
      "cargo_unload_ata": "Cargo ATA",
      "cargo_unload_address_full": "Vykládka zboží"
    },
    "captions": {
      "origin_terminal": "Terminál odeslání",
      "destination_terminal": "Terminál určení",
    }
  },
  "rolePermissions": {
    "availableActions": "Dostupné akce",
    "availableResources": "Dostupné zdroje",    
    "paths": {
      "null": "Nezařazené",
      "app": "Aplikace",
      "section": "Sekce",
      "widget": "Widget",
      "entity": {
        "tracedos": "Entita - Úsek Track & Trace (dle role subjektu)",
        "tracedo": "Entita - Úsek Track & Trace",
        "user" : "Entity - Uživatel",
        "address": "Entita - adresa",
        "subject": "Entita - subjekt",
        "subjects": "Subjekty obecně"
      },
      "customfields": "Uživatelská pole",
      "rolePermissions": "Oprávnění rolí"
    }   
  } 
}