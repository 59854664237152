import {Tracedo} from "../../entities/Tracedo";
import {Checkpoint} from "../../entities/Checkpoint";
import {i18n} from "i18next-ko";
import {PoItem} from "../../entities/PoItem";

export class EditFormValidationManager {

    private errorMessages: string[] = [];

    /**
     *  returns errors from last form validation, call only after calling isValid method
     */
    public getErrors(): string[] {
        return this.errorMessages;
    }

    public isValid(
        tracedo: Tracedo,
        poItems: PoItem[],
        invalidCheckpointAddressCallback: () => void,
        invalidPoItemsCallback: () => void
    ): boolean {
        // Pro editaci zakaznikem nebo dopravcem neni potreba validace, vse je nepovinne
        if (tracedo.id && tracedo.myField !== 'spediter') {
            return true;
        }

        const isValidated = {
            // Moje responsible person je povinna
            emptyMyPicUser: (!tracedo.myPicUserId || jQuery.trim(String(tracedo.myPicUserId)) === ''),
            // Musí být buď vybraná origin (pickup) a zároveň Destination (delivery) adresa, nebo vyplněná nová
            invalidAddress: !this.isAddressValid(tracedo),
            // ETD from je povinne
            emptyETD: (!tracedo.etdFrom || jQuery.trim(<string>tracedo.etdFrom) === ''),
            // ETA from je povinne
            emptyETA: (!tracedo.etaFrom || jQuery.trim(<string>tracedo.etaFrom) === ''),
            // Customer je povinny
            emptyCustomer: (!tracedo.customerPartnerId || jQuery.trim(String(tracedo.customerPartnerId)) === ''),
            // Carrier je povinny
            emptyCarrier: (!tracedo.supplierPartnerId || jQuery.trim(String(tracedo.supplierPartnerId)) === ''),
            invalidCheckpointAddress: !this.isCheckpointsValid(tracedo.checkpoints, invalidCheckpointAddressCallback),
            invalidPoItems: !this.isPoItemValid(tracedo, poItems, invalidPoItemsCallback)
        }
        this.errorMessages = [];

        Object.keys(isValidated).forEach((key) => {
            if (typeof ((isValidated as any)[key]) === 'function' ? (isValidated as any)[key]() : (isValidated as any)[key]) {
                this.errorMessages.push(i18n.t(`system.alerts.${key}`));
            }
        });

        return this.errorMessages.length === 0;
    }

    private isAddressValid(tracedo: Tracedo): boolean {
        if (!tracedo.pickupAddressId) {
            if (jQuery.trim(tracedo.originAddressCity) === '') return false;
            if (jQuery.trim(tracedo.originAddressZipCode) === '') return false;
            if (!tracedo.originAddressCountryId) return false;
        }

        if (!tracedo.deliveryAddressId) {
            if (jQuery.trim(tracedo.destAddressCity) === '') return false;
            if (jQuery.trim(tracedo.destAddressCity) === '') return false;
            if (!tracedo.destAddressCountryId) return false;
        }

        return true;
    }

    private isCheckpointValid(checkpoint: Checkpoint): boolean {
        if (!checkpoint.addressbookId) {
            if (jQuery.trim(checkpoint.addressName) === '') return false;
            if (jQuery.trim(checkpoint.addressTown) === '') return false;
            if (jQuery.trim(checkpoint.addressZipCode) === '') return false;
            if (!checkpoint.addressCountryId) return false;
        }
        return true;
    }

    private isCheckpointsValid(checkpoints: Checkpoint[], invalidCallback: () => void): boolean {
        for (let checkpoint of checkpoints) {
            if (this.isCheckpointValid(checkpoint)) continue;
            invalidCallback();
            return false;
        }
        return true;
    }

    private isPoItemValid(tracedo: Tracedo, poItems: PoItem[], invalidCallback: () => void): boolean {
        if (tracedo.id !== null || poItems.length === 0) {
            return true;
        }
        for (const poItem of poItems) {
            if (jQuery.trim(poItem.descr) === '') {
                invalidCallback();
                return false;
            }
        }
        return true;
    }
}
