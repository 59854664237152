import { i18n } from "i18next-ko";
import { inject, injectable } from "tsyringe";
import { Client } from "../../../../tracejs/src/net/jsonrpc/Client";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { CustomFieldsHelper } from "../../../model/CustomFieldsHelper";

import { KendoHelpers } from "../../../model/KendoHelpers";
import { TypedCheckpointsHelper } from "../../../model/TypedCheckpointsHelper";
import { BaseViewModel } from "../../common/BaseViewModel";
import { TracedoHelpers } from "../../TrackAndTrace/TracedoHelpers";
import { IGridConfiguratorExportOptions, IGridConfiguratorOptions } from "./IGridConfiguratorOptions";
import { IGridPreset } from "./IGridPreset";

/**
 * Default ViewModel for Grid Configurator
 */
@injectable()
export class GridConfigurator extends BaseViewModel<IGridConfiguratorOptions>
{
	/**
	 * Defaults
	 */
	protected _settings: IGridConfiguratorOptions = {
		grid: null,
		name: null,

		showButtonCaptions: true,
		showSearch: true,
		showPresets: true,
		showExports: true,
		
		exports: [],

		//showGraphs: null,
		//graphs: null
	};


	/**
	 * Kendo GRID reference
	 */
	protected kendoGrid: kendo.ui.Grid;

	/**
	 * Unique GRID name
	 */
	protected gridName: string;

	/**
	 * Widget instances
	 */
	protected presets: KnockoutObservableArray<IGridPreset> = ko.observableArray([]);

	/**
	 * Chosen preset's name
	 */
	protected preset: KnockoutObservable<string> = ko.observable(null);

	/**
	 * Default grid settings backup
	 */
	protected backup: IGridPreset;

	/**
	 * Not Default
	 */
	protected notDefault: KnockoutObservable<boolean> = ko.observable(true);


	//protected selectedGraphPath: KnockoutObservable<string> = ko.observable(null);

	/**
	 * Search text
	 */
	protected search: KnockoutObservable<string> = ko.observable('');


	// custom fields helper
	protected customFieldsHelper: CustomFieldsHelper;

	// typed checkpoints helper
	protected typedCheckpointsHelper: TypedCheckpointsHelper;


	/**
	 * Search text GETter
	 */
	public get searchText(): string
	{
		return this.search();
	}


	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param culture Culture
	 */
	constructor(
		@inject(Client) rpc: Client, 
		@inject(CustomFieldsHelper) customFieldsHelper: CustomFieldsHelper,
		@inject(TypedCheckpointsHelper) typedCheckpointsHelper: TypedCheckpointsHelper
	) {
		super(rpc);
		this.customFieldsHelper = customFieldsHelper;
		this.typedCheckpointsHelper = typedCheckpointsHelper;
	}


	/**
	 * Component Startup
	 * @param options Component options
	 */
	public configure(options: IGridConfiguratorOptions): void
	{
		super.configure(options)
		
		//Add translation into graph`s name
		// if(this.settings.graphs) {
		// 	jQuery.each(options.graphs, (i, graph: any) => {
		// 		graph.name = i18n.t('system.tools.gridConfigurator.' + graph.name);
		// 	});
		// }

		// shortcut for GRID and NAME
		this.kendoGrid = this.settings.grid;
		this.gridName = this.settings.name;

		// build backup
		this.backup = this.buildPreset(null);

		// subscribe for preset change
		this.preset.subscribe((name) => {
			if(name !== undefined) {
				if (this.preset()) {
					var pr = this.getPresetByName(this.preset());
					if (pr) {
						this.notDefault(!pr.default);
						this.applyPreset(pr);
					}
				}
				else {
					this.notDefault(false);
					this.applyPreset(this.backup);
				}

			} else {
				this.notDefault(false);
				this.applyPreset(this.backup);
			}

		});

		// subscribe
		this.search.subscribe(() => {
			/*var text = this.search().substring(0, 24);
			this.search(text);*/
			(this.kendoGrid.dataSource as any).searchText = this.search();
		});
		(this.kendoGrid.dataSource as any).searchText = this.search();
	}

	public async startup(): Promise<void>
	{
		await super.startup(),
		await Promise.all([
			this.loadPresets(),
			this.typedCheckpointsHelper.loadCheckpointTypes(),
			this.customFieldsHelper.loadCustomFields()
		]);
	}

	/**
	 * Loads all presets
	 */
	private async loadPresets(forcePreset: any = null): Promise<void>
	{
		let presets: string = await this.rpc.call('userSettings.loadMy', { key: '[client][kendo.grid][' + this.gridName + ']' });
		// set presets
		if(presets && presets != 'null') {
			this.presets(JSON.parse(presets));
		}

		// force to load given preset ?
		if (forcePreset) {
			this.preset(forcePreset);
			return;
		}

		// if not forced - find default preset and load it
		this.presets().forEach((preset: IGridPreset) => {
			if (preset.default) {
				this.preset(preset.name);
			}
		});
	}

	/**
	 * Nahradi preset
	 * @param name Preset name
	 * @param preset Preset
	 */
	private replacePreset(name: string, newPreset: IGridPreset)
	{
		for (let i in this.presets()) {
			let preset = this.presets()[i];
			if (preset.name === name) {
				this.presets()[i] = newPreset;
				break;
			}
		}
	}

	// Hledani stisknutim enteru
	public enterSearch(data: any, event: KeyboardEvent)
	{
		if (event.key == 'Enter') { // keyCode == 13
			this.reloadGrid(true);
			return;
		}
		return true;
	}

	/**
	 * refresh kendo grid
	 * 
	 * @param reset - set to first page
	 */
	public reloadGrid(reset: boolean = false): void
	{
		if (reset) {
			this.kendoGrid.dataSource.page(1);	// this also refreshes the grid
		}
		else {
			this.kendoGrid.dataSource.read();
		}
	}

	/**
	 * Obnovit preset
	 */
	public refreshPreset(): void
	{
		this.search(null);
		(this.kendoGrid.dataSource as any).searchText = null;

		let name = this.preset();
		this.applyPreset(name ? this.getPresetByName(name) : this.backup);
	}

	/**
	 * Rename current preset
	 */
	public async rename(): Promise<void>
	{
		let oldName = this.preset();
		let preset = this.getPresetByName(oldName);

		let newName = await this.promptDialog(i18n.t('system.tools.gridConfigurator.enterPresetName'), oldName);
		if (newName === null) return;

		preset.name = newName;
		this.replacePreset(oldName, preset);

		await this.savePresets()
			.then(() => {
				this.loadPresets(newName);
			})
			.catch((error: any) => {
				alert(error.message);
			});
	}

	/**
	 * Save current preset
	 */
	public async save(): Promise<void>
	{
		if (!this.preset()) {
			return await this.saveAs();
		}
		var name = this.preset();

		let overwrite = await this.confirmDialog(i18n.t('system.tools.gridConfigurator.overwrite', { 'name': name }));
		if (overwrite) {
			// vytahneme stary
			var old = this.getPresetByName(name);
			// sestavime novy
			var built = this.buildPreset(name);
			// zaktualizujeme priznak defaultnosti
			built.default = old.default;
			// nahradime v kolekci
			this.replacePreset(name, built);
			// ulozime
			return this.savePresets()
				.then(() => {
					this.loadPresets(name);
				})
				.catch((error: any) => {
					alert(error.message); 
				});
		}
	}

	/**
	 * Save current preset as new item
	 */
	public async saveAs(): Promise<void>
	{
		let name = await this.promptDialog(i18n.t('system.tools.gridConfigurator.enterPresetName'), '');
		if(name) {
			var preset = this.getPresetByName(name);
			var built = this.buildPreset(name);
			if (preset !== null) {
				let overwrite = await this.confirmDialog(i18n.t('system.tools.gridConfigurator.overwrite', { 'name': name }));
				if (!overwrite) {
					return await this.saveAs();
				}
				else {
					this.replacePreset(name, built);
				}
			}
			else {
				this.presets.push(built);
			}
			// save and when done, load presets
			return await this.savePresets()
				.then(() => { 
					this.loadPresets(name); 
				})
				.catch((error: any) => { 
					alert(error.message); 
				});
		}
	}

	/**
	 * Deletes currently loaded preset
	 */
	public async delete(): Promise<any> {
		let del = await this.confirmDialog(i18n.t('system.tools.gridConfigurator.deletePresetQuestion'));
		if(del) {
			var preset = this.getPresetByName(this.preset());
			if (preset) {
				this.presets.remove(preset);
				this.preset(null);
				return await this.savePresets();
			}
		}
	}

	/**
	 * returns preset by its name
	 * @param string name Preset name
	 * @return IGridPreset
	 */
	public getPresetByName(name: string): IGridPreset {
		let found: IGridPreset = this.presets().find((p: IGridPreset) => p.name == name);
		return found ? found : null;
	}

	/**
	 * Set as default
	 */
	public async setDefault(): Promise<void>
	{
		this.presets().forEach((p: IGridPreset) => {
			p.default = this.preset() === p.name;
		});		
		this.notDefault(false);
		return await this.savePresets();
	}


	public getColums(): any[]
	{
		let query = this.buildPreset('xls-export', true);
		return query.columns;
	}

	/**
	 * Calls XLS export on the server
	 * 
	 * @param string name Export name
	 */
	public export(name: string): void
	{
		let eoSettings = this.findExportByName(name);

		let query = this.buildPreset('xls-export', true);
		let columns = query.columns;
		delete query.columns;
		delete query.default;
		delete query.name;

		let selectedIds: number[] = [];
		if(eoSettings.selectedIdProperty && eoSettings.selectedIdsProperty) {
			const rows = this.kendoGrid.select();
			rows.each((ix: number, el: HTMLElement) => {
				let rowData = this.kendoGrid.dataSource.getByUid($(el).data('uid'));
				selectedIds.push(rowData.get(eoSettings.selectedIdProperty as string));
			});
		}

		// add static criteria via parameter GridConfiguratorOptions
		if(eoSettings.query) {
			var defaultFilter = eoSettings.query;
			if (query.filter !== undefined) {
				defaultFilter.filters.push(query.filter);
			}
			query.filter = defaultFilter;
		}
		query.search = this.search();
		//KendoHelpers.replaceRequestData(query, '__');

		// Tracedo Only  -  compatibilize column names
		//TracedoHelpers.compatibilizeColumns(columns);

		let dateFields = jQuery.extend({},
			TracedoHelpers.tracedoGridDateColumns(),
			this.typedCheckpointsHelper.getDateFields(),
			this.customFieldsHelper.getDateFields('fields_')
		);
		// Tracedo Onlu - compatilibize filters and sorts
		TracedoHelpers.compatibilizeRequestData(query, '__', dateFields);

		let params: any = {
			query: query,
			columns: columns,
			lang: this.culture.localeShort,
			...ko.mapping.toJS(eoSettings.params) // add additional exports parameters
		};
		if(eoSettings.selectedIdsProperty) {
			params[eoSettings.selectedIdsProperty] = selectedIds;
		}

		// columns = Ikit.Utils.KendoHelpers.replaceRequestData(columns, '__');
		if(typeof eoSettings.method === 'function') {
			eoSettings.method(params);
		}
		else {
			this.rpc.call(eoSettings.method, params).then((url: string) => {
				location.href = url;
			});
		}
	}

	/**
	 * Calls XLS export on the server
	 */
	private findExportByName(name: string): IGridConfiguratorExportOptions
	{
		let found = this.settings.exports.find((eo: IGridConfiguratorExportOptions) => eo.name == name );
		if(found) {
			if(!found.params) {
				found.params = {};	// empty additional params if not present
			}
			return found;
		}
		return null;
	}

	// public showGraph()
	// {
	// 	if (this.selectedGraphPath()) {
	// 		var option = this.kendoGrid.getOptions();
	// 		var criteria = {
	// 			filter: option.dataSource.filter
	// 		}; '#mySelect option[value="fg"]'
	// 		KendoHelpers.replaceRequestData(criteria, '__');
	// 		(this.loadViewFrame('System.Tools.GridConfigurator.Graphs.' + this.selectedGraphPath() + '.Default', [criteria])).done((vm: ExcarrierKgs, el: JQuery) => {
	// 			el.kendoWindow({
	// 				deactivate: () => { el.data('kendoWindow').destroy(); },
	// 				actions: ['Close'],
	// 				modal: true,
	// 				visible: false,
	// 				title: $('#graph option[value="' + this.selectedGraphPath() + '"').text(),
	// 				resizable: true,
	// 				width: vm.sizeRequest.width,
	// 				height: vm.sizeRequest.height,
	// 				open: (e) => {
	// 					this.windowButtons(e.sender.element, [
	// 						{
	// 							align: 'right',
	// 							label: i18n.t('common.actions.close'),
	// 							click: () => {
	// 								el.data('kendoWindow').close();
	// 							}
	// 						}
	// 					]);
	// 				}
	// 			}).data('kendoWindow').center().open();
	// 		});
	// 	}
	// }


	/**
	 * Save presets
	 * @return JQueryPromise
	 */
	private async savePresets(): Promise<any>
	{
		let pts = this.presets().sort((a: IGridPreset, b: IGridPreset) => {
			return (a.name > b.name ? 1 : (a.name < b.name ? -1 : 0));
		});

		return await this.rpc.call('userSettings.saveMy', {
			key: '[client][kendo.grid][' + this.gridName + ']',
			value: JSON.stringify(pts)
		});
	}


	/**
	 * Returns current preset data
	 * @param string Preset name
	 * @return Grid Preset Data
	 */
	private buildPreset(name: string, moreInfo: boolean = false): IGridPreset
	{
		let cols: any[] = [];
		let visibleIndex = 1;

		//u Po-Items je prvni column detail, neni v columns (colgroup col.k-hierarchy-col).
		if (this.kendoGrid.element.find("colgroup col.k-hierarchy-col").length > 0) {
			visibleIndex = 2;
		}
		//console.log(this.kendoGrid.columns);
		this.kendoGrid.columns.forEach((col) => {
			//ziskani opravdove sirky z html elementu (col.width funguje jen pri zmene sirky, jinak je undefined => automaticka sirka)
			if (!col.hidden) {
				var width = this.kendoGrid.element.find('colgroup col:nth-child(' + visibleIndex.toString() + ')').width();
				if (col.width != width) {
					col.width = width;
				}
				visibleIndex++;
			}
			var colDef: any = {
				field: col.field,
				hidden: col.hidden === true,
				width: col.width === undefined ? null : col.width,
			};
			if (moreInfo) {
				colDef.title = col.title;
				colDef.format = col.format;
			}
			var text = colDef.field;
			if (text) {
				if (text.indexOf('concatField=') !== -1) {
					var fieldsNames = text.split("+");
					if (fieldsNames[0]) {
						var fieldsNames2 = fieldsNames[0].split("=");
						if (fieldsNames2[1]) {
							colDef.field = fieldsNames2[1];
						}
					}
				}
			}
			cols.push(colDef);
		});
		var pr: IGridPreset = {
			columns: cols,
			filter: this.kendoGrid.dataSource.filter(),
			sort: this.kendoGrid.dataSource.sort(),
			search: this.search(),
			name: name,
			default: false
		};
		//console.log(pr);
		return pr;
	}

	/**
	 * Apply the given preset (sets grid)
	 * @param presetSrc Preset to apply (will be cloned)
	 */
	private applyPreset(presetSrc: IGridPreset)
	{
		// columns hash for faster column location
		var columnsHash: any = {};
		jQuery.each(this.kendoGrid.columns, (i, col: any) => {
			columnsHash[col.field] = col;
			if (col.field) {
				var text = col.field;
				if (text.indexOf('concatField') !== -1) {
					var fieldsNames = text.split("||");
					if (fieldsNames[0]) {
						var fieldsNames2 = fieldsNames[0].split("+");
						if (fieldsNames2[0]) {
							var fieldsNames3 = fieldsNames2[0].split("=");
							if (fieldsNames3[1]) {
								columnsHash[fieldsNames3[1]] = col;
							}
						}
					}
				}
			}
		});
		
		var preset = jQuery.extend(true, {}, presetSrc);
		var widths: any = {};
		
		var visibleIndex = 1;

		var toDelete = [];
		//pokud uzivatelske nastaveni ma ulozeno vice sloupcu nez existuje v kodu
		var minusIndex = 0;

		//pokud ma prvni sloupec sirku 27, jedna se o stare ulozeni, prvni sloupec je 27 sirka detailInit
		var fixSettings = false;
		if (preset.columns[0].width == 27) {
			fixSettings = true;
		}
		//console.log(preset.columns);
		jQuery.each(preset.columns, (i, col: any) => {
			//if column doesn't exist anymore		
			if (columnsHash[col.field]) {
				// show hide column	
				if (col.hidden) {
					this.kendoGrid.hideColumn(col.field);
				}
				else {
					this.kendoGrid.showColumn(col.field);
					//pokud ma prvni sloupec sirku 27, jedna se o stare ulozeni
					if (fixSettings && preset.columns[i + 1]) {
						widths[visibleIndex] = preset.columns[i + 1].width ? preset.columns[i + 1].width : 100;
					}
					else {
						widths[visibleIndex] = col.width ? col.width : 100;
					}
					visibleIndex++;
				}
				// column width
				//columnsHash[col.field].width = col.width ? col.width : columnsHash[col.field].width;
				//column order

				this.kendoGrid.reorderColumn(i - minusIndex, columnsHash[col.field]);
			}
			else {
				minusIndex ++;
			}
		});

		// Hide columns that are new to grid and are not saved in this preset
		var presetColumnsHash: any = {};
		jQuery.each(preset.columns, (i, col) => {
			presetColumnsHash[col.field] = col;
		});
		jQuery.each(this.kendoGrid.columns, (i, col) => {
			if(!presetColumnsHash[col.field]) {
				this.kendoGrid.hideColumn(col.field);
			}
		});

		//console.log(widths);
		//u Po-Items je prvni column detail, neni v columns (colgroup col.k-hierarchy-col).
		var offset = 0;
		if (this.kendoGrid.element.find("colgroup col.k-hierarchy-col").length > 0) {
			offset = 1;
		}
		// column widths (cols)
		jQuery.each(widths, (vis: any, val) => {
			this.kendoGrid.element.find('colgroup col:nth-child(' + (parseInt(vis, 10) + offset).toString() + ')').attr('width', val).attr('style', 'width: '+ val +'px');
		});

		// Search
		if(presetSrc.search) {
			this.search(presetSrc.search);
		}
		else {
			this.search('');
		}

		// query
		this.kendoGrid.dataSource.query({
			filter: preset.filter ? preset.filter : {},
			sort: preset.sort ? preset.sort : [],
			pageSize: this.kendoGrid.dataSource.pageSize(),
			page: this.kendoGrid.dataSource.page(),
			group: this.kendoGrid.dataSource.group(),
			aggregate: this.kendoGrid.dataSource.aggregate()
		});
	}


	/**
	 * Grid Configurator template
	 * @returns 
	 */
	public template = (): HTMLElement => (

		<div className="row">
			<div className="col">
				<div className="inline-form">

					<ko with="$root.presets">
						<label className="col-form-label" data-bind="uniqueFor: 'preset', i18n: 'system.tools.gridConfigurator.presets'"></label>
						<select className="form-select" data-bind="
							uniqueId: 'preset',
							value: $root.preset,
							options: $root.presets,
							optionsValue: 'name',
							optionsText: 'name',
							optionsCaption: i18n.t('system.tools.gridConfigurator.selectPreset')">
						</select>
					</ko>
	
					<button type="button" className="btn btn-outline-secondary" data-bind="click: $root.refreshPreset.bind($root),
						attr: { title: i18n.t('kendo.filterMenu.clear') }">
						<i className="k-icon k-i-reload"></i>
						<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'kendo.filterMenu.clear'"></span></ko>
					</button>
					<button type="button" className="btn btn-outline-secondary" data-bind="
						enable: $root.notDefault, click: $root.setDefault.bind($root),
						attr: { title: i18n.t('common.actions.setAsDefault') }">
						<i className="icon-ok"></i>
						<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'common.actions.setAsDefault'"></span></ko>
					</button>
					<button type="button" className="btn btn-outline-secondary" data-bind="
						click: $root.rename.bind($root),
						attr: { title: i18n.t('common.actions.rename') }">
						<i className="icon-edit"></i>
						<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'common.actions.rename'"></span></ko>
					</button>
					<button type="button" className="btn btn-outline-secondary" data-bind="
						click: $root.save.bind($root),
						attr: { title: i18n.t('common.actions.save') }">
						<i className="icon-floppy"></i>
						<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'common.actions.save'"></span></ko>
					</button>
					<button type="button" className="btn btn-outline-secondary" data-bind="
						click: $root.saveAs.bind($root),
						attr: { title: i18n.t('common.actions.saveAs') }">
						<i className="icon-floppy-1"></i>
						<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'common.actions.saveAs'"></span></ko>
					</button>
					<button type="button" className="btn btn-outline-danger" data-bind="
						enable: $root.preset,
						click: $root.delete.bind($root),
						attr: { title: i18n.t('common.actions.delete') }">
						<i className="icon-trash"></i>
						<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'common.actions.delete'"></span></ko>
					</button>


					<ko if="$root.settings.showExports">

						<ko foreach="$root.settings.exports">

							<button type="button" data-bind="
								click: $root.export.bind($root, $data.name),
								attr: {
									'title': i18n.t('common.actions.exportExcel'),
									'class': 'btn btn-outline-secondary ' + ($data.buttonClass ?? '')
								}">
								<i data-bind="attr: { 'class': $data.iconClass ?? 'icon-table' }"></i>
								<ko if="$root.settings.showButtonCaptions"><span data-bind="text: $data.name"></span></ko>
							</button>

						</ko>

					</ko>

					{/*<ko if="$root.settings.showGraphs">
						<label className="inline" data-bind="i18n: 'system.tools.gridConfigurator.graphs'"></label>
						<select id="graph" data-bind="
							attr: {
								'class': 'if-small',
								'style': 'padding-right: 18px;'
							},
							value: $root.selectedGraphPath,
							options: $root.settings.graphs,
							optionsValue: 'path',
							optionsText: 'name',
							optionsCaption: i18n.t('system.tools.gridConfigurator.selectGraph')"></select>
						<button type="button" className="btn btn-outline-secondary" data-bind="
								click: $root.showGraph.bind($root),
								attr: { title: i18n.t('system.tools.gridConfigurator.showGraph') }">
							<i className="icon-table"></i><ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'system.tools.gridConfigurator.showGraph'"></span></ko>
						</button>
						</ko>*/}
				</div>
			</div>
			<div class="col-auto">
				<ko if="$root.settings.showSearch">
					<div className="inline-form">
						{/*<!--label className="inline" data-bind="uniqueFor: 'search', i18n: 'common.actions.search'"></label-->*/}
						<input type="text" autocomplete="off" data-bind="
							attr: {
								'placeholder': i18n.t('common.actions.search')
							},
							uniqueId: 'search',
							value: $root.search,
							valueUpdate: 'afterkeydown',
							event: { keypress: $root.enterSearch.bind($root) }" className="form-control" />
						<button type="button" className="btn btn-primary" data-bind="click: $root.reloadGrid.bind($root)">
							<i className="icon-search"></i>
							<ko if="$root.settings.showButtonCaptions"><span data-bind="i18n: 'common.actions.search'"></span></ko>
						</button>
					</div>
				</ko>				
			</div>
		</div>

	);


}