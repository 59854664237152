import { inject, injectable } from "tsyringe";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { Objects } from "../../../tracejs/src/utils/Objects";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { CustomFieldsHelper } from "../../model/CustomFieldsHelper";

import { BaseViewModel } from "../common/BaseViewModel";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../model/KendoHelpers";
import { GridConfigurator } from "../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../components/GridConfigurator/IGridConfiguratorOptions";
import { CodelistManager } from "../../model/CodelistManager";
import { TracedoHelpers } from "./TracedoHelpers";
import { TracedoManager } from "../../model/TracedoManager";
import { TypedCheckpointsHelper } from "../../model/TypedCheckpointsHelper";
import moment from "moment";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { TracedoGroupManager } from "../../model/TracedoGroupManager";
import { TracedoGroupItem } from "../../entities/TracedoGroupItem";
import { TracedoGroup } from "../../entities/TracedoGroup";
import { Tracedo } from "../../entities/Tracedo";

/**
 * Tracedo Group Select
 */
@injectable()
export class TracedoGroupSelect extends BaseDialogViewModel<any>
{

	/** Tracedo Group Manager */
	protected tracedoGroupManager: TracedoGroupManager;

    /** Combo with tracedo groups */
    protected groupComboBox: kendo.ui.ComboBox;

    /** Newly added items */
    protected newItems: KnockoutObservableArray<{tracedo: Tracedo, group: null|TracedoGroup}> = ko.observableArray([]);;

    /** Already existing items in the group */
    protected existingItems: KnockoutObservableArray<Tracedo> = ko.observableArray([]);;

    /** Selected group */
    protected selectedGroup: KnockoutObservable<TracedoGroup|null> = ko.observable(null);

	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 */
	constructor(@inject(Client) rpc: Client, @inject(TracedoGroupManager) tracedoGroupManager: TracedoGroupManager) {
		super(rpc);
		this.tracedoGroupManager = tracedoGroupManager;
	}

    public async startup(): Promise<any>
    {
        await super.startup();

        // load newly added transports
        this.newItems.removeAll();
        const result: {[key: string]: any} = await this.rpc.batch()
            .call('details', "tracedo.getByIds", { ids: this.settings.selected })
            .call('groups', "tracedoGroup.getByTracedoIds", { ids: this.settings.selected })
            .run();

        result['details'].forEach((t: Tracedo) => {
            this.newItems.push({
                tracedo: t,
                group: result['groups'][t.id] ? result['groups'][t.id] : null
            });
        });
    }

    public async rendered(): Promise<any>
    {
        this.groupComboBox = this.element.find('.groups-select').kendoComboBox({
            dataTextField: "name",
            dataValueField: "id",
            autoBind: true,
            minLength: 1,
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                        const search: string = (options.data.filter.filters ?? []).length > 0 ? (options.data.filter.filters[0] as any).value : null;
                        const groups = await this.tracedoGroupManager.get(search);
                        options.success(groups);
                    }
                }
            },
            filter: "contains",
            suggest: true,
			placeholder: i18n.t('common.captions.pleaseSelectGroup'),
            valuePrimitive: true,
            template: '<strong>#= name #</strong><br><small>#= number #</small>',
			noDataTemplate: (e: any) => {
                if(e.instance.text().trim().length <= 0) {
                    return `<div class="missing-group-info-box">${i18n.t('common.captions.noGroupStartTyping')}</div>`;
                }
                const box = jQuery(`
                    <div class="missing-group-info-box">
                        <div>${i18n.t('common.captions.noGroupAddNew')} "${e.instance.text()}"?</div>
                        <br />
                        <button class="btn btn-primary" type="button" data-action="add-new-group">${i18n.t('common.captions.addNewGroup')}</button>
                    </div>
                `);
                box.find('button[data-action=add-new-group]').on('click', () => {
                    this.addNewGroup(e.instance.text());
                });
                return box;
            },
            select: (e) => {
                // if existing group was selected, load group detail
                this.loadGroupDetail(e.dataItem ? e.dataItem.id : null);
            }
        }).data('kendoComboBox');
        
        this.element.on('click', 'button[data-action=add-new-group]', (event: JQuery.ClickEvent) => {
            console.log('will create ', this.element.find('.groups-select').data('kendoComboBox').value());
        });
    }

    /** Read group detail */
    public async loadGroupDetail(id: number|null)
    {
        if(id === null) {
            this.selectedGroup(null);
            this.existingItems.removeAll();
        }
        else {
            const group: TracedoGroup = await this.tracedoGroupManager.getOne(id);
            this.selectedGroup(group);

            // fill existing items observable array
            this.existingItems.removeAll();
            group.items.forEach((item: TracedoGroupItem) => {
                this.existingItems.push(item.tracedo);
            });
        }
    }

    public async addNewGroup(name: string): Promise<TracedoGroup>
    {
        const group = await this.tracedoGroupManager.create(name);
        await this.groupComboBox.dataSource.read();
        this.groupComboBox.close();
        this.loadGroupDetail(group.id);
        return group;
    }

    public async save(): Promise<number> {

        let ids: number[] = [];
        this.newItems().forEach(tracedo => {
            ids.push(tracedo.tracedo.id);
        });

        return await this.tracedoGroupManager.addToGroup(this.selectedGroup().id, ids);
    }

    public isInExisting(item: Tracedo)
    {
        for(let exItem of this.existingItems()) {
            if(exItem.id == item.id) {
                return true;
            }
        }
        return false;
    }

	public dialogTemplate = (): HTMLElement => (
        <div className="h-100">
            <div>
                <label className="w-100">
                    <span data-bind="i18n: 'common.captions.selectGroup'" className="d-block mb-1"></span>
                    <div className="d-flex">
                        <select className="groups-select" style="width: calc(100% - 200px)"></select>
                        <ko if="$root.selectedGroup()">
                            <strong data-bind="text: $root.selectedGroup().number" className="ms-2 ps-2 pt-1 pb-1 pe-2 k-success-colored"></strong>
                        </ko>
                    </div>
                </label>
            </div>
            <div className="row mt-3" style="height: calc(100% - 60px)">
                <div className="col h-100">
                    <h3 data-bind="i18n: 'common.captions.newlyAddedItems'" className="pb-2 border-bottom"></h3>
                    <ul data-bind="foreach: $root.newItems" className="list-unstyled m-0" style="height: calc(100% - 35px); overflow: auto;">
                        <li>
                            <div className="border p-1 mb-2 me-1 bg-gray-100" data-bind="
                                    css: { 'bg-gray-300 cursor-help': $root.isInExisting($data.tracedo) },
                                    attr: { 'title': $root.isInExisting($data.tracedo) ? i18n.t('common.captions.alreadyContainerInfo') : null },
                                ">
                                <strong data-bind="text: $data.tracedo.myNumber"></strong><br />
                                <small data-bind="text: $data.tracedo.mySubjectRole"></small><br />
                                <ko if="$data.group">
                                    <div className="border bg-danger text-white p-1 text-center cursor-help" data-bind="attr: { title: i18n.t('common.captions.inSomeContainer') }">
                                        <small data-bind="text: $data.group.name"></small> (<small data-bind="text: $data.group.number"></small>)
                                    </div>
                                </ko>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col h-100">
                    <h3 data-bind="i18n: 'common.captions.alreadyContainedItems'" className="pb-2 border-bottom"></h3>
                    <ul data-bind="foreach: $root.existingItems" className="list-unstyled m-0" style="height: calc(100% - 35px); overflow: auto;">
                        <li>
                            <div className="border p-1 mb-2 me-1 bg-gray-300">
                                <strong data-bind="text: $data.myNumber"></strong><br />
                                <small data-bind="text: $data.mySubjectRole"></small>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	);

}
