import { h } from "../../../../tracejs/src/utils/JSXFactory";

export class EditableMainInfo {

    private static exportOrImport = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'kindId', i18n: 'trade.demand.edit.kind'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.kindId" /></label>
                </ko> : ``}                 

                <select className="form-select" data-bind="
                    uniqueId: 'kindId',
                    options: $root.transportKinds,
                    optionsText: 'name',
                    optionsValue: 'kindId',
                    value: kindId
                "></select>
            </div>
        </div>
    );

    private static transportType = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'roadTypeId', i18n: 'trade.demand.edit.mode'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.roadTypeId" /></label>
                </ko> : ``}

                <select data-bind="uniqueId: 'roadTypeId', options: $root.roadTypes, optionsText: 'name', optionsValue: 'id', value: roadTypeId" className="form-select"></select>
            </div>
        </div>
    );

    private static clientRef1 = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'clientRef', i18n: 'common.captions.clientRef'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.clientRef" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'clientRef', value: clientRef" className="form-control" />
            </div>
        </div>
    );

    private static clientRef2 = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'clientRef2', i18n: 'common.captions.clientRef2'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.clientRef2" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'clientRef2', value: clientRef2" className="form-control" />
            </div>
        </div>
    );

    private static clientRef3 = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'clientRef3', i18n: 'common.captions.clientRef3'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.clientRef3" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'clientRef3', value: clientRef3" className="form-control" />
            </div>
        </div>
    );

    private static upz = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myUzp', i18n: 'common.captions.myUzp'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.myUzp" /></label>
                </ko> : ``}

                <input type="date" data-bind="uniqueId: 'myUzp', kendoDatePicker: { animation: false, value: myUzp }" className="w-100" />
            </div>
        </div>
    );

    private static incoterms = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], sync: boolean) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'incotermsId', i18n: 'common.captions.incoterms'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.incotermsId" /></label>
                </ko> : ``}

                <select data-bind="uniqueId: 'incotermsId', options: $root.incoterms, optionsText: 'ident', optionsValue: 'id', optionsCaption: '', value: incotermsId" className="form-select"></select>
            </div>
        </div>  
    );
    
    public static editableMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], showUzp: boolean = false, sync: boolean = false) : HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.mainInformation'"></legend>
            { this.exportOrImport(l, v, sync) }
            { this.transportType(l, v, sync) }

            { this.clientRef1(l, v, sync) }
            { this.clientRef2(l, v, sync) }
            { this.clientRef3(l, v, sync) }
            
            { showUzp ? this.upz(l, v, sync) : '' }

            { this.incoterms(l, v, sync) }
        </fieldset>
    ) 
}