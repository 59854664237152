import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { EditableCustomFields } from "./_editPartials/EditableCustomFields";
import { EditableHeaderTemplate } from "./_editPartials/EditableHeaderTemplate";
import { EditableCheckpointsTemplate } from "./_editPartials/EditableCheckpointsTemplate";
import { EditablePoItems } from "./_editPartials/EditablePoItems";
import { AbstractEdit } from "./AbstractEdit";
import { i18n } from "i18next-ko";
import { Tracedo } from "../../entities/Tracedo";
import { Checkpoint } from "../../entities/Checkpoint";
import { PoItem } from "../../entities/PoItem";

/**
 * Tracedo edit
 */
@injectable()
export class CreateOrder extends AbstractEdit
{

    public async startup()
    {
        await super.startup();

        // nemam pravo na createORder u tracedo a ID je prazdne?
		if(!this.user.isAllowed('entity.tracedo', 'createOrder')) {
			throw new BadRequestError('Access denied - cannot create', 403);
		}

        // Objednavka
        this.isOrdering = true;
        this.isSpediter = false;
        this.isCarrier = false;
        this.isCustomer = true;
        // Nazev zdroje pro opravneni
        this.resourceName = 'entity.tracedoCustomer';
        
		// main subject ID
        const response: any = await this.rpc.call('subject.getMain');
		this.mainSubjectId = response.subjectId;
		this.mainSubject = response;

        // responsible person = current user
        let myPicUserId = this.user.identity.data.userId;
        this.tracedo.myPicUserId(myPicUserId);
        this.tracedo.myField('customer');

        await this.readCodelists();

        // Duplikace objednavky ? 
        if(this.settings.duplicate) {
            let prefillTracedo: Tracedo = await this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId });
            this.prefillDuplicate(prefillTracedo);
        }

        // custom fields default values (NULLs)
        Object.keys(this.customFieldsDefaults).forEach((field: string) => { this.tracedo.fields[field] = ko.observable(this.customFieldsDefaults[field]); });

        // Export is default
        let exportKinds = this.transportKinds().filter((kind: any) => kind.ident.toLocaleUpperCase() === 'E');
        this.tracedo.kindId(exportKinds.length > 0 ? exportKinds[0].kindId : null);
    }

    /**
	 * Vycisti duplikat
	 * @param tracedo 
	 */
    private prefillDuplicate(tracedo: Tracedo): void
    {
        this.tracedo.mySeasonId(this.currentSeason.seasonId);

        this.tracedo.myPicUserId(tracedo.myPicUserId);
        this.tracedo.supplierPartnerId(tracedo.supplierPartnerId);
        this.tracedo.kindId(tracedo.kindId);
        this.tracedo.roadTypeId(tracedo.roadTypeId);

        this.tracedo.pickupAddressId(tracedo.pickupAddressId);
        this.tracedo.originAddressCity(tracedo.originAddressCity);
        this.tracedo.originAddressCountryId(tracedo.originAddressCountryId);
        this.tracedo.originAddressName(tracedo.originAddressName);
        this.tracedo.originAddressStreet(tracedo.originAddressStreet);
        this.tracedo.originAddressStreet2(tracedo.originAddressStreet2);
        this.tracedo.originAddressZipCode(tracedo.originAddressZipCode);

        this.tracedo.pickupContactId(tracedo.pickupContactId);
        this.tracedo.originContactEmail(tracedo.originContactEmail);
        this.tracedo.originContactFirstName(tracedo.originContactFirstName);
        this.tracedo.originContactLastName(tracedo.originContactLastName);
        this.tracedo.originContactPhone(tracedo.originContactPhone);

        this.tracedo.deliveryAddressId(tracedo.deliveryAddressId);
        this.tracedo.destAddressCity(tracedo.destAddressCity);
        this.tracedo.destAddressCountryId(tracedo.destAddressCountryId);
        this.tracedo.destAddressName(tracedo.destAddressName);
        this.tracedo.destAddressStreet(tracedo.destAddressStreet);
        this.tracedo.destAddressStreet2(tracedo.destAddressStreet2);
        this.tracedo.destAddressZipCode(tracedo.destAddressZipCode);

        this.tracedo.deliveryContactId(tracedo.deliveryContactId);
        this.tracedo.destinationContactEmail(tracedo.destinationContactEmail);
        this.tracedo.destinationContactFirstName(tracedo.destinationContactFirstName);
        this.tracedo.destinationContactLastName(tracedo.destinationContactLastName);
        this.tracedo.destinationContactPhone(tracedo.destinationContactPhone);

        this.tracedo.bdpCntrTypeId(tracedo.bdpCntrTypeId);
        this.tracedo.rejdar(tracedo.rejdar);
        this.tracedo.custom(tracedo.custom);
        this.tracedo.customOffice(tracedo.customOffice);
        this.tracedo.shipperName(tracedo.shipperName);
        this.tracedo.consigneeName(tracedo.consigneeName);
        this.tracedo.depotId(tracedo.depotId);

        if(tracedo.checkpoints && tracedo.checkpoints.length > 0) {
            this.tracedo.checkpoints.removeAll();
            tracedo.checkpoints.forEach((cp: Checkpoint) => {
                // Je zastavka typova ?
                if(cp.typeId) {
                    let c = this.addCheckpoint();
                    c.addressbookId(cp.addressbookId);
                    c.addressCountryId(cp.addressCountryId);
                    c.addressName(cp.addressName);
                    c.addressStreet(cp.addressStreet);
                    c.addressStreet2(cp.addressStreet2);
                    c.addressTown(cp.addressTown);
                    c.addressZipCode(cp.addressZipCode);

                    c.contactId(cp.contactId);
                    c.contactFirstName(cp.contactFirstName);
                    c.contactLastName(cp.contactLastName);
                    c.contactPhone(cp.contactPhone);
                    c.contactEmail(cp.contactEmail);

                    c.typeId(cp.typeId);
                    c.name(cp.name);
                }
            });
        }
        
        if(tracedo.fields) {
            this.tracedo.fields = ko.mapping.fromJS(tracedo.fields);
        }
    }




    /**
     * Common render
     */
    public async rendered()
    {
        await super.rendered();
        // zakladani nove objednavky = pridame prvni polozku PO Item - 2022-03-09 - jiz ne
        //this.addPoItem();
    }


	/**
	 * Save tracedo order and its items
	 */
    public async save(): Promise<boolean>
    {
        // Konverze do JS objektu z KnockoutJS observables
        let tracedo = ko.mapping.toJS(this.tracedo);
        let poItems = ko.mapping.toJS(this.poItems);

        // kontrola před uložením
        if(!this.validateOrderForm(tracedo, poItems)) {
            return false;
        }

		// mode Id podle roadTypeId
		tracedo.modeId = this.codelistManager.getOneRoadType(tracedo.roadTypeId, 'modeId');

        // nastaveni spravneho formatu date / datetime pro API
        this.formatDateFieldsForSave(tracedo);
        
        // pokud vytvarime novy usek, pridame PO Items pole, jinak se PO Items vyNULLuji (spravuji se na zvlast zalozce)
        tracedo.poItems = poItems;

        // Ulozeni
        const saved: Tracedo = await this.rpc.call('tracedo.createOrder', { tracedo: tracedo, query: { select: '*,checkpoints(*),poItems(*)' } });

        // vytvorit hashmapu createPosIndex => id
        let poHash: {[key: number]: number} = {};
        saved.poItems.forEach((po: PoItem) => {
            poHash[po.createPosIndex] = po.id;
        });

        // Origin PO Items
        let newOriginPoItems: number[] = [];
        this.originPoItems().forEach(createPosIndex => {
            newOriginPoItems.push(poHash[createPosIndex]);
        })
        this.originPoItems(newOriginPoItems);

        // Destination PO Items
        let newDestinationPoItems: number[] = [];
        this.destinationPoItems().forEach(createPosIndex => {
            newDestinationPoItems.push(poHash[createPosIndex]);
        })
        this.destinationPoItems(newDestinationPoItems);

        // Checkpoints PO Items
        if(tracedo.checkpoints) {
            tracedo.checkpoints.forEach((cp, ix) => {
                let newCPPoItems: number[] = [];
                cp.poItems.forEach((createPosIndex: number) => {
                    newCPPoItems.push(poHash[createPosIndex])
                });
                cp.poItems = newCPPoItems;
            });
        }



		// Sestaveni MAPy pozice zastavky => ID zastavky
		let posIndexIdMap: { [key: number]: number } = {};
		saved.checkpoints.forEach((cp: Checkpoint) => {
			posIndexIdMap[cp.posIndex] = cp.id;
		});

		// Priprava connections PO Items na origin/destination/PO Item
		let connections: { poItemId: number, target: string|number }[] = [];
		this.originPoItems().forEach((poId: number) => {
			connections.push({ poItemId: poId, target: 'origin' });
		});
		this.destinationPoItems().forEach((poId: number) => {
			connections.push({ poItemId: poId, target: 'destination' });
		});
		if(tracedo.checkpoints) {
			tracedo.checkpoints.forEach((cp, ix) => {
				cp.poItems.forEach((poId: number) => {
					connections.push({ poItemId: poId, target: posIndexIdMap[ix] });
				});
			});
		}
		// Nastaveni PO Connections
		await this.rpc.call('poItem.setCheckpointConnections', { tracedoId: saved.id, connections });

        return true;
    }

    /**
     * Validace formulare pred ulozenim
     * @param tracedo Usek
     * @param poItems PO Items
     * @returns boolean TRUE / FALSE
     */
    protected validateOrderForm(tracedo: any, poItems: any): boolean
    {
        
        // Responsible person je povinna
        if(!tracedo.myPicUserId || jQuery.trim(tracedo.myPicUserId) === '') {
            this.alertDialog(i18n.t('system.alerts.emptyMyPicUser'));
            return false;
        }

        if(!tracedo.supplierPartnerId || jQuery.trim(tracedo.supplierPartnerId) === '') {
            this.alertDialog(i18n.t('system.alerts.emptySpediter'));
            return false;
        }

        // Musí být buď vybraná origin (pickup) adresa, nebo vyplněná nová
        if(!tracedo.pickupAddressId && (jQuery.trim(tracedo.originAddressCity) === '' || jQuery.trim(tracedo.originAddressZipCode) === '' || !tracedo.originAddressCountryId)) {
            this.alertDialog(i18n.t('system.alerts.invalidAddress'));
            return false;
        }

        // Musí být buď vybraná Destination (delivery) adresa, nebo vyplněná nová
        if(!tracedo.deliveryAddressId && (jQuery.trim(tracedo.destAddressCity) === '' || jQuery.trim(tracedo.destAddressZipCode) === '' || !tracedo.destAddressCountryId)) {
            this.alertDialog(i18n.t('system.alerts.invalidAddress'));
            return false;
        }

        // ETD from je povinne
        if(!tracedo.etdFrom || jQuery.trim(tracedo.etdFrom) === '') {
            this.alertDialog(i18n.t('system.alerts.emptyETD'));
            return false;
        }
        // ETA FROM je povinne
        if(!tracedo.etaFrom || jQuery.trim(tracedo.etaFrom) === '') {
            this.alertDialog(i18n.t('system.alerts.emptyETA'));
            return false;
        }

        // Kontrola na adresu u checkpointu + ETA a ETD
        for(let i in tracedo.checkpoints) {
            let cp: any = tracedo.checkpoints[i];
            if(!cp.addressbookId && (jQuery.trim(cp.addressName) === '' || jQuery.trim(cp.addressTown) === '' || jQuery.trim(cp.addressZipCode) === '' || !cp.addressCountryId)) {
                this.alertDialog(i18n.t('system.alerts.invalidCheckpointAddress'));
                return false;
            }
        }

        // Kontrola na PO Items pri zakladani useku
        if (tracedo.id === null && poItems.length > 0) {
            let poItemsOk = true;
            poItems.forEach((poItem: any) => {
                if(jQuery.trim(poItem.descr) === '') {
                    poItemsOk = false;
                }
            });
            if(!poItemsOk) {
                this.alertDialog(i18n.t('system.alerts.invalidPoItems'));
                return false;
            }
        }

        return true;
    }



	public dialogTemplate = (): HTMLElement => (

		<div>

            <view-frame name="edit-checkpoint" />

            <div data-bind="kendoTabStrip: { animation: false }">
                <ul>
                    <li class="k-state-active"><span data-bind="i18n: 'trade.demand.edit.information'"></span></li>
                    <ko if="$root.customFields().length > 0">
                        <li><span data-bind="i18n: 'common.captions.additionalInformation'"></span></li>
                    </ko>
                    <li><span data-bind="i18n: 'trade.demand.edit.orderItems'"></span></li>
                </ul>
                <div class="k-state-active">
                    <ko with="$root.tracedo">

                        <div class="row">
                            {/* Subjects */}
                            <div class="col-md-4">
                                {EditableHeaderTemplate.customerOrderParties()}
                                {EditableHeaderTemplate.customerOrderMainInfo()}
                            </div>
                            {/* Detaily pouze pro "road_container" prepravu */}
                            <div class="col-md-4">
                                {EditableHeaderTemplate.customerOrderCargoInfo()}
                            </div>
                            {/* Cargo + Driver info  -  empty for order editation */}
                            <div class="col-md-4">
                                <ko if="!($root.isRail())">
                                    {EditableHeaderTemplate.customerOrderDriverInfo()}
                                </ko>
                                <div data-bind="visible: $root.isRail() || $root.isRoadContainer()">
                                {EditableHeaderTemplate.customerOrderContainerInfo()}
                                </div>
                            </div>
                        </div>

                        {EditableCheckpointsTemplate.customerOrder()}

                    </ko>
                </div>

                {/* Custom Fields - sekce je viditelna jen tehdy, pokud mam jako subjekt nejake definovane */}
                <ko if="$root.customFields().length > 0">
                    <div>
                        <div className="row">
                            <div className="col-md-5">
                                <fieldset>
                                    <legend data-bind="i18n: 'common.captions.customFields'"></legend>
                                    {EditableCustomFields.template()}
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </ko>

                {/* zakladame novy usek => v zalozce PO Items bude formular pro vyplnovani PO Items */}
                {EditablePoItems.template()}

            </div>

		</div>

	);    
}