import { i18n } from "i18next-ko";
import { singleton } from "tsyringe";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";

@singleton()
export class TracedoManager
{

	/**
	 * RPC Client
	 */
    private rpc: Client;

    /**
     * Constructor
     * @param rpc RPC
     */
    constructor(rpc: Client)
    {
        this.rpc = rpc;
    }

	/**
     * Přerušit přepravu (nastavit stav suspend, odstraní řidiče z přepravy a odešle mu notifikaci)
     */
    public async suspend(tracedoId: number): Promise<any>
    {
        return await this.rpc.call('tracedo.suspend', { id: tracedoId });
    }

    /**
     * Zrušit přepravu (nastavit stav cancel + odešle email notifikaci)
     */
    public async cancel(tracedoId: number): Promise<any>
    {
        return await this.rpc.call('tracedo.cancel', { id: tracedoId });
    }
    
    /**
     * Generovani objednavky pozemni prepravy
     * @param tracedoId Tracedo ID
     * @param asRole 'customer' = jsem zakaznik, objednavam na dopravce NEBO 'supplier' = jsem dopravce, prijata objednavka od zakaznika
     * @param format 'pdf', 'html'
     */
    public async generateTransportOrder(tracedoId: number, asRole: string, format: string): Promise<any>
    {
        return await this.rpc.call('tracedo.generateTransportOrder', { 
            id: tracedoId,
            asRole: asRole,
            format: format
        });
    }

    /**
     * Generovani objednavky pozemni prepravy
     * @param tracedoId Tracedo ID
     * @param asRole 'customer' = jsem zakaznik, objednavam na dopravce NEBO 'supplier' = jsem dopravce, prijata objednavka od zakaznika
     */
    public async getTransportOrderHtml(tracedoId: number, asRole: string): Promise<any>
    {
        return await this.rpc.call('tracedo.generateTransportOrder', {
            id: tracedoId,
            asRole: asRole,
            format: 'html',
            returnContent: true
        });
    }

    /**
     * Konverze HTML fo PDF
     * @param html 
     * @returns 
     */
    public async saveAsPdf(html: string, fileName: string): Promise<any>
    {
        return await this.rpc.call('report.htmlToPdf', { html: html, fileName: fileName });
    }

}