import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { BaseWidget } from "../../common/BaseWidget";
import { IDestinationSettings } from "./IDestinationSettings";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { inject, injectable } from "tsyringe";
import { DestinationSettings } from "./DestinationSettings";
import { BaseConfigurableWidget } from "../../common/BaseConfigurableWidget";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { TracedoHelpers } from "../../TrackAndTrace/TracedoHelpers";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { TracedoGroupSelect } from "../../TrackAndTrace/TracedoGroupSelect";
import { TracedoView } from "../../../entities/TracedoView";
import { TracedoGroupManager } from "../../../model/TracedoGroupManager";
import {Client} from "../../../../tracejs/src/net/jsonrpc/Client";
import {CustomFieldsHelper} from "../../../model/CustomFieldsHelper";
import {TypedCheckpointsHelper} from "../../../model/TypedCheckpointsHelper";
import {CodelistManager} from "../../../model/CodelistManager";
import {TracedoManager} from "../../../model/TracedoManager";

/**
 * Destination settings
 */
@injectable()
export class Destination extends BaseConfigurableWidget
{
	/**
	 * Grid configurator ViewModel
	 */
 	protected gridConfiguratorViewModel: GridConfigurator = null;

 	/** GRID element */
 	protected gridElement: JQuery = null;
 	/** Kendo GRID */
 	protected grid: kendo.ui.Grid;
	
	// Grid Config
	protected gridConfig: kendo.ui.GridOptions;	 

 	//filtrace dle export/import
 	protected kindId: number = null;

	// Transport kinds
	private transportKinds: Array<{ kindId: number, name: string }> = [];

	// Invoicing statuses
	private invoicingStatuses: Array<{ statusId: number, ident: string, name: string }> = [];


 	// default sort
 	protected defaultSort = { field: "etaFrom", dir: "desc" };


	// Main subject ID prihlaseneho uzivatele
	protected mainSubjectId: number;

	// Max passed carriers count
	protected maxCarriers: number;

	// Tracedo group manager
	protected tracedoGroupManager: TracedoGroupManager;

	/**
	 * Open widget settings
	 */
	public async openSettings(): Promise<void>
	{
		await this.openSettingsDialog<DestinationSettings>(DestinationSettings);
	}

	/**
	 * Constructor
	 *
	 * @param rpc RPC
	 * @param customFieldsHelper
	 * @param typedCheckpointsHelper
	 * @param codelistManager
	 * @param tracedoManager
	 * @param tracedoGroupManager
	 */
	constructor(
		@inject(Client) rpc: Client,
		@inject(CustomFieldsHelper) customFieldsHelper: CustomFieldsHelper,
		@inject(TypedCheckpointsHelper) typedCheckpointsHelper: TypedCheckpointsHelper,
		@inject(CodelistManager) codelistManager: CodelistManager,
		@inject(TracedoManager) tracedoManager: TracedoManager,
		@inject(TracedoGroupManager) tracedoGroupManager: TracedoGroupManager
	) {
		super(rpc, customFieldsHelper, typedCheckpointsHelper,codelistManager, tracedoManager);
		this.customFieldsHelper = customFieldsHelper;
		this.typedCheckpointsHelper = typedCheckpointsHelper;
		this.codelistManager = codelistManager;
		this.tracedoManager = tracedoManager;
		this.tracedoGroupManager = tracedoGroupManager;
	}

	/**
	 * Kendo GRID options
	 */
	protected configureGrid(): void
	{
		// pripravit toolbar
		let toolbarConfig = TracedoHelpers.tracedoGridCreateToolbar({
			'create': true,
			'edit': true,
			'duplicate': true,
			'detail': true,
			'setDriver': true,
			'setLoad': true,
			'setDischarge': true,
			'changeInvStatus': false,
			'suspend': true,
			// 'approve': true,
			// 'decline': true,
			'sellPriceDetail': true,
			'purchasePriceDetail': true,			
			'cancel': true,
			'kind': true,
			'generateTransportOrder': true,
			'generateCMR': true
		}, this.user, this.invoicingStatuses, this.transportKinds);
		// pripravit definici sloupcu s filtrama
		let gridColumnsDef = TracedoHelpers.tracedoGridDefineColumns(this.user, this.codelistManager);
		// pridat custom fields
		this.customFieldsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShort);
		// pridat typed checkpoints
		this.typedCheckpointsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShortCapitalized);

		// Kendo grid Schema model fields
		let schemaModelFields = TracedoHelpers.tracedoGridDefineSchema();
		// pridat do neho custom fields
	 	this.customFieldsHelper.appendSchemaFieldsTo(schemaModelFields);
		// pridat do nebo typed checkpoints fields
		this.typedCheckpointsHelper.appendSchemaFieldsTo(schemaModelFields);

		var widgetId = this.id;
		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
						options.data.search = (this.grid.dataSource as any).searchText;

						// filtrovat prepravy dle import export
						options.data = KendoHelpers.filterByKindId(options.data, this.kindId, 'kindId');

						let dateFields = jQuery.extend({},
							TracedoHelpers.tracedoGridDateColumns(),
							this.typedCheckpointsHelper.getDateFields(),
							this.customFieldsHelper.getDateFields('fields_')
						);						
						TracedoHelpers.compatibilizeRequestData(options.data, '__', dateFields);

						let response: any = await this.rpc.call('tracedo.getViewByWidgetId', {
							widgetId: this.id,
							query: {
								...options.data,
								select: TracedoHelpers.getSelect() +
										this.customFieldsHelper.getCustomFieldsSelect() + 
										this.typedCheckpointsHelper.getCheckpointTypesSelect()
							}
						});
						response.data = TracedoHelpers.processResponseData(response.data);
						response.data = KendoHelpers.tracedoSetRowColor(response.data);

						// TracedoHelpers.compatibilizeResponseData(response.data);

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: schemaModelFields
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: this.defaultSort,
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: [
				// Basket (Group)
				{ template: '<span style="cursor: pointer;" class="btn btn-link text-decoration-none cursor-default d-none" data-selected-count="1"></span>' },
				{ template: '<button type="button" class="btn btn-outline-secondary d-none" data-action="addToGroup"><i class="k-icon k-i-plus"></i>' + i18n.t('common.captions.addToGroup') + '</button>' },
				{ template: '<button type="button" class="btn btn-outline-secondary d-none" data-action="removeFromGroup"><i class="k-icon k-i-minus"></i>' + i18n.t('common.captions.removeFromGroup') + '</button>' },
				{ template: '<span style="flex-basis: 100%; height: 5px; margin: 0;"></span>' },
				// other toolbar items
				...toolbarConfig],
			columns: [
				{
					template: (data: TracedoView) => `<input class="k-checkbox${data.myTracedoGroupId ? ' in-group' : ''}" type="checkbox" data-id="${data.id}">`,
					headerTemplate: '',
					width: '40px',
					title: i18n.t('common.captions.rowSelection')
				},
				...gridColumnsDef
			],
			detailInit: (e: kendo.ui.GridDetailInitEvent) => { TracedoHelpers.tracedoGridDetailInit(this.rpc, e, this.codelistManager); },
			dataBound: (e: kendo.ui.GridDataBoundEvent) => {
				TracedoHelpers.tracedoGridDataBound(this.grid, this.gridElement, e);
				this.gridElement.find('button[data-action=addToGroup]').addClass('d-none');
				this.gridElement.find('button[data-action=removeFromGroup]').addClass('d-none');
				this.gridElement.find('span[data-selected-count]').addClass('d-none');
			},
			change: (e: kendo.ui.GridChangeEvent) => { TracedoHelpers.tracedoGridSelectionChanged(this.grid, this.gridElement, this.user, this.codelistManager); }
		};
	}

	/**
	 * Reread grid
	 */
	public reload()
	{
		this.grid.dataSource.read();
	}

    /**
     * Refresh widget (after settings are saved)
     */
	public refresh()
	{
		this.reload();
	}

	/**
	 * Startup widgetu
	 */
	public async startup()
	{
		// call parent startup
		await super.startup();

	 	// Pokud nemuze pouzit widget DESTINATION
	 	if(!this.user.isAllowed('widget.destination', 'use')) {
			throw new BadRequestError('Access denied', 403);
		}
		
		// Read Typed Checkpoints
		await this.typedCheckpointsHelper.loadCheckpointTypes();
	 	// Read custom fields
	 	await this.customFieldsHelper.loadCustomFields();

		// Read codelists
		let batch = this.rpc.batch();
		batch.call('mainSubject', 'subject.getMain');
		batch.call('maxCarriers', 'tracedo.getMaxCarrier');
		let batchResult: any = await batch.run();
		this.transportKinds = this.codelistManager.getKinds();
		this.invoicingStatuses = this.codelistManager.getInvoicingStatuses();
		this.mainSubjectId = batchResult['mainSubject'].subjectId;
		this.maxCarriers = batchResult['maxCarriers'];

		this.configureGrid();
	}

	/**
	 * Render
	 */
	public async rendered()
	{
		// grid element
		this.gridElement = this.element.find('div[data-grid=destination]');
		// initialize grid and save reference
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		TracedoHelpers.tracedoGridBindActions(this, this.grid, this.gridElement, this.user, this.tracedoManager);

		// on checkbx clicked - stop propagation immediately
		this.gridElement.on('click', 'input[type=checkbox]', (event: JQuery.ClickEvent) => {
			event.stopPropagation();
			let checkedData = TracedoHelpers.getGridCheckedRows(this.grid);
			let isChecked = checkedData.length > 0;
			if(isChecked) {
				this.gridElement.find('button[data-action=addToGroup],button[data-action=removeFromGroup],span[data-selected-count]')
					.addClass('d-inline-block')
					.removeClass('d-none');
			}
			else {
				this.gridElement.find('button[data-action=addToGroup],button[data-action=removeFromGroup],span[data-selected-count]')
					.addClass('d-none')
					.removeClass('d-inline-block');
			}
			// how many selected
			this.gridElement.find('span[data-selected-count=1]').html(
				'<strong>' + (isChecked && checkedData.length > 0 ? i18n.t('common.captions.selectedCount') + ': ' + checkedData.length : '') + '</strong>'
			);
		});

		// Přidání označených přeprav do skupiny (košíku)
		this.gridElement.on('click', '[data-action=addToGroup]', (event: JQuery.ClickEvent) => {
			let checkedData = TracedoHelpers.getGridCheckedRows(this.grid);
			let checkedIds: number[] = [];
			checkedData.forEach((item: any) => {
				checkedIds.push(item.id);
			});

			this.loadViewFrame<TracedoGroupSelect>(TracedoGroupSelect, 'actionDialog', {
				selected: checkedIds,
				dialog: {
					width: 600,
					height: 450,
					modal: true,
					title: i18n.t('common.captions.addToGroup'),
					buttons: (groupSelectVm: TracedoGroupSelect, window: kendo.ui.Window) => [{
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.save'),
						click: async () => {
							await groupSelectVm.save();
							window.close();
							this.reload();
						}
					}, {
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.close'),
						click: () => {
							window.close();
						}
					}]
				}
			});
		});

		// Odebrání označených přeprav ze skupiny (košíku)
		this.gridElement.on('click', '[data-action=removeFromGroup]', async (event: JQuery.ClickEvent) => {
			let checkedData = TracedoHelpers.getGridCheckedRows(this.grid);
			let checkedIds: number[] = [];
			checkedData.forEach((item: any) => {
				checkedIds.push(item.id);
			});

			await this.tracedoGroupManager.removeFromGroup(checkedIds);
			this.reload();
		});

		// Rychlý filtr pro IMPORT/EXPORT - nastavit kindId promennou a obnovit grid
		this.gridElement.on('change', '[data-action=selectKind]', (event: JQuery.ChangeEvent) => {
			let val = jQuery(event.currentTarget).val();
			this.kindId = val !== '-' ? parseInt(val as string, 10) : null;
			this.grid.dataSource.read();
		});

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			showButtonCaptions: false,
			grid: this.grid,
			name: 'Order.TrackAndTrace-' + this.id,
			exports: [{
				name: 'XLS export',
				method: 'tracedo.xlsExport',
                params: {
                    widgetId: this.id
                }
			}]
		} as IGridConfiguratorOptions);
		this.gridConfiguratorViewModel = vm;
	}


	public template = (): HTMLElement => (
		<div>
			<view-frame name="gridconf" className="mb-2" />
			<div data-grid="destination"></div>
			<view-frame name="actionDialog" />
			<view-frame name="widgetsettings" />
		</div>
	);

}