import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";

import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { IDialogSettings } from "../common/IDialogSettings";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../model/KendoHelpers";
import { EditCustomFee } from "./EditCustomFee";
import { Fee } from "../../entities/Fee";
import { ApiKeys } from "./ApiKeys";


/**
 * Company Profile
 */
@injectable()
export class CompanyProfile extends BaseDialogViewModel<IDialogSettings>
{
	/**
	 * Main subject
	 */ 
	private subject: KnockoutObservable<any> = ko.observable(null);

	private countries: KnockoutObservableArray<any> = ko.observableArray();

    private logoUrl: KnockoutObservable<any> = ko.observable(null);

	private logoFile: KnockoutObservable<any> = ko.observable(null);


    // Calculator Fee Grid Element
    private calcFeeGridEl: JQuery<HTMLElement>;

    // Calculator Fee Grid
    private calcFeeGrid: kendo.ui.Grid;

    private tplHash: {[key:number]: any};


    private reportsHeader: KnockoutObservable<boolean> = ko.observable(false);

    private invoicingMailBody: KnockoutObservable<boolean> = ko.observable(false);

	/**
	 * Dashboard Startup
	 */
	public async startup()
	{
		await super.startup();

		let batch = this.rpc.batch();
		batch.call('subject', 'subject.getMain');
		batch.call('subjectLogo', 'subject.getMainLogo');
        batch.call('countries', 'country.get', { query: { sort: [{ field: 'descr', dir: 'asc' }] }, select: 'countryId,descr,nameEn' });

		let response: any = await batch.run();

		this.subject(ko.mapping.fromJS(response['subject']));
        this.countries(response['countries']);
        this.logoUrl(response['subjectLogo']);
	}

    /**
     * When redered
     */
    public async rendered(): Promise<any>
    {
        this.setupCalcFeeGrid();
        await this.loadViewFrame<ApiKeys>(ApiKeys, 'api-keys');
    }

    /**
     * Setup Calculator Fee Grid
     */
    public setupCalcFeeGrid()
    {
        this.calcFeeGridEl = this.element.find('.calcFeeGrid');

		// Toolbar configuration
		const toolbarConfig: kendo.ui.GridToolbarItem[] = [];
        toolbarConfig.push({ template: 
            '<button type="button" class="btn btn-outline-primary" data-action="create">' + 
                '<i class="icon-plus"></i>' + 
                '<span> ' + i18n.t('common.actions.create') + '</span>' + 
            '</button>' 
        });
        toolbarConfig.push({ template: 
            '<button type="button" class="btn btn-outline-primary" data-action="update">' + 
                '<i class="icon-edit"></i>' + 
                '<span> ' + i18n.t('common.actions.edit') + '</span>' + 
            '</button>' 
        });
        toolbarConfig.push({ template: 
            '<button type="button" class="btn btn-outline-danger" data-action="delete">' + 
                '<i class="icon-trash"></i>' + 
                '<span> ' + i18n.t('common.actions.delete') + '</span>' + 
            '</button>' 
        });

        this.calcFeeGridEl.on('click', 'button[data-action=create]', () => { this.createFee() });
        this.calcFeeGridEl.on('click', 'button[data-action=update]', () => { this.editFee(); });
        this.calcFeeGridEl.on('click', 'button[data-action=delete]', () => { this.deleteFee(); });

        this.calcFeeGridEl.on('dblclick', 'tbody tr', () => { this.editFee(); });

        this.calcFeeGrid = this.calcFeeGridEl.kendoGrid({
			autoBind: true,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

                        let batch = this.rpc.batch();
                        batch.call('fees', 'calculator.getFees', {
                            costs: null,
                            revenues: null,
							query: {
								...options.data,
								select: '*,group(*),assigns(*)'
							}
						});
                        batch.call('template', 'calculator.getTemplate');

                        let response: any = await batch.run();

                        this.tplHash = this.tplHash ?? {};
                        //console.log('before', JSON.stringify(this.tplHash), JSON.stringify(this.tplHash[21]));
                        response['template'].forEach((tpl: any) => {
                            if(!this.tplHash[tpl.feeId]) {
                                this.tplHash[tpl.feeId] = tpl;
                            }
                        });

                        //console.log('after', this.tplHash, this.tplHash[21], this.tplHash[21].defaultCosts, this.tplHash[21].defaultRevenues, this.tplHash[21].posIndex);

                        response['fees'].forEach((fee: any) => {
                            fee.inTemplateCosts = this.tplHash[fee.id] ? this.tplHash[fee.id].defaultCosts : false;
                            fee.inTemplateRevenues = this.tplHash[fee.id] ? this.tplHash[fee.id].defaultRevenues : false;
                            fee.inTemplatePosIndex = this.tplHash[fee.id] ? this.tplHash[fee.id].posIndex : 0;
                        });

						options.success(response['fees']);
					}
				},
				schema: {
					model: {
						fields: {
							'id': { editable: false, type: "number" },
                            'subjectId': { editable: false, type: "number" },
							'nameCs': { editable: false, type: "string" },
							'nameEn': { editable: false, type: "string" },
							'group.name': { editable: false, type: "string" }
						}
					}
				},
                change: (e: any) => {
                    let data = this.calcFeeGrid.dataSource.data();
                    data.forEach((item: any) => {
                        if(this.tplHash[item.id]) {
                            this.tplHash[item.id].defaultCosts = item.inTemplateCosts;
                            this.tplHash[item.id].defaultRevenues = item.inTemplateRevenues;
                            this.tplHash[item.id].posIndex = item.inTemplatePosIndex;
                        }
                    });
                },
				sort: [{ field: 'subjectId', dir: 'asc'}, { field: 'nameCs', dir: 'asc' }],
				serverPaging: false,
				serverFiltering: false,
				serverSorting: false
			},
			selectable: 'row',
			scrollable: false,
			reorderable: true,
			columnMenu: false,
			resizable: true,
			filterable: false, //KendoHelpers.getGridFilter(),
			sortable: true,
			pageable: false,
			toolbar: toolbarConfig,
            dataBound: () => {
                this.calcFeeGridEl.find('[data-action=update]').prop('disabled', true);
                this.calcFeeGridEl.find('[data-action=delete]').prop('disabled', true);

                let grd = this.calcFeeGrid;
                grd.tbody.find('tr').each(function() {
                    kendo.bind(this, grd.dataItem(this));
                });

        // // Sync do sablony pro pripadny refresh gridu po editaci/pridani custom polozky
        // this.calcFeeGridEl.on('change', 'input[type=checkbox][data-in-template-costs]', (e) => { 
        //     const $input = jQuery(e.target);
        //     const feeId = $input.data('in-template-costs');
        //     console.log('before', JSON.stringify(this.tplHash[feeId]));
        //     this.tplHash[feeId].defaultCosts = $input.is(':checked');
        //     console.log('after', JSON.stringify(this.tplHash[feeId]));
        // });
        // this.calcFeeGridEl.on('change', 'input[type=checkbox][data-in-template-revenues]', (e) => { 
        //     const $input = jQuery(e.target);
        //     const feeId = $input.data('in-template-revenues');
        //     this.tplHash[feeId].defaultRevenues = $input.is(':checked');
        // });
        // this.calcFeeGridEl.on('change', 'input[type=number][data-in-template-pos-index]', (e) => { 
        //     const $input = jQuery(e.target);
        //     const feeId = $input.data('in-template-pos-index');
        //     this.tplHash[feeId].posIndex = $input.val();
        // });

            },
            change: (arg: kendo.ui.GridChangeEvent) => {
                let selected = this.calcFeeGrid.select();
                let isSelected = selected.length > 0;
                if(isSelected) {
                    // Editovat mohu jen vlastni poplatky, systemove nikoliv
                    let one: any = this.calcFeeGrid.dataItem(selected[0]);
                    this.calcFeeGridEl.find('[data-action=update]').prop('disabled', one.subjectId ? false : true);
                    this.calcFeeGridEl.find('[data-action=delete]').prop('disabled', one.subjectId ? false : true);            
                }
                else {
                    this.calcFeeGridEl.find('[data-action=update]').prop('disabled', true);
                    this.calcFeeGridEl.find('[data-action=delete]').prop('disabled', true);
                }
            },            
            columns: [{
                field: 'subjectId',
                title: i18n.t('common.captions.customFee'),
                template: '#= subjectId ? "' + i18n.t('common.captions.yes') + '" : "' + i18n.t('common.captions.no') + '" #',
                width: 65
            }, {
                field: 'nameCs',
                title: i18n.t('common.captions.name') + ' CS'
            }, {
                field: 'nameEn',
                title: i18n.t('common.captions.name') + ' EN'
            }, {
                field: 'group.name',
                title: i18n.t('common.captions.feeGroup')
            }, {
                field: 'inTemplateCosts',
                title: i18n.t('common.captions.inTemplateCosts'),
                template: (data: any) => '<input type="checkbox" class="align-middle" data-bind="checked: inTemplateCosts" data-in-template-costs="' + data.id + '" />', // ' + (data.inTemplateCosts ? ' checked="checked"' : '') + '
                width: 65
            }, {
                field: 'inTemplateRevenues',
                title: i18n.t('common.captions.inTemplateRevenues'),
                template: (data: any) => '<input type="checkbox" class="align-middle" data-bind="checked: inTemplateRevenues" data-in-template-revenues="' + data.id + '" />', // ' + (data.inTemplateRevenues ? ' checked="checked"' : '') + '
                width: 65
            }, {
                field: 'inTemplatePosIndex',
                title: i18n.t('common.captions.inTemplatePosIndex'),
                template: (data: any) => '<input type="number" min="0" class="align-middle form-control" style="width:55px" data-bind="value: inTemplatePosIndex" data-in-template-pos-index="' + data.id + '" />', // value="' + (data.inTemplatePosIndex ? data.inTemplatePosIndex : 0) + '"
                width: 65
            }]
        }).data('kendoGrid');
    }

    /**
     * Create new custom fee
     */
    private async createFee()
    {
        return this.openFeeEditDialog(null);
    }
 
    /**
     * Edit custom fee
     */
    private async editFee()
    {
        let selected = this.calcFeeGrid.select();
        let fee: any = this.calcFeeGrid.dataItem(selected);
        if(fee.subjectId) {
            return this.openFeeEditDialog(fee.id);
        }
    }

    /**
     * Delete custom fee
     */
    private async deleteFee()
    {
        let result = await this.confirmDialog(i18n.t('common.captions.reallyDeleteFee'));
        if(result) {
            let selected = this.calcFeeGrid.select();
            let fee: any = this.calcFeeGrid.dataItem(selected);

            await this.rpc.call('calculator.deleteCustomFee', { id: fee.id });
            this.calcFeeGrid.dataSource.read();
        }
    }

    /**
     * Open Fee Edit Dialog
     * @param id 
     * @returns 
     */
    private async openFeeEditDialog(id: number = null)
    {
        return await this.loadViewFrame<EditCustomFee>(EditCustomFee, 'edit-custom-fee', {
            id: id,
            dialog: {
                width: 400,
                height: 480,
                modal: true,
                title: i18n.t('common.captions.customFee' + (id ? 'Edit' : 'Create')),
                buttons: (editVm: EditCustomFee, window: kendo.ui.Window) => {
                    return [{
                        align: 'right',
                        cls: 'btn-link',
                        label: i18n.t('common.actions.cancel'),
                        click: async () => {
                            window.close();
                        }
                    }, {
                        align: 'right',
                        cls: 'btn-primary',
                        label: i18n.t('common.actions.save'),
                        click: async () => {
                            // save
                            let saved = await editVm.save();
                            if(saved) {
                                // refresh grid
                                this.calcFeeGrid.dataSource.read();
                                // close dialog
                                window.close();
                            }
                        }
                    }];
                }
            }
        });        
    }







	/**
	 * Save profile data
	 */
	public async save(): Promise<boolean>
	{   
        // Save calculator fees (defaults / posIndex)
        let feeSettings: { feeId: number, defaultCosts: boolean, defaultRevenues: boolean, posIndex: number }[] = [];
        let feeData = this.calcFeeGrid.dataSource.data();
        feeData.forEach((item: any) => {
            feeSettings.push({
                feeId: item.id,
                defaultCosts: item.inTemplateCosts,
                defaultRevenues: item.inTemplateRevenues,
                posIndex: item.inTemplatePosIndex
            });
        });
        
        // Save main information + company logo
        let subjData = ko.mapping.toJS(this.subject);
        let logoData: string = this.logoFile();
        if(logoData) {
            let png = 'data:image/png;base64,';
            let jpg = 'data:image/jpeg;base64,';
            if(logoData.substr(0, png.length) !== png && logoData.substr(0, jpg.length) !== jpg) {
                this.alertDialog(i18n.t('system.alerts.logoMustBeJpg'));
                return false;
            }
        }

        // Call save batch
        await this.rpc.batch()
            .call('template', 'calculator.saveTemplate', { template: feeSettings })
            .call('main', 'subject.saveMain', { subject: subjData, logoData: logoData })
            .run();

		return true;
	}


    /**
     * Activate kendoEditor only if tab was activated
     * @param e 
     */
    public tabActivated(e: any)
    {
        if(jQuery(e.item).hasClass('tab-strip-reports-tab') && !this.reportsHeader()) {
            this.reportsHeader(true);
        }

        if(jQuery(e.item).hasClass('tab-strip-invoicing-tab') && !this.invoicingMailBody()) {
            this.invoicingMailBody(true);
        }
    }

    /**
     * Dialog Template
     * @returns 
     */
	public dialogTemplate = (): HTMLElement => (
		<div>

            <div data-bind="kendoTabStrip: { animation: false, activate: $root.tabActivated.bind($root) }">
                <ul>
                    <li class="k-state-active"><span data-bind="i18n: 'registration.firm'"></span></li>
                    <li class="tab-strip-reports-tab"><span data-bind="i18n: 'common.captions.reportsSettings'"></span></li>
                    <li class="tab-strip-invoicing-tab"><span data-bind="i18n: 'common.captions.invoicingSettings'"></span></li>
                    <li><span data-bind="i18n: 'common.captions.calculatorSettings'"></span></li>
                    <li><span data-bind="i18n: 'common.captions.apiKeys'"></span></li>
                </ul>
                <div class="k-state-active">


                    <ko with="$root.subject">

                        <div className="row">
                            <div className="col-md-6">

                                <h2 data-bind="i18n: 'registration.firm'"></h2>

                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmIco', i18n: 'registration.firmIco'"></label>
                                    <div className="col-md-9">
                                        <input type="text" disabled="disabled" className="form-control" data-bind="value: ic, uniqueId: 'firmIco'" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmName', i18n: 'registration.firmName'"></label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" data-bind="value: name, uniqueId: 'firmName'" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmDic', i18n: 'registration.firmDic'"></label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" data-bind="value: dic, uniqueId: 'firmDic'" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmStreet', i18n: 'registration.firmStreet'"></label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" data-bind="value: address1, uniqueId: 'firmStreet'" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmCity', i18n: 'registration.firmCity'"></label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" data-bind="value: city, uniqueId: 'firmCity'" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmZipCode', i18n: 'registration.firmZipCode'"></label>
                                    <div className="col-md-9">
                                        <input type="text" className="form-control" data-bind="value: postalCode, uniqueId: 'firmZipCode'" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmCountry', i18n: 'registration.firmCountry'"></label>
                                    <div className="col-md-9">
                                        <input type="text" className="w-100" data-bind="
                                            uniqueId: 'firmCountry',
                                            kendoDropDownList: {
                                                value: countryId, 
                                                placeholder: i18n.t('common.captions.chooseState'),
                                                dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
                                                dataValueField: 'countryId',
                                                filter: 'contains',
                                                minLength: 0,
                                                data: $root.countries()
                                            }" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'firmTradeCurrency', i18n: 'registration.firmTradeCurrency'"></label>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" data-bind="
                                            uniqueId: 'firmTradeCurrency',
                                            kendoNumericTextBox: {
                                                value: tradeCurrency,
                                                decimals: 2
                                            }"/>
                                    </div>
                                    <div className="col-md-1 p-0">
                                        <i data-bind="kendoTooltip: { content: i18n.t('registration.firmTradeCurrencyTipText') }" className="icon-help-circled" style="font-size:20px;line-height:25px;"></i>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-6">

                                <h2 data-bind="i18n: 'system.user.profile.logo'"></h2>

                                <div className="row mb-2">
                                    <span data-bind="i18n: 'system.user.profile.logoSize'"></span>
                                </div>

                                <div className="row mb-2">
                                    <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'logoFile'">Logo:</label>
                                    <div className="col-md-9">
                                        <input type="file" className="form-control" data-bind="fileSrc: $root.logoFile, uniqueId: 'logoFile'" />
                                        <ko if="$root.logoFile">
                                            <div class="mt-2">
                                                <img data-bind="attr: { src: $root.logoFile, alt: 'logo' }" className="img-fluid" />
                                            </div>
                                        </ko>
                                    </div>
                                </div>

                                <ko if="$root.logoUrl()">
                                    <div className="row mb-2">
                                        <label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'logoFile', i18n: 'system.user.profile.downloadLogo'"></label>
                                        <div className="col-md-9">
                                            <a data-bind="attr: { href: $root.logoUrl, title: i18n.t('system.user.profile.downloadLogo') }">
                                                <img data-bind="attr: { src: $root.logoUrl(), alt: 'logo' }" className="img-fluid" />
                                            </a>
                                        </div>
                                    </div>
                                </ko>

                            </div>
                        </div>

                    </ko>

                </div>
                <div>

                    <ko if="$root.reportsHeader">
                        <ko with="$root.subject">

                            <h2 data-bind="uniqueFor: 'reportsHeader', i18n: 'common.captions.reportsHeader'"></h2>

                            <textarea className="form-control" data-bind="kendoEditor: {
                                value: reportsHeader,
                                tools: ['bold', 'italic','cleanFormatting','viewHtml'] },
                                uniqueId: 'reportsHeader'
                            " />
                        </ko>
                    </ko>

                </div>
                <div>

                    <ko if="$root.invoicingMailBody">
                        <ko with="$root.subject">

                            <label className="col-form-label" data-bind="uniqueFor: 'invoicingEmailRecipient', i18n: 'common.captions.invoicingEmailRecipient'"></label>
                            <div className="mb-2">
                                <input type="text" className="form-control" data-bind="value: invoicingEmailRecipient, uniqueId: 'invoicingEmailRecipient'" />
                            </div>

                            <label className="col-form-label" data-bind="uniqueFor: 'invoicingEmailSubject', i18n: 'common.captions.invoicingEmailSubject'"></label>
                            <div className="mb-2">
                                <input type="text" className="form-control" data-bind="value: invoicingEmailSubject, uniqueId: 'invoicingEmailSubject'" />
                            </div>

                            <label className="col-form-label" data-bind="uniqueFor: 'invoicingEmailBody', i18n: 'common.captions.invoicingEmailBody'"></label>
                            <div className="mb-2">
                                <textarea className="form-control" data-bind="kendoEditor: {
                                    value: invoicingEmailBody,
                                    tools: ['bold', 'italic','cleanFormatting','viewHtml'] },
                                    uniqueId: 'invoicingEmailBody'
                                " />
                            </div>

                            <div data-bind="i18n: 'common.captions.invoicingInfo'"></div>
                        </ko>
                    </ko>

                </div>                
                <div>

                    <h2 data-bind="i18n: 'common.captions.calculatorFees'"></h2>

                    <div className="calcFeeGrid nodirtyflag"></div>

                    <view-frame name="edit-custom-fee"></view-frame>
                    
                </div>
                <div>

                    <h2 data-bind="i18n: 'common.captions.apiKeys'"></h2>

                    <view-frame name="api-keys"></view-frame>

                </div>

            </div>

		</div>
	);
	
} 