import {i18n} from "i18next-ko";
import {inject, injectable} from "tsyringe";
import {Client} from "../../../tracejs/src/net/jsonrpc/Client";
import {h} from "../../../tracejs/src/utils/JSXFactory";
import {Addressbook} from "../../entities/Addressbook";
import {Checkpoint} from "../../entities/Checkpoint";
import {CheckpointType} from "../../entities/CheckpointType";
import {ColliType} from "../../entities/ColliType";
import {Country} from "../../entities/Country";
import {PoItem} from "../../entities/PoItem";
import {PoItemConnection} from "../../entities/PoItemConnection";
import {CodelistManager} from "../../model/CodelistManager";
import {BaseDialogViewModel} from "../common/BaseDialogViewModel";
import {IDialogSettings} from "../common/IDialogSettings";
import {AddressbookRamp} from "../../entities/AddressbookRamp";


export interface EditCheckpointSettings extends IDialogSettings {
    /**
     * ID useku, pokud je k dispozici
     */
    tracedoId?: number;

    /**
     * checkpoint, origin, destination
     */
    editType: string;

    checkpoint: MappedType<Checkpoint>;

    countries?: Country[];
    checkpointTypes?: CheckpointType[];

    poItems?: KnockoutObservableArray<MappedType<PoItem>>;
}

/**
 * Tracedo checkpoint edit
 */
@injectable()
export class EditCheckpoint extends BaseDialogViewModel<EditCheckpointSettings> {
    // Codelist manager
    private codelistManager: CodelistManager;

    // nastaveni dropdownu pro vyber adresy
    private addressComboConfig: kendo.ui.DropDownListOptions;

    // nastaveni dropdownu pro vyber kontaktni osoby (kaskada na adresu)
    private contactComboConfig: kendo.ui.DropDownListOptions;


    /**
     * Ciselnik typu baleni
     */
    private colliTypes: KnockoutObservableArray<ColliType> = ko.observableArray([]);

    /**
     * Ciselnik countries pro adresu
     */
    private countries: KnockoutObservableArray<Country> = ko.observableArray([]);

    /**
     * Ciselnik typu checkpointu
     */
    private checkpointTypes: KnockoutObservableArray<CheckpointType> = ko.observableArray([]);


    /**
     * Dostupne PO Items
     */
    private availPoItems: KnockoutObservableArray<MappedType<PoItem>> = ko.observableArray([]);


    private initialAddressLoaded: boolean = false;

    private initialContactLoaded: boolean = false;

    private ramps: KnockoutObservableArray<AddressbookRamp> = ko.observableArray(null);


    /**
     * Constructor
     *
     * @param rpc RPC Client
     * @param codelistManager CodelistManager
     */
    constructor(@inject(Client) rpc: Client, @inject(CodelistManager) codelistManager: CodelistManager) {
        super(rpc);
        this.codelistManager = codelistManager;
    }


    /**
     * Startup
     */
    public async startup(): Promise<any> {
        await super.startup();

        // pokud mame predane po items, nebudeme je nacitat (zakladani useku), jinak si je nacteme
        if (this.settings.poItems) {
            this.availPoItems = this.settings.poItems;
        } else {
            await this.readPoItems();
        }

        // pokud mame predany codelist countries, nebudeme jej nacitat, jinak ho nacteme
        if (this.settings.countries) {
            this.countries = ko.observableArray(this.settings.countries);
        } else {
            await this.readCountries();
        }

        // pokud mame predany codelist typu checkpointu, nebudeme jej nacitat, jinak ho nacteme
        if (this.settings.checkpointTypes) {
            this.checkpointTypes = ko.observableArray(this.settings.checkpointTypes);
        } else {
            await this.readCheckpointTypes();
        }

        this.colliTypes(this.codelistManager.getColliTypes());

        this.setupCombos();
    }

    /**
     * initial Ramps into select by addressbookId
     */
    private async initRamps(addressbookId: number) {    // 5117
        if (this.settings.checkpoint && !this.settings.checkpoint.rampId) {
            this.settings.checkpoint.rampId(null);
        }
        let ramps: any = await this.rpc.call('addressbook.getRampsByAddressBookId', {
            id: addressbookId
        });
        this.ramps(ramps);
    }

    /**
     * find the ramp name and setup name/id or remove them while ramp combobox is changed
     */
    private async setRamps(isAddressbookChanged: boolean = false) {

        this.settings.checkpoint.rampId.subscribe((newRampId: number) => {
            let foundRamp: AddressbookRamp = this.ramps().find(r => r.id == newRampId);
            if (foundRamp) {
                this.settings.checkpoint.ramp.id(foundRamp.id);
                this.settings.checkpoint.rampId(foundRamp.id);
                this.settings.checkpoint.ramp.name(foundRamp.name);
                this.settings.checkpoint.rampName(foundRamp.name);
            } else {
                this.settings.checkpoint.ramp.id(null);
                this.settings.checkpoint.rampId(null)
                this.settings.checkpoint.ramp.name(null);
                this.settings.checkpoint.rampName(null)
            }
        });
        if (isAddressbookChanged) {
            this.settings.checkpoint.ramp.id(null);
            this.settings.checkpoint.rampId(null);
            this.settings.checkpoint.ramp.name(null);
            this.settings.checkpoint.rampName(null);
        }
    }

    /**
     * Read countries codelist
     */
    private async readCountries() {
        let countries: any[] = await this.rpc.call('country.get', {
            query: {
                select: 'countryId,descr,nameEn',
                sort: [{field: 'descr', dir: 'asc'}]
            }
        });
        // translation
        this.countries.removeAll();
        countries.forEach((country: any) => {
            country.nameTranslated = this.culture.localeShort == 'cs' ? country.descr : country.nameEn
            this.countries.push(country);
        });
    }

    /**
     * Read checkpoint types codelist
     */
    private async readCheckpointTypes() {
        let checkpointTypes: CheckpointType[] = await this.rpc.call('tracedo.getCheckpointTypes', {
            query: {
                select: 'id,ident,nameCs,nameEn',
                soft: [{field: 'nameCs', dir: 'asc'}]
            }
        });
        // translation
        this.checkpointTypes.removeAll();
        checkpointTypes.forEach((type: any) => {
            type.nameTranslated = this.culture.localeShort == 'cs' ? type.nameCs : type.nameEn
            this.checkpointTypes.push(type);
        });
    }

    /**
     * Read available Po Items for currently editing Tracedo
     */
    private async readPoItems() {
        let poItems: PoItem[] = await this.rpc.call('poItem.getByTracedoId', {
            tracedoId: this.settings.tracedoId,
            query: {
                pageSize: null,
                page: null,
                select: '*',
                sort: [{field: 'createdTimestamp', dir: 'asc'}]
            }
        });
        this.availPoItems.removeAll();
        poItems.forEach((poItem: PoItem) => {
            poItem.length = poItem.length ? poItem.length * 100 : null;
            poItem.width = poItem.width ? poItem.width * 100 : null;
            poItem.height = poItem.height ? poItem.height * 100 : null;

            this.availPoItems.push(ko.mapping.fromJS(poItem));
        });
    }

    /**
     * Konfigurace comboboxu
     */
    private setupCombos(): void {
        // naseptavac pro adresy
        this.addressComboConfig = {
            optionLabel: i18n.t('trackAndTrace.edit.selectAddress'),
            dataTextField: 'info',
            dataValueField: 'addressbookId',
            filter: 'contains',
            autoBind: true,
            minLength: 0,
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: async (options: kendo.data.DataSourceTransportReadOptions) => {

                        let searchedText = options.data.filter && options.data.filter.filters && options.data.filter.filters[0] ?
                            (options.data.filter.filters[0] as any).value :
                            '';

                        let batch = this.rpc.batch();

                        // pocatecni nacteni nastavene adresy
                        if (this.settings.checkpoint.addressbookId() && !this.initialAddressLoaded) {
                            batch.call('selected', 'addressbook.getOne', {
                                id: this.settings.checkpoint.addressbookId(),
                                deleted: true,
                                query: {select: '*,country(iso)'}
                            });
                            this.initialAddressLoaded = true;
                        }

                        batch.call('addresses', 'addressbook.get', {
                            query: {
                                search: searchedText,
                                select: '*,country(iso)',
                                page: 1,
                                pageSize: 100,
                                sort: [{field: 'name', dir: 'asc'}]
                            }
                        });

                        let response: any = await batch.run();
                        let addresses: Addressbook[] = response['addresses'].data;

                        // Pokud se selected neobjevil v datovem zdroji (strakovani / deleted), doplnime ho na konec
                        if (response['selected']) {
                            let selected: Addressbook = response['selected'];
                            let isSelectedThere = addresses.filter(a => a.addressbookId == selected.addressbookId).length > 0;
                            if (!isSelectedThere) {
                                addresses.push(selected);
                            }
                        }

                        addresses.forEach((addr: Addressbook) => {
                            (addr as any).info = addr.name +
                                (addr.town ? ', ' + addr.town : '') +
                                (addr.zipCode ? ', ' + addr.zipCode : '') +
                                (addr.countryId ? ', ' + addr.country.iso : '');
                        });

                        options.success(addresses);
                    }
                }
            },
            dataBound: (e: kendo.ui.DropDownListDataBoundEvent) => {
                let selectedObject = e.sender.dataItem(e.sender.select());
                if (selectedObject.addressbookId !== '') {
                    this.setAddress(selectedObject, false);
                    this.initRamps(selectedObject.addressbookId);
                    this.setRamps();
                }
            },
            // cascade: (e: kendo.ui.DropDownListCascadeEvent) => {
            //     let selectedObject = e.sender.dataItem(e.sender.select());
            //     this.setAddress(selectedObject, false);
            // },            
            change: (e: kendo.ui.DropDownListChangeEvent) => {
                let selectedObject = e.sender.dataItem(e.sender.select());
                this.setAddress(selectedObject, true);
                if (selectedObject.addressbookId !== '') {
                this.initRamps(selectedObject.addressbookId);
                this.setRamps(true);
                }else{
                    this.ramps([]);
                }
            }
        };

        this.contactComboConfig = {
            cascadeFrom: 'checkpointAddressbookId',
            optionLabel: i18n.t('trackAndTrace.edit.selectContactPerson'),
            dataTextField: 'fullName',
            dataValueField: 'contactId',
            filter: 'contains',
            autoBind: true,
            minLength: 2,
            dataSource: {
                serverFiltering: true,
                transport: {
                    read: async (options: kendo.data.DataSourceTransportReadOptions) => {

                        // pokud neni vybrana adresa, vratime prazdne kontaktni osoby
                        if (!this.settings.checkpoint.addressbookId()) {
                            options.success([]);
                            return;
                        }

                        // Pokud neni zafiltrovano, nevratime nic
                        if (!options.data.filter || !options.data.filter.filters || !options.data.filter.filters[0]) {
                            options.success([]);
                            return;
                        }

                        let searchText = null;
                        let addressId = null;
                        options.data.filter.filters.forEach((flt: any) => {
                            if (flt.field == 'fullName') {
                                searchText = flt.value;
                            }
                            if (flt.field == 'addressbookId') {
                                addressId = flt.value;
                            }
                        });

                        // pocatecni nacteni nastavene osoby
                        let fltr = undefined;
                        if (this.settings.checkpoint.contactId() && !this.initialContactLoaded) {
                            fltr = {
                                logic: 'and',
                                filters: [{
                                    field: 'contactId',
                                    operator: 'eq',
                                    value: this.settings.checkpoint.contactId()
                                }]
                            };
                            this.initialContactLoaded = true;
                        }


                        let contacts: any = await this.rpc.call('contact.getByAddress', {
                            addressId: addressId,
                            query: {
                                filter: fltr,
                                search: searchText,
                                select: 'contactId,firstName,lastName,fullName,email,mobile',
                                page: 1,
                                pageSize: 50,
                                take: 50,
                                skip: 0,
                                sort: [{field: 'lastName', dir: 'asc'}],
                            }
                        });

                        contacts.data.forEach((c: any) => {
                            c.info = c.lastName + ' ' + c.firstName +
                                (c.mobile ? ', ' + c.mobile : '') +
                                (c.email ? ', ' + c.email : '')
                        })

                        options.success(contacts.data);
                    }
                }
            },
            dataBound: (e: kendo.ui.DropDownListDataBoundEvent) => {
                let selectedObject = e.sender.dataItem(e.sender.select());
                this.setContact(selectedObject);
            },
            // cascade: (e: kendo.ui.DropDownListCascadeEvent) => {
            //     let selectedObject = e.sender.dataItem(e.sender.select());
            //     this.setContact(selectedObject);
            // },
            change: (e: kendo.ui.DropDownListChangeEvent) => {
                let selectedObject = e.sender.dataItem(e.sender.select());
                this.setContact(selectedObject);
            }
        };

    }

    private setAddress(address: Addressbook, clearContacts: boolean): void {
        // Sanitize addressbook ID
        let id = Number(this.settings.checkpoint.addressbookId());
        this.settings.checkpoint.addressbookId(isNaN(id) || id == 0 ? null : id);

        // Odebrat kontaktni osobu pri zmene adresy
        if (clearContacts) {
            this.settings.checkpoint.contactId(null);
        }

        // pokud je vybrana nejaka adresa (ID je cislo), tak vyplnime ostatni pole adresy (budou zasedle)
        if (this.settings.checkpoint.addressbookId()) {
            this.settings.checkpoint.addressName(address.name);
            this.settings.checkpoint.addressStreet(address.street);
            this.settings.checkpoint.addressStreet2(address.street2);
            this.settings.checkpoint.addressTown(address.town);
            this.settings.checkpoint.addressZipCode(address.zipCode);
            this.settings.checkpoint.addressCountryId(address.countryId);
            // Pokud je defaultni poznamka a zaroven jeste neni napsana nova poznamka
            if (address.defaultNote !== null && address.defaultNote.trim() !== '' && (this.settings.checkpoint.note() === null || this.settings.checkpoint.note().trim() === '')) {
                this.settings.checkpoint.note(address.defaultNote);
            }
        } else {
            if (this.initialAddressLoaded) {
                this.settings.checkpoint.addressName('');
                this.settings.checkpoint.addressStreet('');
                this.settings.checkpoint.addressStreet2('');
                this.settings.checkpoint.addressTown('');
                this.settings.checkpoint.addressZipCode('');
                this.settings.checkpoint.addressCountryId(null);
                this.settings.checkpoint.note('');
            } else {
                this.initialAddressLoaded = true;
            }
        }
    }

    private setContact(contact: any): void {
        // Sanitize contact ID
        let id = Number(this.settings.checkpoint.contactId());
        this.settings.checkpoint.contactId(isNaN(id) || id == 0 ? null : id);

        // pokud je vybrana nejaka adresa (ID je cislo), tak vyplnime ostatni pole adresy (budou zasedle)
        if (this.settings.checkpoint.contactId()) {
            this.settings.checkpoint.contactFirstName(contact.firstName);
            this.settings.checkpoint.contactLastName(contact.lastName);
            this.settings.checkpoint.contactPhone(contact.mobile);
            this.settings.checkpoint.contactEmail(contact.email);
        } else {
            if (this.initialContactLoaded) {
                this.settings.checkpoint.contactFirstName('');
                this.settings.checkpoint.contactLastName('');
                this.settings.checkpoint.contactPhone('');
                this.settings.checkpoint.contactEmail('');
            } else {
                this.initialContactLoaded = true;
            }
        }
    }

    /**
     * Validace dat
     */
    public validate(): boolean {
        let cp = this.settings.checkpoint;
        // Pokud se zada telefon nebo Email, je vyzadovano alespon prijmeni
        if ((cp.contactPhone() !== null && cp.contactPhone().trim() !== '') || (cp.contactEmail() !== null && cp.contactEmail().trim() !== '')) {
            if (cp.contactLastName() === null || cp.contactLastName().trim() === '') {
                this.alertDialog(i18n.t('common.captions.enterAtLeastLastName'));
                return false;
            }
        }
        return true;
    }

    /**
     * Sablona dialogu
     */
    public dialogTemplate = (): HTMLElement => (

        <div>
            <ko with="$root.settings.checkpoint">

                <div data-bind="kendoTabStrip: { animation: false }">
                    <ul>
                        <li class="k-state-active"><span data-bind="i18n: 'trade.demand.edit.information'"></span></li>
                        <li data-bind="i18n: 'common.captions.poItems'"></li>
                    </ul>
                    <div class="k-state-active">

                        <ko if="$root.settings.editType === 'checkpoint'">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'typeId', i18n: 'common.captions.type'"></label>
                                <div className="col-md-10">
                                    <select data-bind="
                                        uniqueId: 'typeId', 
                                        options: $root.checkpointTypes,
                                        optionsText: 'nameTranslated',
                                        optionsValue: 'id',
                                        optionsCaption: i18n.t('common.captions.customValue'),
                                        value: typeId
                                    " className="form-select"></select>
                                </div>
                            </div>
                        </ko>

                        <ko if="$root.settings.editType === 'checkpoint' && !typeId()">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'name', i18n: 'common.captions.name'"></label>
                                <div className="col-md-10">
                                    <input type="text"
                                           data-bind="uniqueId: 'name', value: name, disable: $root.settings.disableName"
                                           className="form-control"/>
                                </div>
                            </div>
                        </ko>

                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label" for="checkpointAddressbookId"
                                   data-bind="i18n: 'common.captions.address'"></label>
                            <div className="col-md-10">
                                <input type="text" className="w-100" id="checkpointAddressbookId"
                                       data-bind="value: addressbookId, kendoDropDownList: $root.addressComboConfig"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'addressName', i18n: 'trade.demand.edit.addressName'"></label>
                            <div className="col-md-10">
                                <input type="text"
                                       data-bind="uniqueId: 'addressName', value: addressName, disable: addressbookId() !== null"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'addressStreet', i18n: 'trade.demand.edit.addressStreet'"></label>
                            <div className="col-md-10">
                                <input type="text"
                                       data-bind="uniqueId: 'addressStreet', value: addressStreet, disable: addressbookId() !== null"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'addressStreet2', i18n: 'trade.demand.edit.addressStreet2'"></label>
                            <div className="col-md-10">
                                <input type="text"
                                       data-bind="uniqueId: 'addressStreet2', value: addressStreet2, disable: addressbookId() !== null"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'addressCity', i18n: 'trade.demand.edit.addressCity'"></label>
                            <div className="col-md-6">
                                <input type="text"
                                       data-bind="uniqueId: 'addressCity', value: addressTown, disable: addressbookId() !== null"
                                       className="form-control"/>
                            </div>
                            <label className="inline col-md-1 col-form-label"
                                   data-bind="uniqueFor: 'addressZipCode', i18n: 'trade.demand.edit.addressZipCode'"></label>
                            <div className="col-md-3">
                                <input type="text"
                                       data-bind="uniqueId: 'addressZipCode', value: addressZipCode, disable: addressbookId() !== null"
                                       className="form-control"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'addressCountryId', i18n: 'trade.demand.edit.addressCountry'"></label>
                            <div className="col-md-10">
                                <select data-bind="
                                    disable: addressbookId() !== null,
                                    uniqueId: 'addressCountryId', 
                                    options: $root.countries,
                                    optionsText: 'nameTranslated',
                                    optionsValue: 'countryId',
                                    optionsCaption: '-',
                                    value: addressCountryId
                                " className="form-select"></select>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'contactId', i18n: 'common.captions.contactPerson'"></label>
                            <div className="col-md-10">
                                <input type="text" className="w-100"
                                       data-bind="uniqueId: 'contactId', value: contactId, kendoDropDownList: $root.contactComboConfig"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'contactFirstName', i18n: 'registration.firstName'"></label>
                            <div className="col-md-4">
                                <input type="text" className="form-control"
                                       data-bind="uniqueId: 'contactFirstName', value: contactFirstName, disable: contactId() !== null"/>
                            </div>
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'contactLastName', i18n: 'registration.lastName'"></label>
                            <div className="col-md-4">
                                <input type="text" className="form-control"
                                       data-bind="uniqueId: 'contactLastName', value: contactLastName, disable: contactId() !== null"/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'contactPhone', i18n: 'registration.phone'"></label>
                            <div className="col-md-4">
                                <input type="text" className="form-control"
                                       data-bind="uniqueId: 'contactPhone', value: contactPhone, disable: contactId() !== null"/>
                            </div>
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'contactEmail', i18n: 'registration.email'"></label>
                            <div className="col-md-4">
                                <input type="email" className="form-control"
                                       data-bind="uniqueId: 'contactEmail', value: contactEmail, disable: contactId() !== null"/>
                            </div>
                        </div>
                        <ko if="$root.ramps().length > 0">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'rampId', i18n: 'common.captions.ramp'"></label>
                                <div className="col-md-10">
                                    <select data-bind="
                                    uniqueId: 'rampId',
                                    options: $root.ramps,
                                    optionsText: 'name',
                                    optionsValue: 'id',
                                    optionsCaption: '-',
                                    value: rampId
                                " className="form-select"></select>
                                </div>
                            </div>
                        </ko>

                        {/* Origin - editace etdFrom - etdTo, pak atdReady + atd */}
                        <ko if="$root.settings.editType === 'origin'">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'etdFrom'">
                                    <span data-bind="i18n: 'common.captions.etd'"></span>&nbsp;<span
                                    data-bind="i18n: 'common.captions.from'"></span>
                                </label>
                                <div className="col-md-4">
                                    <input type="text"
                                           data-bind="uniqueId: 'etdFrom', kendoDateTimePicker: { value: etdFrom }"
                                           className="w-100"/>
                                </div>
                                <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'etdTo'">
                                    <span data-bind="i18n: 'common.captions.etd'"></span>&nbsp;<span
                                    data-bind="i18n: 'common.captions.to'"></span>
                                </label>
                                <div className="col-md-4">
                                    <input type="text"
                                           data-bind="uniqueId: 'etdTo', kendoDateTimePicker: { value: etdTo }"
                                           className="w-100"/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'ata', i18n: 'common.captions.atdReady'"></label>
                                <div className="col-md-4">
                                    <input type="text"
                                           data-bind="uniqueId: 'atdReady', kendoDateTimePicker: { value: atdReady }"
                                           className="w-100"/>
                                </div>
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'atd', i18n: 'common.captions.atd'"></label>
                                <div className="col-md-4">
                                    <input type="text" data-bind="uniqueId: 'atd', kendoDateTimePicker: { value: atd }"
                                           className="w-100"/>
                                </div>
                            </div>
                        </ko>

                        {/* Destination - editace etaFrom - etaTo, pak ataReady + ata */}
                        <ko if="$root.settings.editType === 'destination'">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'etaFrom'">
                                    <span data-bind="i18n: 'common.captions.eta'"></span>&nbsp;<span
                                    data-bind="i18n: 'common.captions.from'"></span>
                                </label>
                                <div className="col-md-4">
                                    <input type="text"
                                           data-bind="uniqueId: 'etaFrom', kendoDateTimePicker: { value: etaFrom }"
                                           className="w-100"/>
                                </div>
                                <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'etaTo'">
                                    <span data-bind="i18n: 'common.captions.eta'"></span>&nbsp;<span
                                    data-bind="i18n: 'common.captions.to'"></span>
                                </label>
                                <div className="col-md-4">
                                    <input type="text"
                                           data-bind="uniqueId: 'etaTo', kendoDateTimePicker: { value: etaTo }"
                                           className="w-100"/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'ata', i18n: 'common.captions.ataReady'"></label>
                                <div className="col-md-4">
                                    <input type="text"
                                           data-bind="uniqueId: 'ataReady', kendoDateTimePicker: { value: ataReady }"
                                           className="w-100"/>
                                </div>
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'ata', i18n: 'common.captions.ata'"></label>
                                <div className="col-md-4">
                                    <input type="text" data-bind="uniqueId: 'ata', kendoDateTimePicker: { value: ata }"
                                           className="w-100"/>
                                </div>
                            </div>
                        </ko>

                        {/* Checkpoint = libovolna mezizastavka => editace eta/etd a nasledne ata/atd */}
                        <ko if="$root.settings.editType === 'checkpoint'">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'eta', i18n: 'common.captions.eta'"></label>
                                <div className="col-md-4">
                                    <input type="text" data-bind="uniqueId: 'eta', kendoDateTimePicker: { value: eta }"
                                           className="w-100"/>
                                </div>
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'etd', i18n: 'common.captions.etd'"></label>
                                <div className="col-md-4">
                                    <input type="text" data-bind="uniqueId: 'etd', kendoDateTimePicker: { value: etd }"
                                           className="w-100"/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'ata', i18n: 'common.captions.ata'"></label>
                                <div className="col-md-4">
                                    <input type="text" data-bind="uniqueId: 'ata', kendoDateTimePicker: { value: ata }"
                                           className="w-100"/>
                                </div>
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'atd', i18n: 'common.captions.atd'"></label>
                                <div className="col-md-4">
                                    <input type="text" data-bind="uniqueId: 'atd', kendoDateTimePicker: { value: atd }"
                                           className="w-100"/>
                                </div>
                            </div>
                        </ko>


                        <div className="row mb-2">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'reference', i18n: 'common.captions.reference'"></label>
                            <div className="col-md-10">
                                <input type="text" data-bind="uniqueId: 'reference', value: reference"
                                       className="form-control"/>
                            </div>
                        </div>

                        <div className="row">
                            <label className="inline col-md-2 col-form-label"
                                   data-bind="uniqueFor: 'note', i18n: 'common.captions.remark'"></label>
                            <div className="col-md-10">
                                <textarea type="text" data-bind="uniqueId: 'note', value: note" style="height: 100px"
                                          className="form-control"></textarea>
                            </div>
                        </div>

                    </div>
                    <div>

                        <ko if="$root.availPoItems().length <= 0">
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'colli', i18n: 'common.captions.colli'"></label>
                                <div className="col-md-10">
                                    <input type="number" data-bind="uniqueId: 'colli', value: colli"
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'kgs', i18n: 'common.captions.kgs'"></label>
                                <div className="col-md-10">
                                    <input type="number" data-bind="uniqueId: 'kgs', value: kgs"
                                           className="form-control"/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <label className="inline col-md-2 col-form-label"
                                       data-bind="uniqueFor: 'colliTypeId', i18n: 'edit.poItem.packaging'"></label>
                                <div className="col-md-10">
                                    <select className="form-select"
                                            data-bind="uniqueId: 'colliTypeId', value: colliTypeId, options: $root.colliTypes, optionsText: 'name', optionsValue: 'colliTypeId', optionsCaption: ''"></select>
                                </div>
                            </div>
                        </ko>

                        <ko if="$root.availPoItems().length > 0">
                            <table className="table table-sm avail-po-items-table">
                                <thead>
                                <tr>
                                    <th style="width:25px"></th>
                                    <th>PN1</th>
                                    <th style="width:60px">Colli</th>
                                    <th style="width:80px"><span data-bind="i18n: 'edit.poItem.width'"></span></th>
                                    <th style="width:80px"><span data-bind="i18n: 'edit.poItem.height'"></span></th>
                                    <th style="width:80px"><span data-bind="i18n: 'edit.poItem.length'"></span></th>
                                    <th style="width:120px"><span data-bind="i18n: 'edit.poItem.weight'"></span></th>
                                </tr>
                                </thead>
                                <tbody data-bind="foreach: $root.availPoItems">
                                <tr>
                                    <td><input type="checkbox"
                                               data-bind="value: $data.id, checked: $root.settings.checkpoint.poItems"/>
                                    </td>
                                    <td><span data-bind="text: $data.pn1"></span></td>
                                    <td><span data-bind="text: $data.colli"></span></td>
                                    <td><span data-bind="text: $data.width"></span></td>
                                    <td><span data-bind="text: $data.height"></span></td>
                                    <td><span data-bind="text: $data.length"></span></td>
                                    <td><span data-bind="text: $data.weight"></span></td>
                                </tr>
                                </tbody>
                            </table>
                        </ko>

                    </div>
                </div>
            </ko>
        </div>

    );

}