import { i18n } from "i18next-ko";

import { BaseViewModel } from "../common/BaseViewModel";

import { Edit } from "./EditAndCreateTransport/Edit";
import { SetDriver } from "./SetDriver";
import { Approve } from "./Approve";
import { Decline } from "./Decline";
import { Load } from "./Load";
import { Discharge } from "./Discharge";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { User } from "../../../tracejs/src/security/User";
import { KendoHelpers } from "../../model/KendoHelpers";
import { ViewModel } from "../../../tracejs/src/application/ViewModel";
import { CodelistManager } from "../../model/CodelistManager";
import { TracedoManager } from "../../model/TracedoManager";
import { Fields } from "../Settings/Fields/Fields";
import moment from "moment";
import { HtmlEditor } from "../components/HtmlEditor/HtmlEditor";
import { CreateOrder } from "./CreateOrder";
import { Pass } from "./Pass";
import { TracedoView } from "../../entities/TracedoView";
import { PassStatus } from "../../entities/PassStatus";
import { PriceLog } from "./PriceLog";
import { Tracedo } from "../../entities/Tracedo";
import { GenerateCMR } from "./GenerateCMR";
// import { Detail } from "./Detail";

/**
 * Helpers for Tracedo actions (dialogs)
 */
export class TracedoHelpers
{
	public static async validation(){
		console.log("Used function from TracedoHelpers");
	}
	/**
     * Open dialog for Tracedo create
     */
	public static async createOrder(vm: BaseViewModel<any>, viewFrame: string): Promise<CreateOrder>
	{
		return TracedoHelpers.openOrderDialog(vm, viewFrame);
	}

	public static async duplicateOrder(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView): Promise<CreateOrder>
	{
		return TracedoHelpers.openOrderDialog(vm, viewFrame, tracedo, true);
	}	

	/**
     * Open dialog for Tracedo create
     */
	public static async createTracedo(vm: BaseViewModel<any>, viewFrame: string, user: User): Promise<Edit>
    {
		return TracedoHelpers.openTracedoDialog(vm, viewFrame, user);
	}

	/**
	 * Open dialog for Tracedo edit
	 */
	public static async editTracedo(vm: BaseViewModel<any>, viewFrame: string, user: User, tracedo: TracedoView): Promise<Edit>
	{
		return TracedoHelpers.openTracedoDialog(vm, viewFrame, user, tracedo, false, false);
	}

	/**
	 * Open dialog for Tracedo duplication
	 */
	public static async duplicateTracedo(vm: BaseViewModel<any>, viewFrame: string, user: User, tracedo: TracedoView): Promise<Edit>
	{
		return TracedoHelpers.openTracedoDialog(vm, viewFrame, user, tracedo, false, true);
	}	

	/**
	 * Open detail dialog for existing Tracedo
	 * @param vm 
	 * @param viewFrame 
	 * @param tracedo 
	 * @returns 
	 */
	public static async detailTracedo(vm: BaseViewModel<any>, viewFrame: string, user: User, tracedo: TracedoView): Promise<Edit>
	{
		return TracedoHelpers.openTracedoDialog(vm, viewFrame, user, tracedo, true, false);
	}

	/**
     * Open dialog for Tracedo create or edit
     */
	public static async openTracedoDialog(
			vm: BaseViewModel<any>, 
			viewFrame: string, 
			user: User, 
			tracedo: TracedoView = null, 
			forceReadOnly: boolean = false,
			duplicate: boolean = false
		): Promise<Edit>
	{
		// Bud usek vytvarim (pak jsem spediter (objednavky jsou jinde)), nebo edituji a pak mohu byt kdokoliv
		const resourceName = tracedo ?
			'entity.tracedo' + (tracedo.myField === 'spediter' ? 'Spediter' : (tracedo.myField === 'customer' ? 'Customer' : 'Carrier')) : 
			'entity.tracedoSpediter';

		let dialogTitle = i18n.t('nav.createTracedo');
		if(tracedo != null) {
			if(duplicate) {
				dialogTitle = i18n.t('common.actions.duplicateTransport') + ' - ' + tracedo.myNumber;
			}
			else {
				dialogTitle = i18n.t('nav.tracedo') + ' - ' + tracedo.myNumber;
			}
		}

		return vm.loadViewFrame<Edit>(Edit, viewFrame, {
			forceReadOnly: forceReadOnly,
			duplicate: duplicate,
			tracedoId: tracedo ? tracedo.id : null,
			dialog: {
				width: 1160, // 1110, // 1330,
				height: 755, // 825,
				modal: true,
				maximize: true,
				title: dialogTitle,
				resizable: false,
				buttons: (editVm: Edit, window: kendo.ui.Window) => {

					if(forceReadOnly) {
						return [{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.close'), click: () => window.close() }];
					}
					
					if(user.isAllowed(resourceName, 'edit')) {
						return [
							{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.cancel'), click: () => window.close() },
							{ align: 'right', cls: 'btn-primary', label: i18n.t('common.actions.save'), click: async () => {
									let saved = await editVm.save();
									if(saved) {
										(vm as any).reload();
										window.close();
									}
								}
							}
						];
					}

					return [{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.close'), click: () => window.close() }];
				}
			}
		});
	}

	/**
	 * Open order dialog
	 * 
	 * @param vm 
	 * @param viewFrame 
	 */
	public static async openOrderDialog(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView = null, duplicate: boolean = false): Promise<CreateOrder>
	{
		return vm.loadViewFrame<CreateOrder>(CreateOrder, viewFrame, {
			duplicate: duplicate,
			tracedoId: tracedo ? tracedo.id : null,			
			dialog: {
				width: 1160, // 1110, // 1330,
				height: 755, // 825,
				modal: true,
				title: i18n.t('common.actions.createOrder'),
				buttons: (editVm: Edit, window: kendo.ui.Window) => {
					return [
						{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.cancel'), click: () => window.close() },
						{ align: 'right', cls: 'btn-primary', label: i18n.t('common.actions.save'), click: async () => {
								let saved = await editVm.save();
								if(saved) {
									(vm as any).reload();
									window.close();
								}
							}
						}
					];
				}
			}
		});
	}


	/**
	 * nastaví řidiče
	 */
	public static async setDriver(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView): Promise<SetDriver>
	{
		//double-bang check
		if((!!tracedo.destAddressName && !!tracedo.destAddressStreet && !!tracedo.destAddressCity && !!tracedo.destAddressZipCode && !!tracedo.destAddressCountryId) == false)
		{
			kendo.alert(i18n.t('common.captions.chooseDestinationGateway'));
			return;
		}
		if((!!tracedo.originAddressName && !!tracedo.originAddressStreet && !!tracedo.originAddressCity && !!tracedo.originAddressZipCode && !!tracedo.originAddressCountryId) == false)
		{
			kendo.alert(i18n.t('common.captions.chooseOriginGateway'));
			return;
		}
		return vm.loadViewFrame<SetDriver>(SetDriver, viewFrame, {
			tracedoId: tracedo.id,
			dialog: {
				width: 700,
				height: 540,
				modal: true,
				title: i18n.t('common.actions.setDriver'),
				buttons: (setDriverVm: SetDriver, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: async () => {
								await setDriverVm.save();
								(vm as any).reload();
								window.close();
							}
						},
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => {
								window.close();
							}
						}
					];
				}
			}
		});
	}

	/**
	 * Otevre dialog pro licitaci o prodejni cene
	 */
	public static async openSellPriceDetail(vm: BaseViewModel<any>, tracedo: Tracedo, viewFrame: string, callback: Function): Promise<PriceLog>
	{
		return await TracedoHelpers.openPriceDetail(vm, tracedo, 'supplier', viewFrame, callback);
	}

	/**
	 * Otevre dialog pro licitaci o nakupuni cene
	 */
	public static async openPurchasePriceDetail(vm: BaseViewModel<any>, tracedo: Tracedo, viewFrame: string, callback: Function): Promise<PriceLog>
	{
		return await TracedoHelpers.openPriceDetail(vm, tracedo, 'customer', viewFrame, callback);
	}

	/**
	 * Otevre dialog pro licitaci o cene (predaneho typu)
	 * @param BaseViewModel vm
	 * @param number tracedoId
	 * @param type 'customer' / 'supplier'
	 * @returns 
	 */
	private static async openPriceDetail(vm: BaseViewModel<any>, tracedo: Tracedo, type: string, viewFrame: string, callback: Function): Promise<PriceLog>
	{
		let priceLogView = await vm.loadViewFrame<PriceLog>(PriceLog, viewFrame, {
			tracedoId: tracedo.id,
			type: type,
			dialog: {
				width: 630,
				height: 550,
				modal: true,
				title: i18n.t('common.captions.priceLog.' + type),
				buttons: (plVm: Edit, window: kendo.ui.Window) => {
					return [
						{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.close'), click: () => window.close() }
					];
				}
			}
		});

		// if price log is changed, refresh customer/supplier + sellPrice/purchasePrice
		priceLogView.onPriceLogSaved.attach((data) => {
			callback(data);
		});
		priceLogView.onPriceLogApproved.attach((data) => {
			callback(data);
		});		
		priceLogView.onPriceLogRejected.attach((data) => {
			callback(data);
		});		

		return priceLogView;
	}
 

	/**
	 * Generovat CMR
	 * @param vm 
	 * @param tracedo 
	 */
	private static async generateCMR(vm: BaseViewModel<any>, tracedo: TracedoView)
	{
		await vm.loadViewFrame<GenerateCMR>(GenerateCMR, 'actionDialog', {
			tracedoId: tracedo.id,
			dialog: {
				width: 600,
				height: 450,
				modal: true,
				title: i18n.t('common.captions.generateCMR'),
				buttons: (generateCmrVm: GenerateCMR, window: kendo.ui.Window) => [{
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.generate'),
						click: async () => {
							await generateCmrVm.save();
						}
					}, {
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.close'),
						click: () => {
							window.close();
						}
					}]
			}
		});
	}


	// /**
	//  * Nastaveni stavu na approved
	//  */
	// public static async approveTracedo(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView): Promise<Approve>
	// {
	// 	return vm.loadViewFrame<Approve>(Approve, viewFrame, {
	// 		tracedoId: tracedo.id,
	// 		dialog: {
	// 			width: 400,
	// 			height: 270,
	// 			modal: true,
	// 			title: i18n.t('common.actions.accept'),
	// 			buttons: (approveVm: Approve, window: kendo.ui.Window) => {
	// 				return [
	// 					{
	// 						align: 'right',
	// 						cls: 'btn-primary',
	// 						label: i18n.t('common.actions.accept'),
	// 						click: async () => {
	// 							await approveVm.save();
	// 							(vm as any).reload();
	// 							window.close();
	// 						}
	// 					},
	// 					{
	// 						align: 'right',
	// 						cls: 'btn-link',
	// 						label: i18n.t('common.actions.close'),
	// 						click: () => {
	// 							window.close();
	// 						}
	// 					}
	// 				];
	// 			}
	// 		}
	// 	});
	// }

	// /**
	//  * Nastaveni stavu na declined
	//  */
	// public static async declineTracedo(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView): Promise<Decline>
	// {
	// 	return vm.loadViewFrame<Decline>(Decline, viewFrame, {
	// 		tracedoId: tracedo.id,
	// 		dialog: {
	// 			width: 400,
	// 			height: 270,				
	// 			modal: true,
	// 			title: i18n.t('common.actions.reject'),
	// 			buttons: (declineVm: Decline, window: kendo.ui.Window) => {
	// 				return [
	// 					{
	// 						align: 'right',
	// 						cls: 'btn-primary',
	// 						label: i18n.t('common.actions.reject'),
	// 						click: async () => {
	// 							await declineVm.save();
	// 							(vm as any).reload();
	// 							window.close();
	// 						}
	// 					},
	// 					{
	// 						align: 'right',
	// 						cls: 'btn-link',
	// 						label: i18n.t('common.actions.close'),
	// 						click: () => {
	// 							window.close();
	// 						}
	// 					}
	// 				];
	// 			}
	// 		}
	// 	});
	// }

	/**
	 * Nastaveni stavu na naloženo - Loaded
	 */
	public static async setLoad(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView): Promise<Load>
	{
		return vm.loadViewFrame<Load>(Load, viewFrame, {
			tracedoId: tracedo.id,
			dialog: {
				width: 320,
				height: 170,					
				modal: true,
				title: i18n.t('common.actions.loadingSet'),
				buttons: (loadVm: Load, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.confirmLoad'),
							click: async () => {
								let loadResult = await loadVm.save();
								if(loadResult) {
									(vm as any).reload();
									window.close();
								}
							}
						},
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => {
								window.close();
							}
						}
					];
				}
			}
		});
	}

	/**
	 * Nastaveni stavu na vyloženo - Unloaded
	 */
	public static async setDischarge(vm: BaseViewModel<any>, viewFrame: string, tracedo: TracedoView): Promise<Discharge>
	{
		return vm.loadViewFrame<Discharge>(Discharge, viewFrame, {
			tracedoId: tracedo.id,
			dialog: {
				width: 320,
				height: 170,
				modal: true,
				title: i18n.t('common.actions.dischargeSet'),
				buttons: (dischargeVm: Discharge, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.confirmDischarge'),
							click: async () => {
								let dischargeResult = await dischargeVm.save();
								if(dischargeResult) {
									(vm as any).reload();
									window.close();
								}
							}
						},
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => {
								window.close();
							}
						}
					];
				}
			}
		});
	}




	// ---------------------------------------------------------------------------------------------------------

	/**
	 * Zmenit predani prepravy na jineho dopravce
	 */
	public static async pass(vm: BaseViewModel<any>, tracedo: TracedoView, viewFrame: string): Promise<Pass>
	{
		return await TracedoHelpers.passTracedo(vm, tracedo, viewFrame, false);
	}
 
	 /**
	  * Postoupit prepravu
	  * @param tracedoId ID useku
	  * @param change Change
	  */
	public static async passTracedo(vm: BaseViewModel<any>, tracedo: TracedoView, viewFrame: string, change: boolean): Promise<Pass>
	{
		let passVM: Pass = await vm.loadViewFrame<Pass>(Pass, viewFrame, {
			tracedoId: tracedo.id,
			dialog: {
				width: 400,
				height: 270,
				modal: true,
				title: change ? i18n.t('common.captions.changeCarrier') : i18n.t('common.captions.pass'),
				buttons: (passVm: Pass, window: kendo.ui.Window) => {
					return [{
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.ok'),
						click: async () => {
							await passVm.save();
							//(vm as any).reload();
							window.close();
						}
					}, {
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.close'),
						click: () => {
							window.close();
						}
					}];
				}
			}
		});

		// when passed - update supplier + purchase price + pass status
		passVM.onPassed.attach(async (data) => {
			(vm as any).reload();
		});

		return passVM;
	}


	// ---------------------------------------------------------------------------------------------------------


	/**
	 * Tracedo prepravy nacteny  do gridu
	 */
	public static async tracedoGridDataBound(grid: kendo.ui.Grid, gridElement: JQuery, e: kendo.ui.GridDataBoundEvent)
	{
		KendoHelpers.highlightColumn(grid);
		gridElement.find('[data-action=detail]').prop('disabled', true);
		gridElement.find('[data-action=edit]').prop('disabled', true);
		gridElement.find('[data-action=duplicate]').prop('disabled', true);
		gridElement.find('[data-action=duplicateOrder]').prop('disabled', true);
		gridElement.find('[data-action=setDriver]').prop('disabled', true);
		// gridElement.find('[data-action=approve]').prop('disabled', true);
		// gridElement.find('[data-action=decline]').prop('disabled', true);

		gridElement.find('[data-action=sellPriceDetail]').prop('disabled', true);
		gridElement.find('[data-action=purchasePriceDetail]').prop('disabled', true);
		gridElement.find('[data-action=pass]').prop('disabled', true);

		gridElement.find('[data-action=setLoad]').prop('disabled', true);
		gridElement.find('[data-action=setDischarge]').prop('disabled', true);
		gridElement.find('[data-action=suspend]').prop('disabled', true);
		gridElement.find('[data-action=cancel]').prop('disabled', true);
		gridElement.find('[data-action=generateCMR]').prop('disabled', true);

		gridElement.find('.generate-transport-order-menu > .btn').prop('disabled', true);
		gridElement.find('.generate-transport-order-menu button[data-role=customer]').prop('disabled', true);
		gridElement.find('.generate-transport-order-menu button[data-role=supplier]').prop('disabled', true);

		let dataView = grid.dataSource.view();
		for(let i = 0; i < dataView.length; i++) {
			let item = dataView[i];
			let el = gridElement.find("tr[data-uid=" + item.uid + "]").removeClass("k-alt");
			el.addClass(item.color);
		}		
	}

	/**
	 * Enable / Disable actions when grid row selection changes
	 */
	public static async tracedoGridSelectionChanged(grid: kendo.ui.Grid, gridElement: JQuery, user: User, codelistManager: CodelistManager)
	{
		let maxCarrier = codelistManager.getMaxCarrier();
		let selected = grid.select().length > 0;
		
		if(selected) {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;

			// console.log('item', item);
			const isSpediter = tracedo.myField === 'spediter';
			const isCustomer = tracedo.myField === 'customer';
			const isCarrier = tracedo.myField.substring(0, 7) === 'carrier';
			const resourceName = 'entity.tracedo' + (isSpediter ? 'Spediter' : (isCustomer ? 'Customer' : 'Carrier'));
			
			const trcStatusIdent: string = codelistManager.getOneStatus(tracedo.trcStatusId, 'ident');

			const passedFromStatus: PassStatus = codelistManager.getOnePassStatus(tracedo.myPassedFromStatusId);
			const passedStatus: PassStatus = codelistManager.getOnePassStatus(tracedo.myPassStatusId);

			// is transport canceled ?
			let isCanceled = trcStatusIdent === 'canceled';

			// Pokud je dodavatel prazdny, jsem poslednim dopravcemm pokud ne, ale je supplierPartnerIsFinal true, je muj dodavatel finalni
			let isCarrierOrParent = !isCustomer && (tracedo.supplierPartnerIsFinal || tracedo.supplierPartnerId == null);

			// Priradit ridice je mozne, pokud je prirazen jiny ridic ("set_to_driver") NEBO je akceptovano ("accepted") NEBO je preruseno ("suspended")
			// A zaroven priradit ridice smi pouze konecny dopravce a jeho predchudce
			let setDriverEnabled = isCarrierOrParent && (trcStatusIdent === 'set_to_driver' || trcStatusIdent === 'accepted' || trcStatusIdent === 'suspended');

			// Prerusit prepravu lze pouze pokud je v nasledujicich stavech
			// A zaroven prerusit prepravu muze pouze konecny dopravce a jeho predchudce
			let suspendEnabled = isCarrierOrParent && jQuery.inArray(trcStatusIdent, ['set_to_driver','accepted','loaded','pos_for_pick_up','pos_for_delivery']) > -1;

			// Nalozit je mozne, pokud je prirazen ridic ("set_to_driver") NEBO je akceptovano ("accepted") NEBO je preruseno ("suspended")
			let setLoadEnabled = (trcStatusIdent == 'set_to_driver' || trcStatusIdent == 'accepted' || trcStatusIdent == 'suspended');
			// Vylozit je mozne, pokud je nalozeno "loaded" NEBO je preprava prerusena "suspended"
			let setDischargeEnabled = (trcStatusIdent == 'loaded' || trcStatusIdent == 'suspended');
			// Schvalit a zamitnout lze prepravu jen pokud je "new"
			// let approveDeclineEnabled = trcStatusIdent == 'new' || trcStatusIdent == 'accepted_by_carrier';
			let sellPriceDetailEnabled = !isCanceled && passedFromStatus; // && (passedFromStatus.ident === 'inprogress' || passedFromStatus.ident === 'new')
			let purchasePriceDetailEnabled = !isCanceled && passedStatus && tracedo.supplierPartnerId && tracedo.supplierPartnerId != tracedo.mySubjectId; //  && (passedStatus.ident === 'inprogress' || passedStatus.ident === 'new')

			// Can current user pass this tracedo?
			// let canPass = false;
			// if(!isCanceled) isSpediter && (tracedo.supplierPartnerId == null || tracedo.supplierPartnerId == tracedo.mySubjectId)) {
			// 	canPass = true;
			// }
			// if(!isCanceled && isCarrier) {
			// 	let myCarrierNo = tracedo.myField === 'carrier' ? 1 : parseInt(tracedo.myField.substr(7), 10);
			// 	if(tracedo.supplierPartnerId == null && myCarrierNo < maxCarrier) {
			// 		canPass = true;
			// 	}
			// }
			let canPass = false;
			if(!isCanceled) {
				if(isSpediter) {
					canPass = true;
				}
				else if(isCarrier) {
					let myCarrierNo = tracedo.myField === 'carrier' ? 1 : parseInt(tracedo.myField.substring(7), 10);
					if(myCarrierNo < maxCarrier) {
						canPass = true;
					}
				}
			}
			// console.log('can pass: ', isCanceled, isSpediter, isCarrier, canPass, tracedo.myField, tracedo.myField === 'carrier' ? 1 : parseInt(tracedo.myField.substr(7), 10), maxCarrier);

			// Zrusit prepravu lze pokud neni "canceled" nebo "unloaded"
			// A zrusit prepravu muze pouze spediter + customer
			let cancelEnabled = (isSpediter || isCustomer) && (trcStatusIdent !== 'canceled' && trcStatusIdent !== 'unloaded');

			// as customer
			// - je nastaveny dopravce (dodavatel) jiny od meho subjektu 
			// - je nastavena moje zodpovedna osoba
			// - je zadana nakupni cena
			let transportOrderAsCustomerEnabled = 
			((tracedo.supplierPartnerId !== null && tracedo.supplierPartnerId != tracedo.mySubjectId) &&
			tracedo.myPicUserId !== null &&
			tracedo.purchasePrice !== null && tracedo.purchasePriceCurrencyId !== null);
			
			// as supplier
			// - je nastaveny zakaznik (customer) jiny od meho subjektu 
			// - je nastavena zakaznikova zodpovedna osoba
			// - je zadana prodejni cena
			let transportOrderAsSupplierEnabled = 
			((tracedo.customerPartnerId !== null && tracedo.customerPartnerId != tracedo.mySubjectId) &&
			tracedo.customerPartnerPicUserId !== null &&
			tracedo.sellPrice !== null && tracedo.sellPriceCurrencyId !== null);
			
			// Povoleni tisknout objednavku prepravy alespon jednim zpusobem
			let transportOrderEnabled = transportOrderAsSupplierEnabled || transportOrderAsCustomerEnabled;
			
			gridElement.find('[data-action=detail]').prop('disabled', !user.isAllowed('entity.tracedo', 'view'));
			gridElement.find('[data-action=edit]').prop('disabled', !user.isAllowed(resourceName, 'edit'));
			gridElement.find('[data-action=duplicate]').prop('disabled', !(user.isAllowed('entity.tracedo', 'create') && isSpediter));
			gridElement.find('[data-action=duplicateOrder]').prop('disabled', !(user.isAllowed('entity.tracedo', 'createOrder') && isCustomer));
			// gridElement.find('[data-action=approve]').prop('disabled', !(approveDeclineEnabled && user.isAllowed(resourceName, 'approve')));
			// gridElement.find('[data-action=decline]').prop('disabled', !(approveDeclineEnabled && user.isAllowed(resourceName, 'decline')));

			gridElement.find('[data-action=sellPriceDetail]').prop('disabled', !(sellPriceDetailEnabled && user.isAllowed(resourceName, 'edit')));
			gridElement.find('[data-action=purchasePriceDetail]').prop('disabled', !(purchasePriceDetailEnabled && user.isAllowed(resourceName, 'edit')));
			gridElement.find('[data-action=pass]').prop('disabled', !(canPass && user.isAllowed('entity.tracedo', 'pass')));

			gridElement.find('[data-action=setDriver]').prop('disabled', !(setDriverEnabled && user.isAllowed(resourceName, 'setDriver')));
			gridElement.find('[data-action=suspend]').prop('disabled', !(suspendEnabled && user.isAllowed(resourceName, 'suspend')));
			gridElement.find('[data-action=setLoad]').prop('disabled', !(setLoadEnabled && user.isAllowed(resourceName, 'setOrigin')));
			gridElement.find('[data-action=setDischarge]').prop('disabled', !(setDischargeEnabled && user.isAllowed(resourceName, 'setDestination')));
			gridElement.find('[data-action=cancel]').prop('disabled', !(cancelEnabled && user.isAllowed(resourceName, 'cancel')));
			gridElement.find('[data-action=generateCMR]').prop('disabled', !(cancelEnabled && user.isAllowed(resourceName, 'generateCMR')));

			gridElement.find('.generate-transport-order-menu > .btn').prop('disabled', !(transportOrderEnabled && user.isAllowed(resourceName, 'generateTransportOrder')));
			gridElement.find('.generate-transport-order-menu button[data-role=customer]').prop('disabled', !(transportOrderAsCustomerEnabled && user.isAllowed(resourceName, 'generateTransportOrder')));
			gridElement.find('.generate-transport-order-menu button[data-role=supplier]').prop('disabled', !(transportOrderAsSupplierEnabled && user.isAllowed(resourceName, 'generateTransportOrder')));
		}
		else {
			gridElement.find('[data-action=detail]').prop('disabled', true);
			gridElement.find('[data-action=edit]').prop('disabled', true);
			gridElement.find('[data-action=duplicate]').prop('disabled', true);
			gridElement.find('[data-action=duplicateOrder]').prop('disabled', true);
			// gridElement.find('[data-action=approve]').prop('disabled', true);
			// gridElement.find('[data-action=decline]').prop('disabled', true);

			gridElement.find('[data-action=sellPriceDetail]').prop('disabled', true);
			gridElement.find('[data-action=purchasePriceDetail]').prop('disabled', true);
			gridElement.find('[data-action=pass]').prop('disabled', true);

			gridElement.find('[data-action=setDriver]').prop('disabled', true);
			gridElement.find('[data-action=suspend]').prop('disabled', true);
			gridElement.find('[data-action=setLoad]').prop('disabled', true);
			gridElement.find('[data-action=setDischarge]').prop('disabled', true);
			gridElement.find('[data-action=cancel]').prop('disabled', true);
			gridElement.find('[data-action=generateCMR]').prop('disabled', true);

			gridElement.find('.generate-transport-order-menu > .btn').prop('disabled', true);
			gridElement.find('.generate-transport-order-menu button[data-role=customer]').prop('disabled', true);
			gridElement.find('.generate-transport-order-menu button[data-role=supplier]').prop('disabled', true);
		}				
	}


	/**
	 * Detail tracedo useku (wizard checkpointu)
	 */
	public static async tracedoGridDetailInit(rpc: Client, e: kendo.ui.GridDetailInitEvent, codelistManager: CodelistManager)
	{
		let tracedo = e.data as unknown as TracedoView;
		let checkpoints: any[] = await rpc.call('tracedo.getCheckpoints', { id: tracedo.id, query: { select: '*,addressCountry(iso)' } });
	
		let originAddressCountryIso = tracedo.originAddressCountryId ? codelistManager.getOneCountry(tracedo.originAddressCountryId, 'iso') as string : '';
		let destAddressCountryIso = tracedo.destAddressCountryId ? codelistManager.getOneCountry(tracedo.destAddressCountryId, 'iso') as string : '';

		// Origin
		let hTpl = '<thead><tr>';
		let mTpl = '<tbody><tr>';
		let bTpl = '<tfoot><tr>';

		let originTd = tracedo.atd ? ' class="tr-left"' : '';
		hTpl += `<td${originTd}><span>${tracedo.originAddressName}<br />${tracedo.originAddressCity}, ${originAddressCountryIso}</span></td>`;
		mTpl += `<td${originTd}><div><i class="icon-download-1"></i></div></td>`;
		bTpl += `<td${originTd}>
					<span>${kendo.toString(tracedo.etdFrom as Date,'g')}</span>
					${tracedo.atd ? '<br /><strong>' + kendo.toString(tracedo.atd as Date,'g') : ''}</strong>
				</td>`;

		// Checkpoints
		checkpoints.forEach((cp: any) => {
			// parse dates from API
			cp.eta = cp.eta === null ? null : kendo.parseDate(cp.eta);
			cp.etd = cp.etd === null ? null : kendo.parseDate(cp.etd);
			cp.ata = cp.ata === null ? null : kendo.parseDate(cp.ata);
			cp.atd = cp.atd === null ? null : kendo.parseDate(cp.atd);

			let cpTd = cp.ata ? ` class="tr-arrived${cp.atd ? ' tr-left' : ''}"` : '';
			hTpl += `<td${cpTd}><span>${cp.addressName}<br />${cp.addressTown}, ${cp.addressCountry.iso}</span></td>`;
			mTpl += `<td${cpTd}><div><i class="icon-ok-circled2"></i></div></td>`;
			bTpl += `<td${cpTd}>
						<span>${kendo.toString(cp.eta,'g')}${cp.etd ? ` - ${kendo.toString(cp.etd,'g')}` : ''}</span>
						${cp.ata ? `<br /><strong>${kendo.toString(cp.ata,'g')}${cp.atd ? ` - ${kendo.toString(cp.atd,'g')}` : ''}</strong>`: ''}
					</td>`;
		});				
		// Destination
		let destTd = tracedo.ata ? ' class="tr-arrived"' : '';
		hTpl += `<td${destTd}><span>${tracedo.destAddressName}<br />${tracedo.destAddressCity}, ${destAddressCountryIso}</span></td>`;
		mTpl += `<td${destTd}><div${tracedo.ata ? ' class="visited"' : ''}><i class="icon-upload-1"></i></div></td>`;
		bTpl += `<td${destTd}>
					<span>${kendo.toString(tracedo.etaFrom as Date,'g')}</span>
					${tracedo.ata ? '<br /><strong>' + kendo.toString(tracedo.ata as Date,'g') : ''}</strong>
				</td>`;

		hTpl += '</tr></thead>';
		mTpl += '</tr></tbody>';
		bTpl += '</tr></tfoot>';

		e.detailCell.append('<table class="wizard">' + hTpl + mTpl + bTpl + '</table>');
	}


	// ---------------------------------------------------------------------------------------------------------

	/**
	 * Vytvori toolbar pro grid s useky
	 * 
	 * @param actions 'create','edit','detail','setDriver','setLoad','setDischarge','changeInvStatus','suspend','approve','decline','cancel','kind'
	 * @param user 
	 * @param invoicingStatuses 
	 * @param transportKinds 
	 * @returns 
	 */
	public static tracedoGridCreateToolbar(actions: { [key: string]: boolean }, user: User, invoicingStatuses: any[] = null, transportKinds: any[] = null)
	{
		// Tlacitka, ktera budou na toolbaru
		var toolbarConfig = [];
		if(actions['createOrder'] && user.isAllowed('entity.tracedo', 'createOrder')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="createOrder"><i class="k-icon k-i-plus"></i>' + i18n.t('common.actions.createOrder') + '</button>' });
		}		
		if(actions['create'] && user.isAllowed('entity.tracedo', 'create')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="createTracedo"><i class="k-icon k-i-plus"></i>' + i18n.t('common.actions.create') + '</button>' });
		}
		if(actions['detail'] && user.isAllowed('entity.tracedo', 'view')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="detail" title="' + i18n.t('common.actions.detail') + '"><i class="icon-search"></i><span> ' + i18n.t('common.actions.detail') + '</span></button>' });
		}
		if(actions['edit'] && user.isAllowed('entity.tracedo', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="edit" title="' + i18n.t('common.actions.edit') + '"><i class="icon-edit"></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
		}
		if(actions['duplicate'] && user.isAllowed('entity.tracedo', 'create')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="duplicate" title="' + i18n.t('common.actions.duplicate') + '"><i class="icon-docs"></i><span> ' + i18n.t('common.actions.duplicate') + '</span></button>' });
		}
		if(actions['duplicateOrder'] && user.isAllowed('entity.tracedo', 'createOrder')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="duplicateOrder" title="' + i18n.t('common.actions.duplicate') + '"><i class="icon-docs"></i><span> ' + i18n.t('common.actions.duplicate') + '</span></button>' });
		}
		if(actions['setDriver'] && user.isAllowed('entity.tracedo', 'setDriver')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="setDriver"><i class="k-icon k-i-edit"></i>' + i18n.t('common.actions.setDriver') + '</button>' });
		}

		if(actions['setLoad'] && user.isAllowed('entity.tracedo', 'setOrigin')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="setLoad"><i class="icon-download-1"></i>' + i18n.t('common.actions.loadingSet') + '</button>'});
		}
		if(actions['setDischarge'] && user.isAllowed('entity.tracedo', 'setDestination')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="setDischarge"><i class="icon-upload-1"></i>' + i18n.t('common.actions.dischargeSet') + '</button>'});
		}
		if(actions['changeInvStatus'] && user.isAllowed('entity.tracedo', 'setInvoicingStatus') && invoicingStatuses !== null) {
			toolbarConfig.push({ template: TracedoHelpers.buildChangeInvoicingStatusSelect(invoicingStatuses) });
		}
		if(actions['suspend'] && user.isAllowed('entity.tracedo', 'suspend')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="suspend"><i class="icon-cancel-circled2"></i>' + i18n.t('common.actions.suspend') + '</button>' });
		}
		// if(actions['approve'] && user.isAllowed('entity.tracedo', 'approve')) {
		// 	toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="approve" title="' + i18n.t('common.actions.accept') + '"><i class="icon-ok-circled2"></i>' + i18n.t('common.actions.accept') +'</button>' });
		// }
		// if(actions['decline'] && user.isAllowed('entity.tracedo', 'decline')) {
		// 	toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="decline" title="' + i18n.t('common.actions.reject') + '"><i class="icon-cancel-circled2"></i>' + i18n.t('common.actions.reject')+'</button>' });
		// }

		if(actions['sellPriceDetail'] && user.isAllowed('entity.tracedo', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="sellPriceDetail" title="' + i18n.t('common.captions.sellPriceDetail') + '"><i class="icon-ok-circled2"></i>' + i18n.t('common.captions.sellPriceDetail') +'</button>' });
		}
		if(actions['purchasePriceDetail'] && user.isAllowed('entity.tracedo', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="purchasePriceDetail" title="' + i18n.t('common.captions.purchasePriceDetail') + '"><i class="icon-cancel-circled2"></i>' + i18n.t('common.captions.purchasePriceDetail')+'</button>' });
		}
		if(actions['pass'] && user.isAllowed('entity.tracedo', 'pass')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="pass" title="' + i18n.t('common.captions.pass') + '"><i class="icon-right-dir"></i>' + i18n.t('common.captions.pass')+'</button>' });
		}
		if(actions['cancel'] && user.isAllowed('entity.tracedo', 'cancel')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="cancel"><i class="icon-trash"></i>' + i18n.t('common.actions.cancel') + '</button>' });
		}
		if(actions['generateTransportOrder'] && user.isAllowed('entity.tracedo', 'generateTransportOrder')) {
			toolbarConfig.push({ template: TracedoHelpers.buildGenerateTransportOrderSelect() });
		}

		if(actions['generateCMR'] && user.isAllowed('entity.tracedo', 'generateCMR')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="generateCMR"><i class="icon-truck"></i>' + i18n.t('common.actions.generateCMR') + '</button>' });
		}


		if(actions['kind'] && transportKinds !== null) {
			toolbarConfig.push({ template: '<div class="ms-auto me-0 d-inline-block">' + i18n.t('common.captions.kind') + ': ' + TracedoHelpers.buildKindSelect(transportKinds) + '</div>' });
		}


		return toolbarConfig;
	}

	/**
	 * SELECT pro sloupce do Track&Trace + widgetu
	 * @returns 
	 */
	public static getSelect(): string
	{
		return '*';
	}

	/**
	 * Zpracuje data ze serveru (dopocita dalsi pole)
	 * @param items 
	 */
	public static processResponseData(items: any[])
	{
		// items.forEach((item) => {
		// 	if(item.createdUser && item.createdUser.firstName && item.createdUser.lastName) {
		// 		item.createdUser.realname = item.createdUser.firstName + ' ' + item.createdUser.lastName;
		// 	}
		// });
		return items;
	} 


	public static getGridCheckedRows(grid: kendo.ui.Grid)
	{
		const rows = grid.tbody.find("tr");
		var checkedRowsData: any[] = [];
		rows.each((i: number, row: HTMLTableRowElement) => {
			const $row = $(row);
			const checkbox = $row.find("input[type=checkbox]");
			if (checkbox.is(":checked")) {
				var dataItem = grid.dataItem($row);
				checkedRowsData.push(dataItem);
			}
		});
		return checkedRowsData;
	}

	/**
	 * Definice sloupcu pro kendo grid s useky
	 * @param codelistManager 
	 * @returns 
	 */
	public static tracedoGridDefineColumns(user: User, codelistManager: CodelistManager, isOrder: boolean = false)
	{
		let cols = [];
		var alertIcon = '<i class="k-icon k-i-cancel-outline k-warning-colored" style="color:red"></i>';
		cols.push({
			field: 'createdTimestamp',
			title: i18n.t('common.captions.createdTimestamp'),
			template: '#= createdTimestamp ? (kendo.toString(createdTimestamp, "' + i18n.t('common.formats.kendoDate') + '") + " " + kendo.toString(createdTimestamp, "' + i18n.t('common.formats.kendoTime') + '")) : "" #',
			width: 90
		});
		cols.push({
			field: 'mySeasonId',
			title: i18n.t('common.captions.season'),
			width: 90,
			template: (data: TracedoView) => codelistManager.getOneSeason(data.mySeasonId, 'name'),
			filterable: {
				ui: (element: JQuery) => codelistManager.createSeasonFilter(element)
			}
		});
		cols.push({
			field: 'myNumber',
			title: i18n.t('common.captions.number'),
			width: 90
		});
		cols.push({
			field: 'mySubjectName',
			title: i18n.t('common.captions.mySubject'),
			width: 90
		});
		cols.push({
			field: 'myPicUserRealName',
			title: i18n.t('common.captions.myPicUser'),
			width: 90
		});
		cols.push({
			field: 'clientRef',
			title: i18n.t('common.captions.clientRef'),
			width: 90
		});
		cols.push({
			field: 'clientRef2',
			title: i18n.t('common.captions.clientRef2'),
			width: 90
		});
		cols.push({
			field: 'clientRef3',
			title: i18n.t('common.captions.clientRef3'),
			width: 90
		});
		cols.push({
			field: 'spz',
			title: i18n.t('common.captions.spz'),
			width: 70
		});
		cols.push({
			field: 'roadTypeId',
			title: i18n.t('common.captions.roadType'),
			template: (data: TracedoView) => codelistManager.getOneRoadType(data.roadTypeId, 'name'),
			width: 70,
			filterable: {
				ui: (element: JQuery) => codelistManager.createRoadTypeFilter(element)
			}
		});
		cols.push({
			field: 'etdFrom',
			title: i18n.t('common.captions.etd'),
			template: '#= etdFrom ? ( kendo.toString(etdFrom, "' + i18n.t('common.formats.kendoDate') + '") + "<br/>"' +
				' + ( kendo.toString(etdFrom, "HH:mm") !== "00:00"  ? kendo.toString(etdFrom, "' + i18n.t('common.formats.kendoTime') + '") : "" ) +' +
				' ( etdTo && kendo.toString(etdTo, "HH:mm") !== "00:00" ? " - "+ kendo.toString(etdTo, "' + i18n.t('common.formats.kendoTime') + '") : "" ) ) : ""  #',
			width: 120
		});
		cols.push({
			field: 'etaFrom',
			title: i18n.t('common.captions.eta'),
			template: '#= etaFrom ? ( kendo.toString(etaFrom, "' + i18n.t('common.formats.kendoDate') + '") + "<br/>"' +
				' + ( kendo.toString(etaFrom, "HH:mm") !== "00:00"  ? kendo.toString(etaFrom, "' + i18n.t('common.formats.kendoTime') + '") : "" ) +' +
				' ( etaTo && kendo.toString(etaTo, "HH:mm") !== "00:00" ? " - "+ kendo.toString(etaTo, "' + i18n.t('common.formats.kendoTime') + '") : "" ) ) : ""  #',
			width: 120
		});
		cols.push({
			field: 'shipperName',
			title: i18n.t('common.captions.shipper'),
			width: 280,
		});
		cols.push({
			field: 'consigneeName',
			title: i18n.t('common.captions.consignee'),
			width: 280,
		});
		cols.push({
			field: 'cntrNr',
			title: i18n.t('common.captions.cntrNr'),
			width: 80,
		});
		cols.push({
			field: 'originAddressName',
			title: i18n.t('common.captions.dispatch'),
			width: 280,
			template: (data: TracedoView) => {

				let originAddress = '<div class="carrier-overridable-address">';

				if(data.carriersOriginData && data.carriersOriginData.address) {
					let cod = data.carriersOriginData.address;
					
					originAddress += '<i class="icon-attention cell-attention"></i>';

					originAddress += '<div class="carriers-data">' +
						cod.name + '<br />' +
						cod.street + 
						((cod.street2 ?? '').trim() !== '' ? ', ' + cod.street2 : '') +
						', ' + cod.zipCode + ' ' + cod.city + 
						(cod.countryId ? (', ' + codelistManager.getOneCountry(cod.countryId, 'iso')) : '') +
						'</div>';
				}

				originAddress += '<div class="original-data">' + 
					data.originAddressName + '<br />' +
					data.originAddressStreet + 
					((data.originAddressStreet2 ?? '').trim() !== '' ? ', ' + data.originAddressStreet2 : '') +
					', ' + data.originAddressZipCode + ' ' + data.originAddressCity + 
					(data.originAddressCountryId ? (', ' + codelistManager.getOneCountry(data.originAddressCountryId, 'iso')) : '') +
					'</div>';

				originAddress += '</div>';
				return originAddress;
			}
		});
		cols.push({
			field: 'originAddressCountryId',
			title: i18n.t('common.captions.originCountry'),
			width: 140,
			template: (data: TracedoView) => codelistManager.getOneCountry(data.originAddressCountryId, 'descr'),
			filterable: {
				ui: (element: JQuery) => codelistManager.createCountryFilter(element)
			}
		});
		cols.push({
			field: 'destAddressName',
			title: i18n.t('common.captions.destination'),
			width: 280,
			template: (data: TracedoView) => {

				let destAddress = '<div class="carrier-overridable-address">';
				 
				if(data.carriersDestinationData && data.carriersDestinationData.address) {
					let cdd = data.carriersDestinationData.address;

					destAddress += '<i class="icon-attention cell-attention"></i>';
					
					destAddress += '<div class="carriers-data">' +
						cdd.name + '<br />' +
						cdd.street + 
						((cdd.street2 ?? '').trim() !== '' ? ', ' + cdd.street2 : '') +
						', ' + cdd.zipCode + ' ' + cdd.city + 
						(cdd.countryId ? (', ' + codelistManager.getOneCountry(cdd.countryId, 'iso')) : '') +
						'</div>';
				}

				destAddress += '<div class="original-data">' + 
					data.destAddressName + '<br />' +
					data.destAddressStreet + 
					((data.destAddressStreet2 ?? '').trim() !== '' ? ', ' + data.destAddressStreet2 : '') +
					', ' + data.destAddressZipCode + ' ' + data.destAddressCity + 
					(data.destAddressCountryId ? (', ' + codelistManager.getOneCountry(data.destAddressCountryId, 'iso')) : '') + 
					'</div>';

				destAddress += '</div>';
				return destAddress;
			}
		});
		cols.push({
			field: 'destAddressCountryId',
			title: i18n.t('common.captions.destinationCountry'),
			width: 140,
			template: (data: TracedoView) => codelistManager.getOneCountry(data.destAddressCountryId, 'descr'),
			filterable: {
				ui: (element: JQuery) => codelistManager.createCountryFilter(element)
			}
		});
		cols.push({
			field: 'trcStatusId',
			title: i18n.t('common.captions.trcStatus'),
			width: 140,
			template: (data: TracedoView) => (codelistManager.getOneStatus(data.trcStatusId, 'name') ?? '') + (data.cancelRequest && data.cancelRequest == 1 ? " <span style=\"font-size:1.5em\">⚠</span>" : ""),
			filterable: {
				multi: true,
				checkAll: false,
				search: true,
				itemTemplate: (e: any) => 
					'<li class="k-item">' + 
						'<label class="k-label k-checkbox-label">' + 
							'<input type="checkbox" name="' + e.field + '" value="#= data.id == 0 ? null : data.id #" class="k-checkbox" />' + 
							'#= data.name || data.all #' + 
						'</label>' + 
					'</li>',
				dataSource: {
					autoSync: true,
					autoBind: true,
					type: "json",
					transport: {
						read: (options: any) => {
							options.success(codelistManager.getStatuses());
						}
					}
				}
			}
		});
		cols.push({
			field: 'driverUserRealName',
			title: i18n.t('common.captions.driver'),
			width: 140
		});
		cols.push({
			field: 'atd',
			title: i18n.t('common.captions.atd'),
			template: (data: TracedoView) => {
				if(data.atd) {
					return kendo.toString(data.atd as Date, i18n.t('common.formats.kendoDate')) + ' ' + kendo.toString(data.atd as Date, i18n.t('common.formats.kendoTime'));
				}
				return '';
			},
			width: 70
		});
		cols.push({
			field: 'ata',
			title: i18n.t('common.captions.ata'),
			template: (data: TracedoView) => {
				if(data.ata) {
					return kendo.toString(data.ata as Date, i18n.t('common.formats.kendoDate')) + ' ' + kendo.toString(data.ata as Date, i18n.t('common.formats.kendoTime'));
				}
				return '';
			},			
			width: 70
		});
		cols.push({
			field: 'myUzp',
			title: i18n.t('common.captions.myUzp'),
			format: i18n.t('common.formats.date'),
			width: 70
		});
		cols.push({
			field: 'createdUserRealName',
			title: i18n.t('common.captions.createdBy'),
			width: 140
		});
		cols.push({
			field: 'colli',
			title: i18n.t('common.captions.colli'),
			format: "{0:n0}",
			width: 60
		});
		cols.push({
			field: 'kgs',
			title: i18n.t('common.captions.kgs'),
			format: "{0:n3}",
			width: 60
		});
		cols.push({
			field: 'cbm',
			title: i18n.t('common.captions.cbm'),
			format: "{0:n2}",
			width: 60
		});
		cols.push({
			field: 'ldm',
			title: i18n.t('common.captions.ldm'),
			format: "{0:n2}",
			width: 60
		});
		cols.push({
			field: 'kindId',
			title: i18n.t('common.captions.kind'),
			template: (data: TracedoView) => codelistManager.getOneKind(data.kindId, 'name'),
			width: 60,
			filterable: {
				ui: (element: JQuery) => codelistManager.createKindFilter(element)
			}
		});
		cols.push({
			field: 'ecrFrom',
			title: i18n.t('common.captions.ecrFrom'),
			template: '#= ecrFrom ? (kendo.toString(ecrFrom, "' + i18n.t('common.formats.kendoDate') + '") + " " + kendo.toString(ecrFrom, "' + i18n.t('common.formats.kendoTime') + '")) : "" #',
			sortable: false,
			width: 70
		});
		cols.push({
			field: 'ecrTo',
			title: i18n.t('common.captions.ecrTo'),
			template: '#= ecrFrom ? (kendo.toString(ecrTo, "' + i18n.t('common.formats.kendoDate') + '") + " " + kendo.toString(ecrTo, "' + i18n.t('common.formats.kendoTime') + '")) : "" #',
			sortable: false,
			width: 70
		});
		cols.push({
			field: 'rdlFrom',
			title: i18n.t('common.captions.rdlFrom'),
			template: '#= rdlFrom ? (kendo.toString(rdlFrom, "' + i18n.t('common.formats.kendoDate') + '") + " " + kendo.toString(rdlFrom, "' + i18n.t('common.formats.kendoTime') + '")) : "" #',
			sortable: false,
			width: 70
		});
		cols.push({
			field: 'rdlTo',
			title: i18n.t('common.captions.rdlTo'),
			template: '#= rdlTo ? (kendo.toString(rdlTo, "' + i18n.t('common.formats.kendoDate') + '") + " " + kendo.toString(rdlTo, "' + i18n.t('common.formats.kendoTime') + '")) : "" #',
			sortable: false,
			width: 70
		});
		cols.push({
			field: 'bdpCntrTypeId',
			title: i18n.t('common.captions.cntrType'),
			template: (data: TracedoView) => codelistManager.getOneBdpCntrType(data.bdpCntrTypeId, 'estoneCode') ?? '',
			width: 70,
			filterable: {
				ui: (element: JQuery) => codelistManager.createBdpCntrTypeFilter(element)
			}
		});

		if(isOrder) {
			cols.push({
				field: 'myPassStatusId',
				title: i18n.t('common.captions.passStatus'),
				width: 160,
				template: (data: TracedoView) => codelistManager.getOnePassStatus(data.myPassStatusId, 'nameTranslated') ?? '',
				filterable: {
					multi: true,
					checkAll: false,
					search: true,
					itemTemplate: (e: any) => 
						'<li class="k-item">' + 
							'<label class="k-label k-checkbox-label">' + 
								'<input type="checkbox" name="' + e.field + '" value="#= data.statusId == 0 ? null : data.statusId #" class="k-checkbox" />' + 
								'#= data.nameTranslated || data.all #' + 
							'</label>' + 
						'</li>',
					dataSource: {
						autoSync: true,
						autoBind: true,
						type: "json",
						transport: {
							read: (options: any) => {
								options.success(codelistManager.getPassStatuses());
							}
						}
					}
				}
			});			
			cols.push({
				field: 'supplierPartnerName',
				title: i18n.t('common.captions.supplierPartner'),
				width: 70
			});
			cols.push({
				field: 'supplierPartnerPicUserRealName',
				title: i18n.t('common.captions.supplierPartnerPicUser'),
				width: 70
			});
			cols.push({
				field: 'purchasePrice',
				title: i18n.t('common.captions.price'),
				template: (data: TracedoView) => data.purchasePrice ? data.purchasePrice + ' ' + codelistManager.getOneCurrency(data.purchasePriceCurrencyId).iso : '',
				width: 100			
			});
		}
		else {
			cols.push({
				field: 'mySubjectRole',
				title: i18n.t('common.captions.role'),
				template: function(data: TracedoView) {
					return i18n.t('common.captions.subjectRole.' + data.mySubjectRole);
				},
				filterable: {
					multi: true,
					checkAll: false,
					search: true,
					itemTemplate: (e: any) => 
						'<li class="k-item">' + 
							'<label class="k-label k-checkbox-label">' + 
								'<input type="checkbox" name="' + e.field + '" value="#= data.value == 0 ? null : data.value #" class="k-checkbox" />' + 
								'#= data.name || data.all #' + 
							'</label>' + 
						'</li>',
					dataSource: {
						autoSync: true,
						autoBind: true,
						type: "json",
						transport: {
							read: (options: any) => {
								options.success([
									{ value: 'customer', name: i18n.t('common.captions.subjectRole.customer') },
									{ value: 'spediter', name: i18n.t('common.captions.subjectRole.spediter') },
									{ value: 'mediator', name: i18n.t('common.captions.subjectRole.mediator') },
									{ value: 'carrier', name: i18n.t('common.captions.subjectRole.carrier') }
								]);
							}
						}
					}
				},
				width: 70
			});			
			cols.push({
				field: 'customerPartnerNumber',
				title: i18n.t('common.captions.customerPartnerNumber'),
				width: 90
			});			
			cols.push({
				field: 'myInvoicingStatusId',
				title: i18n.t('common.captions.invoicingStatus'),
				width: 160,
				template: (data: TracedoView) => codelistManager.getOneInvoicingStatus(data.myInvoicingStatusId, 'name') ?? '',
				filterable: {
					ui: (element: JQuery) => codelistManager.createTracedoInvoicingStatusFilter(element)
				}
			});
			cols.push({
				field: 'myPassStatusId',
				title: i18n.t('common.captions.passStatus'),
				width: 160,
				template: (data: TracedoView) => codelistManager.getOnePassStatus(data.myPassStatusId, 'nameTranslated') ?? '',
				filterable: {
					multi: true,
					checkAll: false,
					search: true,
					itemTemplate: (e: any) => 
						'<li class="k-item">' + 
							'<label class="k-label k-checkbox-label">' + 
								'<input type="checkbox" name="' + e.field + '" value="#= data.statusId == 0 ? null : data.statusId #" class="k-checkbox" />' + 
								'#= data.nameTranslated || data.all #' + 
							'</label>' + 
						'</li>',
					dataSource: {
						autoSync: true,
						autoBind: true,
						type: "json",
						transport: {
							read: (options: any) => {
								options.success(codelistManager.getPassStatuses(true));
							}
						}
					}
				}
			});
			cols.push({
				field: 'myPassedFromStatusId',
				title: i18n.t('common.captions.passedFromStatus'),
				width: 160,
				template: (data: TracedoView) => codelistManager.getOnePassStatus(data.myPassedFromStatusId, 'nameTranslated') ?? '',
				filterable: {
					multi: true,
					checkAll: false,
					search: true,
					itemTemplate: (e: any) => 
						'<li class="k-item">' + 
							'<label class="k-label k-checkbox-label">' + 
								'<input type="checkbox" name="' + e.field + '" value="#= data.statusId == 0 ? null : data.statusId #" class="k-checkbox" />' + 
								'#= data.nameTranslated || data.all #' + 
							'</label>' + 
						'</li>',
					dataSource: {
						autoSync: true,
						autoBind: true,
						type: "json",
						transport: {
							read: (options: any) => {
								options.success(codelistManager.getPassStatuses(true));
							}
						}
					}
				}
			});

			cols.push({
				field: 'customerPartnerName',
				title: i18n.t('common.captions.customerPartner'),
				width: 70
			});
			cols.push({
				field: 'customerPartnerPicUserRealName',
				title: i18n.t('common.captions.customerPartnerPicUser'),
				width: 70
			});
			cols.push({
				field: 'sellPrice',
				title: i18n.t('common.captions.sellPrice'),
				template: (data: TracedoView) => data.sellPrice ? data.sellPrice + ' ' + codelistManager.getOneCurrency(data.sellPriceCurrencyId).iso : '',
				width: 100
			});
			cols.push({
				field: 'supplierPartnerName',
				title: i18n.t('common.captions.supplierPartner'),
				width: 70
			});
			cols.push({
				field: 'supplierPartnerPicUserRealName',
				title: i18n.t('common.captions.supplierPartnerPicUser'),
				width: 70
			});
			cols.push({
				field: 'purchasePrice',
				title: i18n.t('common.captions.purchasePrice'),
				template: (data: TracedoView) => data.purchasePrice ? data.purchasePrice + ' ' + codelistManager.getOneCurrency(data.purchasePriceCurrencyId).iso : '',
				width: 100			
			});
			// Tracedo Group name+number
			cols.push({
				field: 'myTracedoGroupName',
				title: i18n.t('common.captions.tracedoGroupName'),
				width: 70
			});
			cols.push({
				field: 'myTracedoGroupNumber',
				title: i18n.t('common.captions.tracedoGroupNumber'),
				width: 70
			});
		}

		return cols;
	}

	/**
	 * Datove schema pro kendo grid s tracedo useky
	 * @returns 
	 */
	public static tracedoGridDefineSchema(): kendo.data.DataSourceSchemaModelFields
	{
		return {
			id: { type: 'string' },
			createdTimestamp: { type: 'date' },
			myNumber: { type: 'string' },
			mySubjectName: { type: 'string' },
			mySeasonName: { type: 'string' },
			myPicUserRealName: { type: 'string' },
			clientRef: { type: 'string' },
			clientRef2: { type: 'string' },
			clientRef3: { type: 'string' },
			spz: { type: 'string' },
			roadTypeId: { type: 'number' },
			etdFrom: { type: 'date' },
			etdTo: { type: 'date' },
			etaFrom: { type: 'date' },
			etaTo: { type: 'date' },
			shipperName: { type: 'string' },
			consigneeName: { type: 'string' },
			cntrNr: { type: 'string' },
			originAddressName: { type: 'string' },
			originAddressCountryId: { type: 'number' },
			destAddressName: { type: 'string' },
			destAddressCountryId: { type: 'number' },
			trcStatusId: { type: 'number' },
			driverUserRealName: { type: 'string' },
			atd: { type: 'date' },
			ata: { type: 'date' },
			myUzp: { type: 'date' },
			supplierPartnerUzp: { type: 'date' },
			createdUserRealName: { type: 'string' },
			colli: { type: 'number' },
			kgs: { type: 'number' },
			cbm: { type: 'number' },
			ldm: { type: 'number' },
			kindId: { type: 'number' },
			ecrFrom: { type: 'date' },
			ecrTo: { type: 'date' },
			rdlFrom: { type: 'date' },
			rdlTo: { type: 'date' },
			bdpCntrTypeId: { type: 'number' },
			myPassStatusId: { type: 'number' },
			supplierPartnerName: { type: 'string' },
			supplierPartnerPicUserRealName: { type: 'string' },
			purchasePrice: { type: 'number' },
			mySubjectRole: { type: 'string' },
			customerPartnerNumber: { type: 'string' },
			myInvoicingStatusId: { type: 'number' },
			myPassedFromStatusId: { type: 'number' },
			customerPartnerName: { type: 'string' },
			customerPartnerPicUserRealName: { type: 'string' },
			sellPrice: { type: 'number' }
		};
	}

	/**
	 * Vraci pole fieldu s typem date
	 * @returns 
	 */
	public static tracedoGridDateColumns(): {[key:string]:string}
	{
		return {
			'createdTimestamp':'datetime',
			'etdFrom':'datetime',
			'etdTo':'datetime',
			'etaFrom':'datetime',
			'etaTo':'datetime',
			'atd':'datetime',
			'ata':'datetime',
			'myUzp':'date',
			'supplierPartnerUzp':'date',
			'ecrFrom':'datetime',
			'ecrTo':'datetime',
			'rdlFrom':'datetime',
			'rdlTo':'datetime'
		};
	}


	/**
	 * On ViewModel render - bind grid actions
	 */
	public static tracedoGridBindActions(vm: BaseViewModel<any>, grid: kendo.ui.Grid, gridElement: JQuery, user: User, tracedoManager: TracedoManager)
	{
		// BIND action buttons
		gridElement.on('click', '[data-action=createOrder]', () => {
			TracedoHelpers.createOrder(vm, 'actionDialog');
		});
		gridElement.on('click', '[data-action=createTracedo]', () => {
			TracedoHelpers.createTracedo(vm, 'actionDialog', user);
		});
		gridElement.on('click', '[data-action=duplicate]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.duplicateTracedo(vm, 'actionDialog', user, tracedo);
		});
		gridElement.on('click', '[data-action=duplicateOrder]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.duplicateOrder(vm, 'actionDialog', tracedo);
		});
		gridElement.on('click', '[data-action=edit]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.editTracedo(vm, 'actionDialog', user, tracedo);
		});
		gridElement.on('click', '[data-action=detail]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.detailTracedo(vm, 'actionDialog', user, tracedo);
		});
		if(user.isAllowed('entity.tracedo', 'edit') || user.isAllowed('entity.tracedo', 'view')) {
			gridElement.on('dblclick', 'tbody tr.k-master-row', () => {
				let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
				TracedoHelpers.editTracedo(vm, 'actionDialog', user, tracedo);
			});
		}

		gridElement.on('click', '[data-action=setDriver]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.setDriver(vm, 'actionDialog', tracedo);
		});
		// gridElement.on('click', '[data-action=decline]', () => {
		// 	let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
		// 	TracedoHelpers.declineTracedo(vm, 'actionDialog', tracedo);
		// });
		// gridElement.on('click', '[data-action=approve]', () => {
		// 	let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
		// 	TracedoHelpers.approveTracedo(vm, 'actionDialog', tracedo);
		// });

		gridElement.on('click', '[data-action=sellPriceDetail]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.openSellPriceDetail(vm, tracedo, 'actionDialog', () => {
				grid.dataSource.read();
			});
		});
		gridElement.on('click', '[data-action=purchasePriceDetail]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.openPurchasePriceDetail(vm, tracedo, 'actionDialog', () => {
				grid.dataSource.read();
			});
		});
		gridElement.on('click', '[data-action=pass]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.pass(vm, tracedo, 'actionDialog');
		});

		gridElement.on('click', '[data-action=setLoad]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.setLoad(vm, 'actionDialog', tracedo);
		});
		gridElement.on('click', '[data-action=setDischarge]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.setDischarge(vm, 'actionDialog', tracedo);
		});

		// Přerušit přepravu (stav suspend, odstraní řidiče a odešle mu email)
		gridElement.on('click', '[data-action=suspend]', async () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			let result = await vm.confirmDialog(i18n.t('trackAndTrace.reallySuspend'));
			if(result) {
				await tracedoManager.suspend(tracedo.id);
				grid.dataSource.read();
			}
		});

		// Zrušit přepravu (stav cancel + odešle email notifikaci)
		gridElement.on('click', '[data-action=cancel]', async () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			let result = await vm.confirmDialog(i18n.t('trackAndTrace.reallyCancel'));
			if(result) {
				await tracedoManager.cancel(tracedo.id);
				grid.dataSource.read();
			}
		});

		// Vygenerovat CMR
		gridElement.on('click', '[data-action=generateCMR]', () => {
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			TracedoHelpers.generateCMR(vm, tracedo);
		});		

		// generovani objednavky pozemni prepravy (PDF + edit HTML)
		gridElement.on('click', '.generate-transport-order-menu button[data-format]', async (e) => {
			let format = jQuery(e.target).attr('data-format');
			let asRole = jQuery(e.target).attr('data-role');
			let tracedo: TracedoView = (grid.dataItem(grid.select()) as unknown) as TracedoView;
			if(format === 'html') {
				let htmlContent = await tracedoManager.getTransportOrderHtml(tracedo.id, asRole);
				let tracedoNumber = tracedo.myNumber;
				let fileNamePdf = tracedoNumber + '.pdf';

				await vm.loadViewFrame<HtmlEditor>(HtmlEditor, 'actionDialog', {
					content: htmlContent,
					tools: [
						'bold','italic','underline','strikethrough','subscript','superscript',
						'fontName','fontSize','foreColor','backColor',
						'justifyLeft','justifyCenter','justifyRight','justifyFull',
						'insertUnorderedList','insertOrderedList','indent','outdent',
						'createLink','unlink','insertImage','insertFile',
						'tableWizard','createTable','addColumnLeft','addColumnRight','addRowAbove','addRowBelow','deleteRow','deleteColumn',
						'formatting','cleanFormatting',
						'insertHtml',
						'viewHtml',
						'print',
						'pdf',
						'copyFormat','applyFormat'
					],
					dialog: {
						width: 960,
						height: '100%',
						modal: true,
						title: 'Report editor',
						buttons: (htmlEd: HtmlEditor, window: kendo.ui.Window) => {
							return [
								{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.close'), click: () => window.close() },
								{ align: 'right', cls: 'btn-primary', label: i18n.t('common.actions.saveAsPdf'), click: async () => {
									let result = await tracedoManager.saveAsPdf(htmlEd.content, fileNamePdf);
									if(result) {
										location.href = result;
									}
								}}
							];
						}
					}
				});

			}
			else {
				let result = await tracedoManager.generateTransportOrder(tracedo.id, asRole, format);
				if(result) {
					location.href = result;
				}
			}
		});
	}


	/**
	 * Currently dummy function !
	 * @param trc 
	 * @returns 
	 */
	public static async tracedoObjectCreate(trc: any)
	{
		//var cbmSum: number = 0.0;
		//var kgsSum: number = 0.0;
		//var colliSum: number = 0;
		// let numbers: number[] = [];
		// if (multipleDelivery) {
		//	$.each(trc.tracedoPoItemVazList, (i, vaz) => {
		//		colliSum += parseInt(vaz.poItem.colli);
		//		kgsSum += parseFloat(vaz.poItem.weight) * parseInt(vaz.poItem.colli);
		//		cbmSum += parseFloat(vaz.poItem.length) * parseFloat(vaz.poItem.width) * parseFloat(vaz.poItem.height) * parseInt(vaz.poItem.colli);
		//	});
		//	if (trc.tracedoPoItemVazList.length == 0) {
		//		$.each(trc.tracedoVazList, (i, vaz) => {
		//			colliSum += parseInt(vaz.orderItem.colli);
		//			kgsSum += parseFloat(vaz.orderItem.kgs);
		//			cbmSum += parseFloat(vaz.orderItem.cbm);
		//		});
		//	}
		// } else {
			// jQuery.each(trc.tracedoVazList, (i, vaz) => {
				//colliSum += parseInt(vaz.orderItem.colli);
				//kgsSum += parseFloat(vaz.orderItem.kgs);
				//cbmSum += parseFloat(vaz.orderItem.cbm);
			// 	numbers.push(vaz.order.number);
			// });
		// }

		// numbers = numbers.filter((elem, index, self) => index == self.indexOf(elem));
		
		//trc.sumColli_x = colliSum;
		//trc.sumKgs_x = kgsSum;
		//trc.sumCbm_x = cbmSum;
		// trc.orderNumber = numbers.join(',');
		return trc;
	}


	// ---------------------------------------------------------------------------------------------------------


	/**
	 * Sestavi HTML select z predanych transport kinds (import/export)
	 * @param kinds 
	 * @returns string
	 */
	public static buildKindSelect(kinds: Array<{kindId: number, name: string }>): string
	{
		let select = '<select data-action="selectKind" title="' + i18n.t('common.captions.kind') + '" class="form-select d-inline w-auto">';
		select += '<option value="-"> - </option>';
		kinds.forEach(kind => {
			select += '<option value="' + kind.kindId + '">' + kind.name + '</option>';
		});
		select += '</select>';
		return select;
	}

	/**
	 * Sestavi HTML select z predanych invoicing statusu
	 * @param statuses 
	 */
	public static buildChangeInvoicingStatusSelect(statuses: Array<{ statusId: number, ident: string, name: string }>): string
	{
		let select = '<div class="btn-group change-inv-status-menu">' +
			'<button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">' +
				i18n.t('common.actions.changeInvStatus') +
			'</button>' +
			'<ul class="dropdown-menu" aria-labelledby="changeInvoicingStatusDropDown">';
		statuses.forEach(status => {
			select += '<li><a class="dropdown-item" href="\\#" data-status-id="' + status.statusId + '">' + i18n.t('common.captions.' + status.ident) + '</a></li>';
		});
		select += '</ul></div>';
		return select;
	}

	/**
	 * Sestavi HTML select z predanych invoicing statusu
	 * @param statuses 
	 */
	public static buildGenerateTransportOrderSelect(): string
	{
		let select = 
		'<div class="btn-group generate-transport-order-menu">' +
			'<button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">' +
				i18n.t('common.actions.generateTransportOrder') +
			'</button>' +
			'<ul class="dropdown-menu" aria-labelledby="generateTransportOrderDropDown">' +
				'<li><button class="dropdown-item" data-role="customer" data-format="pdf"><i class="icon-table"></i> ' + i18n.t('common.actions.downloadPdf') + '</button></li>' +
				'<li><button class="dropdown-item" data-role="customer" data-format="html"><i class="icon-table"></i> ' + i18n.t('common.actions.editAndDownloadPdf') +'</button></li>' +
				'<li><button class="dropdown-item" data-role="supplier" data-format="pdf"><i class="icon-table"></i> ' + i18n.t('common.actions.downloadOrderReceivedPdf') + '</button></li>' +
				'<li><button class="dropdown-item" data-role="supplier" data-format="html"><i class="icon-table"></i> ' + i18n.t('common.actions.editAndDownloadOrderReceivedPdf') +'</button></li>' +
			'</ul>' + 
		'</div>';
		return select;
	}


	/**
	 * Prepare data saved for get(ByWidgetId) to be used in getView(ByWidgetId)
	 * @param request data
	 */
	public static compatibilizeRequestData(data: any, glue: string, dateFields: {[key:string]: string})
	{
		// replace in filters
		if (data.filter) {
			var replaceFilters = function (filter: any) {
				if (filter.filters) {
					jQuery.each(filter.filters, (i: number, item: any) => {
						replaceFilters(item);
					});
				}
				else {

					// Date / DateTime fields format
					if(dateFields[filter.field]) {
						if(dateFields[filter.field] == 'date') {
							filter.value = moment(filter.value).format('YYYY-MM-DD');
						}
						else {
							filter.value = moment(filter.value).format();
						}
					}
					

					if (filter.field.substr(0, 8) === 'fields__') {
						filter.field = "fields_" + filter.field.substr(8);
					}
					else {
						let fieldNameParts = filter.field.split(glue);
						fieldNameParts.forEach((part: string, i: number) => {
							if(i > 0) {
								fieldNameParts[i] = part.substr(0, 1).toLocaleUpperCase() + part.substr(1);
							}
						});
						filter.field = fieldNameParts.join('');
					}
				}
			};
			replaceFilters(data.filter);
		}
		
		// replace in sorting
		if (data.sort) {
			jQuery.each(data.sort, (i, item) => {
				if (item.field.substr(0, 8) === 'fields__') {
					item.field = "fields_" + item.field.substr(8);
				}
				else {
					let fieldNameParts = item.field.split(glue);
					fieldNameParts.forEach((part: string, i: number) => {
						if(i > 0) {
							fieldNameParts[i] = part.substr(0, 1).toLocaleUpperCase() + part.substr(1);
						}
					});
					item.field = fieldNameParts.join('');
				}
			});
		}
	}

	// /**
	//  * Prepare data from API to be displayed in Track&Trace GRID(s)
	//  * @param data 
	//  */
	// public static compatibilizeResponseData(data: any[])
	// {
	// 	data.forEach((item: any) => {
	// 		jQuery.each(TracedoHelpers.compatibilityMap, (key: string, val: string) => {
	// 			if(item[val] !== undefined) {
	// 				item[key] = item[val];
	// 			}
	// 		});
	// 	});
	// }

	// public static compatibilizeColumns(columns: any[]): void
	// {
	// 	columns.forEach((col: any) => {
	// 		if(TracedoHelpers.compatibilityMap[col.field]) {
	// 			col.field = TracedoHelpers.compatibilityMap[col.field];
	// 		}
	// 	});
	// }

	// /**
	//  * Prepare data saved for get(ByWidgetId) to be used in getView(ByWidgetId)
	//  * @param request data
	//  */
	//  public static compatibilizeQuery(query: any, glue: string, dateFields: {[key:string]: string})
	//  {
	// 	 // replace in filters
	// 	 if (query.filter) {
	// 		 var replaceFilters = function (filter: any) {
	// 			 if (filter.filters) {
	// 				 jQuery.each(filter.filters, (i: number, item: any) => {
	// 					 replaceFilters(item);
	// 				 });
	// 			 }
	// 			 else {
 
	// 				 // Date / DateTime fields format
	// 				 if(dateFields[filter.field]) {
	// 					 if(dateFields[filter.field] == 'date') {
	// 						 filter.value = moment(filter.value).format('YYYY-MM-DD');
	// 					 }
	// 					 else {
	// 						 filter.value = moment(filter.value).format();
	// 					 }
	// 				 }
					 
 
	// 				 if (filter.field.substr(0, 8) === 'fields__') {
	// 					 filter.field = "fields_" + filter.field.substr(8);
	// 				 }
	// 				 else {
	// 					 let fieldNameParts = filter.field.split(glue);
	// 					 fieldNameParts.forEach((part: string, i: number) => {
	// 						 if(i > 0) {
	// 							 fieldNameParts[i] = part.substr(0, 1).toLocaleUpperCase() + part.substr(1);
	// 						 }
	// 					 });
	// 					 filter.field = fieldNameParts.join('');
	// 				 }
	// 			 }
	// 		 };
	// 		 replaceFilters(query.filter);
	// 	 }
		 
	// 	 // replace in sorting
	// 	 if (query.sort) {
	// 		 jQuery.each(data.sort, (i, item) => {
	// 			 if (item.field.substr(0, 8) === 'fields__') {
	// 				 item.field = "fields_" + item.field.substr(8);
	// 			 }
	// 			 else {
	// 				 let fieldNameParts = item.field.split(glue);
	// 				 fieldNameParts.forEach((part: string, i: number) => {
	// 					 if(i > 0) {
	// 						 fieldNameParts[i] = part.substr(0, 1).toLocaleUpperCase() + part.substr(1);
	// 					 }
	// 				 });
	// 				 item.field = fieldNameParts.join('');
	// 			 }
	// 		 });
	// 	 }
	//  }


	// private static compatibilityMap: {[key:string]:string} = {
	// 	'roadType__name': 'roadTypeName',
	// 	// 'originAddressCountry__iso': 'originAddressCountryIso',
	// 	// 'originAddressCountry__descr': 'originAddressCountryDescr',
	// 	// 'originAddressCountry__nameEn': 'originAddressCountryNameEn',
	// 	// 'destAddressCountry__iso': 'destAddressCountryIso',
	// 	// 'destAddressCountry__descr': 'destAddressCountryDescr',
	// 	// 'destAddressCountry__nameEn': 'destAddressCountryNameEn',
	// 	'trcStatus__name': 'trcStatusName',
	// 	'trcStatus__ident': 'trcStatusIdent',
	// 	'spediter__name': 'spediterName',
	// 	'picUser__lastName': 'picUserLastName',
	// 	'picUser__firstName': 'picUserFirstName',
	// 	'carrierPicUser__lastName': 'carrierPicUserLastName',
	// 	'carrierPicUser__firstName': 'carrierPicUserFirstName',
	// 	'customerPicUser__lastName': 'customerPicUserLastName',
	// 	'customerPicUser__firstName': 'customerPicUserFirstName',
	// 	'carrier__name': 'carrierName',
	// 	'driverUser__lastName': 'driverUserLastName',
	// 	'driverUser__firstName': 'driverUserFirstName',
	// 	'createdUser__realname': 'createdUserRealname',
	// 	'createdUser__firstName': 'createdUserFirstName',
	// 	'createdUser__lastName': 'createdUserLastName',
	// 	'customer__name': 'customerName',
	// 	'kind__name': 'kindName',
	// 	'priceCurrency__iso': 'priceCurrencyIso',
	// 	'priceCarrierCurrency__iso': 'priceCarrierCurrencyIso',
	// 	'invoicingStatus__name': 'invoicingStatusName',
	// 	'invoicingStatus__ident': 'invoicingStatusIdent'
	// };


}