import { TracedoGroup } from './../entities/TracedoGroup.d';
import { i18n } from "i18next-ko";
import { singleton } from "tsyringe";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";

@singleton()
export class TracedoGroupManager
{

	/**
	 * RPC Client
	 */
    private rpc: Client;

    /**
     * Constructor
     * @param rpc RPC
     */
    constructor(rpc: Client)
    {
        this.rpc = rpc;
    }

    /**
     * Get groups for the current user's subject
     * 
     * @param search Search in groups
     * @returns 
     */
    public async get(search: string): Promise<TracedoGroup[]>
    {
        const groups: any = await this.rpc.call('tracedoGroup.get', {
            query: {
                search: search,
                sort: [{field: 'number', dir: 'desc'}],
                pageSize: 100,
                page: 1
            }
        });
        return groups.data;
    }

    /**
     * Get one group by ID
     * 
     * @param id Group ID
     * @returns 
     */
    public async getOne(id: number): Promise<TracedoGroup>
    {
        return await this.rpc.call('tracedoGroup.getOne', {
            id: id,
            query: {
                select: '*,items(*,tracedo(*))'
            }
        });
    }

    /**
     * Create new group with given name
     * 
     * @param name 
     */
    public async create(name: string): Promise<TracedoGroup>
    {
        return await this.rpc.call('tracedoGroup.create', { name });
    }

    /**
     * Add tracedoIds to group
     * 
     * @param groupId Group ID
     * @param tracedoIds  Tracedo IDs
     * @returns 
     */
    public async addToGroup(groupId: number, tracedoIds: number[]): Promise<number>
    {
        return await this.rpc.call('tracedoGroup.addToGroup', { id: groupId, tracedoIds: tracedoIds });
    }

    /**
     * Remove selected Tracedo IDs from any group (of the currently operating subject)
     * @param tracedoIds 
     */
    public async removeFromGroup(tracedoIds: number[]): Promise<number>
    {
        return await this.rpc.call('tracedoGroup.removeFromGroup', {tracedoIds: tracedoIds });
    }
}