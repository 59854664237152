import {Checkpoint} from "../../entities/Checkpoint";
import {Addressbook} from "../../entities/Addressbook";

export class CheckpointFactory {

    static makeCheckpoint(typeId: number, name: string): Checkpoint {
        return {
            typeId: typeId,
            addressName: '',
            addressStreet: '',
            addressStreet2: '',
            addressTown: '',
            addressZipCode: '',
            addressCountryId: null,
            addressbookId: null,
            ata: null,
            atd: null,
            contactEmail: '',
            contactFirstName: '',
            contactId: null,
            contactLastName: '',
            contactPhone: '',
            eta: null,
            etd: null,
            name: name,
            note: '',
            reference: '',
            // Colli / Po Items for checkpoint
            kgs: null,
            colliTypeId: null,
            colli: null,
            poItems: [],
            ramp: {"id": null, "name": null},
            rampId: null,
            rampName: null
        };
    }

    static makeCheckpointWithAddress(address: Addressbook, name: string): Checkpoint {
        let checkpoint = this.makeCheckpoint(null, name);
        checkpoint.addressbook = address;
        checkpoint.addressbookId = address.addressbookId;
        checkpoint.addressCountryId = address.countryId;
        checkpoint.addressName = address.name;
        checkpoint.addressCountry = address.country;
        checkpoint.addressStreet = address.street;
        checkpoint.addressStreet2 = address.street2;
        checkpoint.addressTown = address.town;
        checkpoint.addressZipCode = address.zipCode;
        return checkpoint;
    }
}