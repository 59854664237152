import { h } from "../../../../tracejs/src/utils/JSXFactory";

export class EditableContainerInfo {

    private static containerType = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'bdpCntrTypeId', i18n: 'common.captions.cntrType'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.bdpCntrTypeId" /></label>
                </ko> : ``}

                <select className="form-select" data-bind="
                    uniqueId: 'bdpCntrTypeId',
                    options: $root.bdpContainerTypes,
                    optionsText: 'estoneCode',
                    optionsValue: 'bdpContainerTypeId',
                    optionsCaption: '-',
                    value: bdpCntrTypeId
                "></select>
            </div>
        </div>
    );

    private static containerNumber = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'cntrNr', i18n: 'common.captions.cntrNr'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.cntrNr" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'cntrNr', value: cntrNr" className="form-control" />
            </div>
        </div>
    );

    private static sealNumber = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'sealNr', i18n: 'common.captions.sealNr'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.sealNr" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'sealNr', value: sealNr" className="form-control" />
            </div>
        </div>
    );

    private static ssLine = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'rejdar', i18n: 'common.captions.rejdar'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.rejdar" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'rejdar', value: rejdar" className="form-control" />
            </div>
        </div>
    )

    private static vessel = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'vessel', i18n: 'common.captions.vessel'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.vessel" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'vessel', value: vessel" className="form-control" />
            </div>
        </div>
    );

    private static voyageNumber = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'voyageNr', i18n: 'common.captions.voyageNr'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.voyageNr" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'voyageNr', value: voyageNr" className="form-control" />
            </div>
        </div>
    );

    private static trainNumber = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'trainNr', i18n: 'common.captions.trainNr'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input
                        type="checkbox" data-bind="checked: $root.sync.trainNr"/></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'trainNr', value: trainNr" className="form-control"/>
            </div>
        </div>
    );

    private static mbl = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'mbl', i18n: 'common.captions.mbl'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.mbl" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'mbl', value: mbl" className="form-control" />
            </div>
        </div>
    );

    private static hbl = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'hbl', i18n: 'common.captions.hbl'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.hbl" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'hbl', value: hbl" className="form-control" />
            </div>
        </div>
    );

    private static customOffice = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'customOffice', i18n: 'common.captions.customOffice'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.customOffice" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'customOffice', value: customOffice" className="form-control" />
            </div>
        </div>
    );

    private static shipper = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'shipperName', i18n: 'common.captions.shipper'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.shipperName" /></label>
                </ko> : ``}

                <input type="text" data-bind="uniqueId: 'shipperName', value: shipperName" className="form-control" />
            </div>
        </div>
    );

    private static consignee = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'consigneeName', i18n: 'common.captions.consignee'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                    {sync ? <ko if="$root.tracedoGroup()">
                        <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.consigneeName" /></label>
                    </ko> : ``}

                    <input type="text" data-bind="uniqueId: 'consigneeName', value: consigneeName" className="form-control" />
                </div>
            </div>
    );

    private static returnOrPickupDepot = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false) : HTMLElement => (
        <div className="row mb-2">
            <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label pe-0`} data-bind="uniqueFor: 'depotId', i18n: 'common.captions.returnPickupDepot'"></label>
            <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} d-flex`}>

                {sync ? <ko if="$root.tracedoGroup()">
                    <label className="sync-cb" data-bind="attr: { title: i18n.t('common.captions.syncInfo') }"><input type="checkbox" data-bind="checked: $root.sync.depotId" /></label>
                </ko> : ``}

                <select className="form-select" data-bind="
                    options: $root.depots,
                    optionsValue: 'depotId',
                    optionsText: 'name',
                    optionsCaption: '-',
                    value: depotId,
                    uniqueId: 'depotId'
                "></select>
            </div>
        </div>
    );

    public static editableContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9], sync: boolean = false): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.containerInformation'"></legend>

            { this.containerType(l, v, sync) }
            { this.containerNumber(l, v, sync) }
            { this.sealNumber(l, v, sync) }

            <ko if="$root.isRoadContainer()">
                { this.ssLine(l, v, sync) }
                { this.vessel(l, v, sync) }
                { this.voyageNumber(l, v, sync) }
            </ko>
            <ko if="$root.isRail()"> { this.trainNumber(l, v, sync) } </ko>

            { this.mbl(l, v, sync) }

            <ko if="$root.isRail()">{ this.hbl(l, v, sync) }</ko>

            { this.customOffice(l, v, sync) }
            { this.shipper(l, v, sync) }
            { this.consignee(l, v, sync) }
            { this.returnOrPickupDepot(l, v, sync) }

        </fieldset>
    );
}